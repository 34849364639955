import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./Styles/GlobalStyle";
import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from "./config/config";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<GoogleOAuthProvider
		clientId={
			config[process.env.WDS_SOCKET_PATH].REACT_APP_GOOGLE_AUTH_CLIENT_ID
		}
	>
		<BrowserRouter>
			<GlobalStyle />
			<RecoilRoot>
				<App />
			</RecoilRoot>
		</BrowserRouter>
	</GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
