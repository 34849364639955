// 이용내역(포인트)
import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import Api from '../libs/api';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LoadingAtom, UserAtom } from '../Recoil/Atoms/UserState';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';
import Util from '../Store/Util';

import * as S from '../Styles/Style';
import NoList from '../Components/NoList';
import RefreshHandler from '../Components/RefreshHandler';

import chestGold from '../Images/chest_gold.png';
import point from '../Images/point.png';
import { config } from '../config/config';

const UserPropertyArea = styled(S.flexRowCenter)`
	background-color: ${S.color.orange50};
	border-radius: 1rem;
	margin: 2rem 1.4rem 1rem;
	padding: 1.2rem 0;
	img {
		height: 3.4rem;
		margin-right: 1.2rem;
	}
	p {
		border-radius: 2rem;
	}
`;

interface pointHistoryData {
	changeType: string;
	changeTypeCode: string;
	mphmIdx: string;
	regDt: string;
	updownType: string;
	groupCount: string;
	count: number;
	userType: string;
}

export default () => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 포인트내역 리스트 정보
	const [pointHistoryDatas, setPointHistoryDatas] = useState<pointHistoryData[]>([]);
	const [pointHistoryNextPage, setPointHistoryNextPage] = useState<boolean>(true);
	const pointHistoryPage = useRef<number>(1);
	const pointHistoryObserver = useRef<HTMLDivElement>(null);
	// 포인트내역 리셋
	const pointHistoryReset = () => {
		setPointHistoryDatas([]);
		setPointHistoryNextPage(true);
		pointHistoryPage.current = 1;
	};
	// 포인트내역 리스트 불러오기
	const pointHistoryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log('pointHistoryFetch');
		await Api.get(`/member/info/memberPointHistory`, {
			page: pointHistoryPage.current,
			limit: 10,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				setPointHistoryDatas((prevDatas) => [...prevDatas, ...data]);
				setPointHistoryNextPage(data.length === 10);
				if (data.length) {
					pointHistoryPage.current += 1;
				}
			} else {
				alert('fail');
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		if (!pointHistoryObserver.current || !pointHistoryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				pointHistoryFetch();
			}
		});
		io.observe(pointHistoryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [pointHistoryNextPage]);

	// 포인트 이력 구분
	const pointChangeText = (changeType: string) => {
		switch (changeType) {
			case '12': // 분해
				return 'Penukaran Harta Karun';
			case '20': // 합체상품 포인트
				return 'Poin Barang Gabungan';
			case '21': // 상자 구매
				return 'Pembelian Peti Harta Karun';
			case '23': // 이벤트 지급
				return 'Event';
			case '24': // 기타 지급
				return 'Lain-lain';
			case '25': // 배송요청
				return 'Pengajuan Pengiriman oleh Pengguna';
			case '26': // 배송 환급
				return 'Pengembalian Pengiriman';
			case '27': // 사용자 배송 취소
				return 'Pembatalan Pengiriman oleh Pengguna';
			case '28': // 관리자 배송 취소
				return 'Pembatalan Pengiriman oleh Admin';
			case '29': // 관리자 배송 불가
				return 'Pesanan Tidak Dapat Diproses';
			default:
				return '-';
		}
	};

	return (
		<S.wrapper>
			<UserPropertyArea>
				<img src={point} />
				<p>
					<S.bodyText_M_long>Total poin saya </S.bodyText_M_long>
					<S.subhead_3>{Util.addComma(userState.freePoint)}</S.subhead_3>
				</p>
			</UserPropertyArea>
			{/* 포인트내역 */}
			{/* 리스트 */}
			<RefreshHandler
				handleRefresh={() => pointHistoryReset()}
				contents={
					<S.wrapper>
						{pointHistoryDatas.length === 0 ? (
							<NoList type={6} />
						) : (
							pointHistoryDatas?.map((pointHistoryData, index) => (
								<S.helpItem key={index}>
									<div
										style={{
											flexGrow: 1,
											flexShrink: 1,
										}}>
										<S.bodyText_M_long
											$ellipsis={1}
											style={{
												marginBottom: '0.8rem',
											}}>
											{pointChangeText(pointHistoryData.changeTypeCode)}
										</S.bodyText_M_long>
										<S.bodyText_XS color={S.color.gray500}>
											{Util.dateFormat(pointHistoryData?.regDt)}
										</S.bodyText_XS>
									</div>
									<S.flexColEnd
										style={{
											width: 'fit-content',
											flexGrow: 1,
											flexShrink: 0,
										}}>
										<S.subhead_3
											// $ellipsis={1}
											style={{
												marginBottom: '0.4rem',
											}}>
											{pointHistoryData.updownType == '1' ? '+' : '-'}
											{Util.addComma(Number(pointHistoryData.count))}P
										</S.subhead_3>
										<S.bodyText_S color={S.color.gray600}>
											{pointHistoryData.groupCount} transaksi
										</S.bodyText_S>
									</S.flexColEnd>
								</S.helpItem>
							))
						)}
						<div ref={pointHistoryObserver} />
					</S.wrapper>
				}
			/>
		</S.wrapper>
	);
};

