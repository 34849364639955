// 거래소, 거래현황
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom, IsOverlapAtom } from "../Recoil/Atoms/UserState";
import { FilterAtom, TRSRAtom, TRSRIdxAtom } from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import NoList from "../Components/NoList";
import TabMenu from "../Components/TabMenu";
import Filter from "../Components/Filter";
import RefreshHandler from "../Components/RefreshHandler";

import search from "../Images/search.png";
import sliders from "../Images/sliders.png";
import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import { config } from "../config/config";

const ListExplain = styled.div`
	padding: 1.4rem 1.4rem 0;
`;

interface slideMenu {
	value: number;
	text: string;
}
interface exchangeData {
	categoryCode: string;
	categoryName: string;
	displayPrice: number;
	exIdx: number;
	mmIdx: number;
	name: string;
	nick: string;
	pmIdx: number;
	presentPrice: number;
	presentStateCode: string;
	profileImageUrl: string;
	pumIdx: number;
	sellerType: string;
	stateNm: string;
	typeCImageUrl: string;
}
interface historyData {
	exIdx: number;
	sellerMmIdx: number;
	sellerPmIdx: number;
	salePrice: number;
	presentStateCode: string;
	uniqueIdx: number;
	regDt: string;
	modDt: string;
	stateCode: string;
	name: string;
	typeAImageUrl: string;
	typeBImageUrl: string;
	typeCImageUrl: string;
	displayPrice: number;
	sellerNick: string;
	exchangeStateCodeName: string;
	stateCodeName: string;
}

export default () => {
	const navigate = useNavigate();
	const location = useLocation();

	// 보물상세 > 거래등록건수 클릭으로 이동된 경우
	const name = location.state == undefined ? "" : location.state.name;
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 황성화 탭 인덱스
	const [activeTab, setActiveTab] = useState<number>(0);
	// 필터 모달 생성
	const [isOpenFilter, setOpenFilter] = useRecoilState<boolean>(FilterAtom);
	// 거래취소 확인 모달 생성
	const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false);
	// 보물상세정보 팝업 생성
	const setOpenTRSR = useSetRecoilState<boolean>(TRSRAtom);
	const SetTRSRIdx = useSetRecoilState<number>(TRSRIdxAtom);
	const type = useRef<string>();
	// 슬라이드 메뉴
	const slideMenuData: slideMenu[] = [
		{ value: 70, text: "Jual beli" },
		{ value: 71, text: "Transaksi selesai" },
		{ value: 72, text: "Pembelian selesai" },
	];
	// 활성화 슬라이드 메뉴
	const [slideMenuValue, setSlideMenuValue] = useState<number>(
		slideMenuData[0].value
	);
	// 거래소 리스트 정보
	const [exchangeDatas, setExchangeDatas] = useState<exchangeData[]>([]);
	const [exchangeNextPage, setExchangeNextPage] = useState<boolean>(true);
	const exchangePage = useRef<number>(1);
	const exchangeObserver = useRef<HTMLDivElement>(null);
	// 거래현황 리스트 정보
	const [historyDatas, setHistoryDatas] = useState<historyData[]>([]);
	const [historyNextPage, setHistoryNextPage] = useState<boolean>(true);
	const historyPage = useRef<number>(1);
	const historyObserver = useRef<HTMLDivElement>(null);
	// 검색
	const [filter, setFilter] = useState<string>(null);
	const [orderBy, setOrderBy] = useState<number>(2);
	const orderByRef = useRef<number>(null);
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	// 거래소 리셋
	const exchangeReset = () => {
		setExchangeDatas([]);
		setExchangeNextPage(true);
		exchangePage.current = 1;
	};
	// 거래현황 리셋
	const historyReset = () => {
		setHistoryDatas([]);
		setHistoryNextPage(true);
		historyPage.current = 1;
	};
	// 거래소 리스트 불러오기
	const exchangeFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("exchangeFetch");
		searchYnRef.current = false;
		await Api.get(`/exchange/list`, {
			page: exchangePage.current,
			orderBy: orderByRef.current ?? orderBy,
			keyword: keywordRef.current == "" ? undefined : keywordRef.current,
			filter: filter, // (보물명 + 회원명), 보물명, 회원명
		}).then((res) => {
			const data = res.data.data.list;
			setExchangeDatas((prevDatas) => [...prevDatas, ...data]);
			setExchangeNextPage(data.length === 10);
			if (data.length) {
				exchangePage.current += 1;
			}
			setLoading(false);
		});
	};
	// 거래현황 리스트 불러오기
	const historyFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("historyFetch");
		await Api.get(`/exchange/tradeState`, {
			page: historyPage.current,
			orderBy: orderByRef.current ?? orderBy,
			stateCode: slideMenuValue, // 70 (거래중), 71 = 거래완료, 72 = 구매완료
		}).then((res) => {
			const data = res.data.data.list;
			setHistoryDatas((prevDatas) => [...prevDatas, ...data]);
			setHistoryNextPage(data.length === 10);
			if (data.length) {
				historyPage.current += 1;
			}
			setLoading(false);
		});
	};
	useEffect(() => {
		if (!exchangeObserver.current || !exchangeNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				exchangeFetch();
			}
		});
		io.observe(exchangeObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [exchangeNextPage, activeTab]);
	useEffect(() => {
		if (!historyObserver.current || !historyNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				historyFetch();
			}
		});
		io.observe(historyObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [historyNextPage, activeTab]);
	// 각 탭 리셋
	useEffect(() => {
		if (activeTab === 0 && name !== "") {
			setFilter("보물명");
			(document.getElementById("keyword") as HTMLInputElement).value =
				name;
			setKeyword(name);
			keywordRef.current = name;
		}
		orderByRef.current = orderBy;
		activeTab === 0 ? exchangeReset() : historyReset();
	}, [activeTab, orderBy, slideMenuValue]);
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), exchangeReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		exchangeReset();
	};

	return (
		<>
			<Page
				title="Tempat Transaksi"
				contents={
					<>
						<TabMenu
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabs={[
								{
									index: 0,
									name: "Harta Karun",
									content: (
										<>
											{/*거래소 */}
											{/* 검색 */}
											<S.searchArea>
												<div className="searchBox">
													<input
														id="keyword"
														type="text"
														placeholder="kata pencarian"
													/>
													<S.searchButton
														onClick={() =>
															searchAction()
														}
													>
														<img src={search} />
													</S.searchButton>
												</div>
												<S.searchFilter
													onClick={() => {
														if (isLoading) return;
														setOpenFilter(true);
													}}
												>
													<img src={sliders} />
												</S.searchFilter>
											</S.searchArea>
											{/* 정렬 */}
											{/* 리스트 */}
											<RefreshHandler
												handleRefresh={() =>
													exchangeReset()
												}
												contents={
													<>
														{exchangeDatas.length ===
														0 ? (
															<NoList
																searchKeyword={
																	keyword
																}
															/>
														) : (
															<S.treasureList>
																{exchangeDatas?.map(
																	(
																		exchangeData,
																		index
																	) => (
																		<S.treasureListItem
																			onClick={() => {
																				if (
																					isLoading
																				)
																					return;
																				// TODO: 본인 등록 보물 클릭 불가
																				SetTRSRIdx(
																					exchangeData.pmIdx
																				);
																				setOpenTRSR(
																					true
																				);
																				type.current =
																					"exchange";
																			}}
																			key={
																				exchangeData.exIdx
																			}
																			style={{
																				marginRight:
																					index %
																						2 ===
																						0 &&
																					"1rem",
																			}}
																		>
																			<S.flexRowCenter
																				style={{
																					marginBottom:
																						"1rem",
																				}}
																			>
																				{/* TODO: 이미지 크게보기 */}
																				<S.userImage>
																					<S.heightImg
																						src={
																							exchangeData.profileImageUrl
																								? imgUrl +
																								  exchangeData.profileImageUrl
																								: defaultProfile
																						}
																					/>
																				</S.userImage>
																				<S.bodyText_XS
																					color={
																						S
																							.color
																							.gray400
																					}
																				>
																					{
																						exchangeData.nick
																					}
																				</S.bodyText_XS>
																			</S.flexRowCenter>
																			<div className="itemImage">
																				<S.heightImg
																				// 상품 이미지 등록 전
																				// src={
																				// 	imgUrl +
																				// 	exchangeData.typeCImageUrl
																				// }
																				/>
																			</div>
																			<S.bodyText_S
																				className="name"
																				$ellipsis={
																					2
																				}
																			>
																				{
																					exchangeData.name
																				}
																			</S.bodyText_S>
																			<p>
																				<S.bodyText_XS
																					color={
																						S
																							.color
																							.gray500
																					}
																				>
																					Harga
																					normal
																					|{" "}
																					{Util.addComma(
																						exchangeData.displayPrice
																					)}{" "}
																					IDR
																				</S.bodyText_XS>
																			</p>
																			<p>
																				<S.bodyText_XS
																					color={
																						S
																							.color
																							.error
																					}
																				>
																					Harga
																					transaksi
																				</S.bodyText_XS>
																				<S.bodyText_S
																					color={
																						S
																							.color
																							.gray300
																					}
																				>
																					{" "}
																					|{" "}
																				</S.bodyText_S>
																				<S.bodyText_S
																					color={
																						S
																							.color
																							.error
																					}
																				>
																					{Util.addComma(
																						exchangeData.presentPrice
																					)}{" "}
																					P
																				</S.bodyText_S>
																			</p>
																		</S.treasureListItem>
																	)
																)}
															</S.treasureList>
														)}
														<div
															ref={
																exchangeObserver
															}
														/>
													</>
												}
											/>
										</>
									),
								},
								{
									index: 1,
									name: "Status transaksi",
									content: (
										<>
											{/* 거래현황 */}
											<S.flexRowCenter
												style={{ marginTop: "1.4rem" }}
											>
												{/* 정렬 */}
												{/* 슬라이드 메뉴 */}
												<S.slideMenuWrap>
													<S.slideMenu>
														{slideMenuData?.map(
															(data, idx) => (
																<S.slideMenuItem
																	key={idx}
																	value={idx}
																	onClick={() => {
																		if (
																			isLoading
																		)
																			return;
																		setSlideMenuValue(
																			data.value
																		);
																	}}
																	$active={
																		slideMenuValue ===
																		data.value
																	}
																>
																	<button>
																		<S.bodyText_XS>
																			{
																				data.text
																			}
																		</S.bodyText_XS>
																	</button>
																</S.slideMenuItem>
															)
														)}
													</S.slideMenu>
												</S.slideMenuWrap>
											</S.flexRowCenter>
											{/* 목록 자동 삭제 안내 */}
											{slideMenuValue === 72 && (
												<ListExplain>
													<S.bodyText_S>
														test
													</S.bodyText_S>
												</ListExplain>
											)}
											{/* 리스트 */}
											<RefreshHandler
												handleRefresh={() =>
													historyReset()
												}
												contents={
													<>
														{historyDatas.length ===
														0 ? (
															<NoList />
														) : (
															<>
																{historyDatas?.map(
																	(
																		historyData,
																		index
																	) => (
																		<S.listItem
																			key={
																				historyData.exIdx
																			}
																		>
																			<div className="itemWrap">
																				<button
																					className="itemImage"
																					onClick={() => {
																						SetTRSRIdx(
																							historyData.sellerPmIdx
																						);
																						setOpenTRSR(
																							true
																						);
																						type.current =
																							"";
																					}}
																				>
																					<S.heightImg
																					// 상품 이미지 등록 전
																					// src={
																					// 	imgUrl +
																					// 	exchangeData.typeCImageUrl
																					// }
																					/>
																				</button>
																				<div
																					className="itemInfo"
																					style={{
																						justifyContent:
																							"center",
																					}}
																				>
																					<S.bodyText_S
																						$ellipsis={
																							2
																						}
																					>
																						{
																							historyData.name
																						}
																					</S.bodyText_S>
																					<S.bodyText_XS
																						color={
																							S
																								.color
																								.gray500
																						}
																					>
																						Harga
																						normal
																						|{" "}
																						{Util.addComma(
																							historyData.displayPrice
																						)}{" "}
																						IDR
																					</S.bodyText_XS>
																					<S.bodyText_XS
																						color={
																							S
																								.color
																								.gray500
																						}
																					>
																						Harga
																						transaksi
																						|{" "}
																						{Util.addComma(
																							historyData.salePrice
																						)}{" "}
																						IDR
																					</S.bodyText_XS>
																					<S.bodyText_XS
																						color={
																							S
																								.color
																								.gray500
																						}
																					>
																						{Util.dateFormat(
																							historyData.modDt,
																							"date"
																						)}
																					</S.bodyText_XS>
																				</div>
																				{historyData.stateCode ==
																					"70" && (
																					<XIcon
																						onClick={() =>
																							setOpenConfirm(
																								true
																							)
																						}
																						fill={
																							S
																								.color
																								.gray400
																						}
																					/>
																				)}
																			</div>
																		</S.listItem>
																	)
																)}
															</>
														)}
														<div
															ref={
																historyObserver
															}
														/>
													</>
												}
											/>
										</>
									),
								},
							]}
						/>
					</>
				}
				bottomNav={true}
			/>
			<Filter isOpen={isOpenFilter} close={() => setOpenFilter(false)} />
			{/* 거래취소 확인 */}
			<Modal
				isOpen={isOpenConfirm}
				close={() => setOpenConfirm(false)}
				contents={
					<p>
						<S.bodyText_M>
							해당 보물을 거래소에서 내리시겠습니까? 번역 필요
						</S.bodyText_M>
					</p>
				}
			/>
		</>
	);
};
