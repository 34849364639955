import { useEffect, useState } from "react";
import Api from "../libs/api";
import axios from "axios";
import React, { PropsWithChildren } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { UserAtom, IsOverlapAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";

import captain from "../Images/popup_captain.png";
import bubble from "../Images/popup_bubble.png";
import bell from "../Images/popup_bell.png";
import rotate from "../Images/popup_rotate.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as Rotate } from "../Images/rotate.svg";
import { idText } from "typescript";
import { config } from "../config/config";

interface DefaultType {
	isOpen: boolean; // 팝업 생성 여부
	close: () => void; // 팝업 닫기
}

export default ({ isOpen, close }: PropsWithChildren<DefaultType>) => {
	const apiUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_API_URL;
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);

	const [nick, setNick] = useState<string>("");
	const [profile, setProfile] = useState<{
		url: string;
		file: File;
	}>({
		url: "",
		file: null,
	});
	const [profileExplain, setProfileExplain] = useState<string>("");
	const [isValid, setValid] = useState<boolean>(false);

	useEffect(() => {
		let result;

		if (userState.profileImageUrl == "") {
			result = defaultProfile;
		} else {
			if (userState.profileImageUrl.includes("http")) {
				result = userState.profileImageUrl;
			} else {
				result = imgUrl + userState.profileImageUrl;
			}
		}
		console.log(result);
		let copiedList = profile;
		copiedList.url = result;
		setProfile(copiedList);
		setNick(userState.nick);
	}, [userState]);
	// 최초 프로필 설정 표시될 이미지
	const profileImage = () => {
		if (profile.url == "") {
			if (userState.profileImageUrl == "") {
				return defaultProfile;
			} else {
				if (userState.profileImageUrl.includes("http")) {
					return userState.profileImageUrl;
				} else {
					return apiUrl + userState.profileImageUrl;
				}
			}
		} else {
			return profile;
		}
	};
	// 최초 프로필 설정 모달 이미지 등록
	const profileUpload = (e) => {
		const file = e.target.files[0];
		console.log(file.size);
		if (file.length === 0) {
			setProfile({
				url: "",
				file: null,
			});
		} else {
			if (file.size > 20 * 1024 * 1024) {
				return (
					setToastType(1),
					setToastContents(
						"Ukuran gambar yang bisa dilampirkan maksimal 20MB."
					),
					setOpenToast(true)
				);
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setProfile({
						url: reader.result as string,
						file: file,
					});
				};
			}
		}
	};
	// 최초 프로필 설정 모달 저장 버튼 클릭
	const formData = new FormData();
	const memberInfoChange = async () => {
		const nick = (document.getElementById("nick") as HTMLInputElement)
			.value;
		if (nick.length == 0) {
			// 닉네임 미입력
			setToastType(1);
			setToastContents("Masukkan nickname.");
			setOpenToast(true);
			return;
		}
		// 중복확인 안한 경우
		if (!isValid) {
			setToastType(1);
			setToastContents("Silakan periksa nickname Anda");
			setOpenToast(true);
			return;
		}

		const file = profile.file;
		if (file == null) {
			if (profile.url.toString().includes("http")) {
				formData.append("reset", "1");
			} else {
				// 기본이미지로
				formData.append("reset", "0");
			}
		} else {
			formData.append("profileImageUrl", profile.file);
		}
		formData.append("nick", nick);
		for (let key of formData.keys()) {
			console.log(key, ":", formData.get(key));
		}
		const token = localStorage.getItem("token");
		await await axios
			.patch(apiUrl + "/auth/authFirstNick", formData, {
				headers: {
					Authorization: token,
					"content-type": "multipart/form-data",
				},
			})
			.then((res) => {
				console.log(res);
				close();
			});
	};

	// 입력값 변경 이벤트
	const onChangeEvent = (target) => {
		setValid(false);
		setProfileExplain("");

		// 입력 최대값 체크
		if (target.value.length > target.maxLength) {
			setNick(target.value.slice(0, target.maxLength));
		} else {
			setNick(target.value);
		}
	};
	// 닉네임 중복체크
	const memberNickDuplicationCheck = async () => {
		// 영어, 한글, 숫자만 사용 가능
		const regex = /^[a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]+$/;
		if (nick.length == 0) {
			// 닉네임 미입력
			setToastType(1);
			setToastContents("Masukkan nickname.");
			setOpenToast(true);
		} else if (nick.length < 8) {
			// 8글자 미만 작성
			setProfileExplain("Tulis minimal 8 karakter.");
		} else if (nick.search(/\s/) != -1 || !regex.test(nick)) {
			// 특수문자 사용
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else if (false) {
			// 필터처리된 단어 포함
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else {
			await Api.get("/member/info/memberNickDuplicationCheck", {
				nick: nick,
			}).then((res) => {
				if (res.data !== undefined) {
					if (res.data.data === 0) {
						// 사용 가능
						setProfileExplain("Nickname bisa digunakan.");
						setValid(true);
					} else if (res.data.data > 0) {
						// 중복
						setProfileExplain("Nickname sudah digunakan.");
						setValid(false);
					} else {
						setValid(false);
					}
				} else {
					alert("fail");
				}
			});
		}
	};
	return (
		<S.popupContainer $isOpen={isOpen}>
			<S.popupWrapper>
				{/* 내용 */}
				<>
					<h1>
						<S.mainTitle_L>Pengaturan Nickname</S.mainTitle_L>
					</h1>
					<p style={{ marginBottom: "0.8rem" }}>
						<S.subhead_2>
							Tentukan nickname yang akan Anda gunakan di Gopang.
						</S.subhead_2>
					</p>
					<S.textList style={{ marginBottom: "2rem" }} $gray>
						<li>
							<S.bodyText_M_long>
								Boleh menggunakan huruf dan angka.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Harus terdiri dari 8-20 karakter.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Penggunaan kata-kata kotor atau tidak pantas
								dapat mengakibatkan sanksi dan perubahan paksa
								nickname.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Silakan unggah foto dengan ukuran maksimal 20 MB
								dalam format GIF, PNG, atau JPG.
							</S.bodyText_M_long>
						</li>
					</S.textList>
					<S.popupBox>
						<S.setProfileArea>
							<S.userProfile
								$url={
									profile.url ? profile.url : defaultProfile
								}
							>
								<input
									type="file"
									id="profileUpload"
									accept=" .gif, .jpg, .jpeg, .png"
									onChange={(e) => profileUpload(e)}
								/>
								<label htmlFor="profileUpload" />
								<Rotate
									fill="white"
									onClick={() => {
										setProfile({
											url: "",
											file: null,
										});
									}}
								/>
							</S.userProfile>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="nick"
										value={nick}
										onFocus={() => {
											setNick("");
											setValid(false);
											setProfileExplain("");
										}}
										onChange={(e) => {
											onChangeEvent(e.target);
										}}
										placeholder={"Masukkan nickname."}
										maxLength={20}
									/>
								</div>
							</S.inputField>
							<p>
								<S.bodyText_S
									style={{ whiteSpace: "nowrap" }}
									color={
										isValid
											? S.color.point01
											: S.color.error
									}
								>
									{profileExplain}
								</S.bodyText_S>
							</p>
						</S.setProfileArea>
						<S.buttonPrimary_B
							onClick={() => memberNickDuplicationCheck()}
						>
							<S.button_XS>Periksa Nickname</S.button_XS>
						</S.buttonPrimary_B>
					</S.popupBox>
				</>
				{/* 버튼 */}
				<S.buttonWrap>
					<S.buttonPrimary_S
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							memberInfoChange();
						}}
					>
						<S.button_S>Simpan</S.button_S>
					</S.buttonPrimary_S>
				</S.buttonWrap>
			</S.popupWrapper>
		</S.popupContainer>
	);
};
