// 내보물함(배송)
import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import Api from '../libs/api';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LoadingAtom } from '../Recoil/Atoms/UserState';
import { TRSRAtom, TRSRIdxAtom } from '../Recoil/Atoms/PopupState';
import Util from '../Store/Util';

import * as S from '../Styles/Style';
import NoList from '../Components/NoList';
import RefreshHandler from '../Components/RefreshHandler';

import sliders from '../Images/sliders.png';
import { config } from '../config/config';

interface DefaultType {
	setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
	filter: string[];
	stateCode: string;
}

interface Data {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	LEN_NAME: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
	invoiceNumber: string;
}

export default ({ setOpenFilter, filter, stateCode }: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 보물상세정보 팝업 생성
	const setOpenTRSR = useSetRecoilState<boolean>(TRSRAtom);
	const SetTRSRIdx = useSetRecoilState<number>(TRSRIdxAtom);
	// 내배송리스트 정보
	const [deliveryDatas, setDeliveryDatas] = useState<Data[]>([]);
	const [deliveryNextPage, setDeliveryNextPage] = useState<boolean>(true);
	const deliveryPage = useRef<number>(1);
	const deliveryObserver = useRef<HTMLDivElement>(null);
	// B
	const filterRef = useRef<string[]>([]);
	const stateCodeRef = useRef<string>(null);
	//
	// 내배송리스트 리셋
	const deliveryReset = () => {
		setDeliveryDatas([]);
		setDeliveryNextPage(true);
		deliveryPage.current = 1;
	};
	// 내 배송함 목록 조회
	const deliveryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log('deliveryFetch');
		await Api.get(`/member/deliveryListSelect`, {
			page: deliveryPage.current,
			filter: filterRef.current.length > 0 ? filterRef.current.join() : null,
			deliveryStateCode: stateCodeRef.current,
		}).then((res) => {
			if (res.data !== undefined) {
				console.log(res);
				const data = res.data.data;
				setDeliveryDatas((prevDatas) => [...prevDatas, ...data]);
				setDeliveryNextPage(data.length === 10);
				if (data.length) {
					deliveryPage.current += 1;
				}
			} else {
				alert('fail');
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (!deliveryObserver.current || !deliveryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				deliveryFetch();
			}
		});
		io.observe(deliveryObserver.current);

		return () => {
			io.disconnect();
		};
	}, [deliveryNextPage]);
	// 리스트 리셋
	useEffect(() => {
		filterRef.current = filter;
		stateCodeRef.current = stateCode;
		deliveryReset();
	}, [stateCode, filter]);
	// 내보물함 리스트
	const RenderItem = ({ data, type, index }) => {
		return (
			<S.listItem key={data.pumIdx} style={{ paddingTop: index == 0 && '0.6rem' }}>
				<div className="itemWrap">
					<div
						className="itemImage"
						onClick={() => {
							SetTRSRIdx(data.pmIdx);
							setOpenTRSR(true);
						}}>
						<S.heightImg src={imgUrl + data.imageUrl} />
					</div>
					<div
						className="itemInfo"
						onClick={() =>
							navigate('/DLVR002001', {
								state: {
									item: data,
								},
							})
						}>
						<S.bodyText_XS color={S.color.gray400} style={{ margin: '0.4rem 0 1.4rem' }}>
							{Util.dateFormat(data.modDt, 'date')}
						</S.bodyText_XS>
						<S.subhead_3 $ellipsis={2}>{data.name}</S.subhead_3>
						<S.bodyText_XS color={S.color.gray600}>
							Harga normal &nbsp;| &nbsp;
							{Util.addComma(data.displayPrice)} IDR
						</S.bodyText_XS>
					</div>
					<S.handlingButtonArea>{deliveryState(data.deliveryStateCode)}</S.handlingButtonArea>
				</div>
			</S.listItem>
		);
	};
	// 배송 진행 상태 확인
	const deliveryState = (deliveryStateCode) => {
		switch (deliveryStateCode) {
			case '00': // 배송요청
				return (
					<S.badge>
						<S.bodyText_XS color={S.color.primary}>Permintaan Pengiriman</S.bodyText_XS>
					</S.badge>
				);
			case '10': // 배송준비
				return (
					<S.badge>
						<S.bodyText_XS color={S.color.primary}>Siap Dikirim</S.bodyText_XS>
					</S.badge>
				);
			case '20': // 배송중
				return (
					<S.badge className="disabled">
						<S.bodyText_XS color={S.color.gray600}>-</S.bodyText_XS>
					</S.badge>
				);
			case '30': // 배송완료
				return (
					<S.badge className="disabled">
						<S.bodyText_XS color={S.color.gray600}>-</S.bodyText_XS>
					</S.badge>
				);
			case '01': // 결제대기
				return (
					<S.badge className="disabled">
						<S.bodyText_XS color={S.color.gray600}>-</S.bodyText_XS>
					</S.badge>
				);
			case '-10': // 배송취소
				return (
					<S.badge className="disabled">
						<S.bodyText_XS color={S.color.gray600}>Dibatalkan</S.bodyText_XS>
					</S.badge>
				);
			case '-01': // 결제실패
				return (
					<S.badge className="disabled">
						<S.bodyText_XS color={S.color.gray600}>-</S.bodyText_XS>
					</S.badge>
				);
		}
	};

	return (
		<S.wrapper>
			<S.flexRowBetween
				style={{
					margin: '2rem 1.4rem 0',
					paddingBottom: '1rem',
				}}>
				<S.bodyText_S color={S.color.gray600}>Riwayat pengiriman selama 3 bulan terakhir</S.bodyText_S>
				<S.searchFilter
					onClick={() => {
						if (isLoading) return;
						setOpenFilter(true);
					}}>
					<img src={sliders} />
					{filter.length > 0 && (
						<p>
							<S.bodyText_XS color="white">{filter.length}</S.bodyText_XS>
						</p>
					)}
				</S.searchFilter>
			</S.flexRowBetween>
			<RefreshHandler
				handleRefresh={() => {
					deliveryReset();
				}}
				contents={
					<S.wrapper>
						{deliveryDatas.length === 0 || deliveryDatas === undefined ? (
							<NoList bottom />
						) : (
							<S.presentList>
								{deliveryDatas?.map((data, index) => (
									<RenderItem key={index} data={data} type="delivery" index={index} />
								))}
							</S.presentList>
						)}
						<div ref={deliveryObserver} />
					</S.wrapper>
				}
			/>
		</S.wrapper>
	);
};

