// 보물상자 구매
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import { GoldBoxPrice, LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import Payment from "../Components/Payment";

import chestGold from "../Images/chest_gold.png";
import polygon from "../Images/polygon.png";
import boxExplain from "../Images/boxExplain.png";
import { ReactComponent as XIcon } from "../Images/x.svg";

const BoxSelectArea = styled.div`
	width: 100%;
	display: flex;
	.boxArea {
		width: 10rem;
		height: 10rem;
		border: 1px solid ${S.color.gray300};
		border-radius: 1rem;
		margin-right: 1.6rem;
		flex-shrink: 0;
		background-color: white;
	}
	img {
		width: 70%;
	}
	.countArea {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.boxExplain {
		width: 100%;
		flex-grow: 1;
		background-color: white;
		padding: 1.4rem 1.4rem 1rem;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		p {
			padding: 0.2rem 0;
			background-color: ${S.color.primary};
			border: 1px solid #f0ba8a;
			box-shadow: 0 0.2rem 0.1rem #e87c1c;
			border-radius: 0.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
		p::before,
		p::after {
			content: "";
			display: block;
			position: absolute;
			width: 0.3rem;
			height: 1.7rem;
			background-image: url(${boxExplain});
			z-index: 1;
			top: 0.6rem;
			left: 1.2rem;
		}
		p::after {
			left: unset;
			right: 1.2rem;
		}
		&::after {
			content: "";
			display: block;
			width: 1rem;
			height: 2rem;
			position: absolute;
			left: -0.8rem;
			top: 50%;
			transform: translateY(-50%);
			background-image: url(${polygon});
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
`;
const BoxCountArea = styled.div`
	margin: 1rem 0;
	border-bottom: 1px solid ${S.color.primary};
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	input {
		width: 100%;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
	button {
		width: 2.2rem;
		height: 2.2rem;
		background-color: ${S.color.gray400};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
`;
const BoxCountButtonArea = styled(S.flexRowBetween)`
	button {
		padding: 0.8rem 0;
		margin-right: 0.5rem;
		border-radius: 2rem;
		border: 1px solid ${S.color.primary};
		flex-grow: 1;
		span {
			color: ${S.color.primary};
			text-align: center;
		}
	}
	button:last-child {
		margin-right: 0;
	}
`;
// 상자 정보 팝업
const BoxInfoPopup = styled(S.popupContainer)`
	justify-content: flex-start;
	padding-top: 4.4rem;
`;

declare global {
	interface Window {
		ReactNativeWebView: {
			postMessage: (value: string) => void;
		};
	}
}

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	const goldBoxPrice = useRecoilValue(GoldBoxPrice);
	const token = localStorage.getItem("token");
	// 팝업
	const setOpenToast = useSetRecoilState(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenBoxInfo, setOpenBoxInfo] = useState<boolean>(false);
	// 선택된 상자 종류
	const selectedBoxRef = useRef<number>(0);
	const [selectedBox, setSelectedBox] = useState<number>(0);
	// 선택된 상자 개수
	const [selectedBoxCount, setSelectedBoxCount] = useState<number>(0);
	// 결제할 금액
	const [totalPrice, setTotalPrice] = useState<number>(0);
	// 결제 방식
	const [paymentType, setPaymentType] = useState<number>(2);
	// 사용할 포인트
	const [pointToUse, setPointToUse] = useState<string>("0");

	// 최종 결제 금액 업데이트
	useEffect(() => {
		// 선택된 상자 가격
		const boxPrice = selectedBox == 0 ? goldBoxPrice : 5000;
		setTotalPrice(selectedBoxCount * boxPrice);
	}, [selectedBoxCount, selectedBox]);

	// 상자 개수 선택
	const addBoxCount = (count: number) => {
		setPointToUse("0");
		if (selectedBoxCount + count >= 50) {
			setSelectedBoxCount(50);
		} else if (selectedBoxCount <= 50) {
			setSelectedBoxCount(selectedBoxCount + count);
		}
	};
	// 결제 요청 앱으로 메세지 전송
	const postMessage = (cnt) => {
		if (typeof window == undefined || !window?.ReactNativeWebView) {
			return;
		} else {
			window.ReactNativeWebView?.postMessage(
				JSON.stringify({ type: "PAYMENT", token: token, cnt: cnt })
			);
		}
	};
	// 상자 구매
	const buyBox = async () => {
		// 가상계좌 완료
		// Pembelian peti harta karun Anda akan dikonfirmasi dalam waktu maksimal 10 menit setelah pembayaran ke virtual account dilakukan.
		if (isLoading) return;
		setLoading(true);
		if (paymentType === 2) {
			// PG결제요청은 앱으로 메세지 전송
			postMessage(selectedBoxCount);
		} else {
			// 포인트 결제는 바로 api 요청
			await Api.get("/box/buy", {
				bmIdx: 2,
				bmCnt: selectedBoxCount,
				usePoint: selectedBoxCount * goldBoxPrice, // 결제방식(1 = 포인트, 2 = PG)
			}).then((res) => {
				if (res.data !== undefined) {
					if (res.data.code == 200) {
						navigate("/BUPO001001");
					}
				} else {
					alert("fail");
				}
			});
		}
		setLoading(false);
	};

	return (
		<S.wrapper>
			<Page
				title="Beli Peti Harta Karun"
				badge="information"
				badgeAction={() => setOpenBoxInfo(true)}
				contents={
					<S.pageContainer>
						<section>
							<BoxSelectArea>
								<S.flexRowCenter className="boxArea">
									<img src={chestGold} />
								</S.flexRowCenter>
								<div className="countArea">
									<S.flexRowStart
										style={{ marginBottom: "0.6rem" }}>
										<S.subhead_2
											style={{ marginRight: "0.8rem" }}>
											Gold Box
										</S.subhead_2>
										<S.bodyText_L>
											{Util.addComma(goldBoxPrice)} IDR
										</S.bodyText_L>
									</S.flexRowStart>
									<S.bodyText_XS color={S.color.gray600}>
										Anda bisa membeli maksimal 50 peti harta
										karun.
									</S.bodyText_XS>
									<BoxCountArea
										style={{
											borderBottomColor:
												selectedBoxCount > 0
													? S.color.primary
													: S.color.gray400,
										}}>
										<input
											type="number"
											placeholder="0"
											value={selectedBoxCount}
											readOnly
										/>
										<button
											onClick={() => {
												setSelectedBoxCount(0);
												setPointToUse("0");
												setPaymentType(2);
											}}>
											<XIcon fill="white" width="1rem" />
										</button>
									</BoxCountArea>
									<BoxCountButtonArea>
										<button onClick={() => addBoxCount(1)}>
											<S.subhead_4>+ 1</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(5)}>
											<S.subhead_4>+ 5</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(10)}>
											<S.subhead_4>+ 10</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(50)}>
											<S.subhead_4>Maksimal</S.subhead_4>
										</button>
									</BoxCountButtonArea>
								</div>
							</BoxSelectArea>
						</section>
						<Payment
							type={1}
							totalPrice={totalPrice}
							paymentType={paymentType}
							setPaymentType={setPaymentType}
							pointToUse={pointToUse}
							setPointToUse={setPointToUse}
						/>
					</S.pageContainer>
				}
				button={"Konfirmasi"}
				buttonAction={() => buyBox()}
				disabled={
					totalPrice == 0 ||
					(paymentType === 1 &&
						totalPrice - Util.toNumber(pointToUse) !== 0)
				}
			/>
			{/* 상자 정보 팝업 */}
			<BoxInfoPopup
				$isOpen={isOpenBoxInfo}
				onClick={() => setOpenBoxInfo(false)}>
				<div style={{ margin: "2rem 0", width: "calc(100% - 2.8rem)" }}>
					<BoxSelectArea onClick={(e) => e.stopPropagation()}>
						<S.flexRowCenter className="boxArea">
							<img src={chestGold} />
						</S.flexRowCenter>
						<div className="boxExplain">
							<p>
								<S.mainTitle_M color="white">
									Gold box
								</S.mainTitle_M>
							</p>
							<S.bodyText_S>
								Anda bisa mendapatkan harta karun setara atau di
								atas Rp 50,000
							</S.bodyText_S>
						</div>
					</BoxSelectArea>
				</div>
			</BoxInfoPopup>
		</S.wrapper>
	);
};
