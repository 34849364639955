export const config = {
	default: {
		// REACT_APP_API_URL: "http:localhost:3001",
		REACT_APP_API_URL: "https://dev.api.service.go-pang.com",
		REACT_APP_IMG_URL: "https://cdn.go-pang.com/",
		REACT_APP_GOOGLE_AUTH_CLIENT_ID:
			"846051296059-rdk4qhicgch3g9p74q8q67mcmf5did75.apps.googleusercontent.com",
	},
	development: {
		REACT_APP_API_URL: "https://dev.api.service.go-pang.com",
		REACT_APP_IMG_URL: "https://cdn.go-pang.com/",
		REACT_APP_GOOGLE_AUTH_CLIENT_ID:
			"846051296059-rdk4qhicgch3g9p74q8q67mcmf5did75.apps.googleusercontent.com",
	},
	production: {
		REACT_APP_API_URL: "https://api.service.go-pang.com",
		REACT_APP_IMG_URL: "https://cdn.go-pang.com/",
		REACT_APP_GOOGLE_AUTH_CLIENT_ID:
			"846051296059-rdk4qhicgch3g9p74q8q67mcmf5did75.apps.googleusercontent.com",
	},
};
