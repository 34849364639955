// 문의하기
import React, { useState, useEffect, useRef } from 'react';
import Api from '../libs/api';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { UserAtom, LoadingAtom } from '../Recoil/Atoms/UserState';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';
import Util from '../Store/Util';

import * as S from '../Styles/Style';
import Page from '../Components/Page';
import Filter from '../Components/Filter';
import Modal from '../Components/Modal';

import { ReactComponent as Plus } from '../Images/plus.svg';
import { ReactComponent as XIcon } from '../Images/x.svg';
import { ReactComponent as Chevron } from '../Images/chevron.svg';

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 얼럿 생성
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	// 양식에 맞지 않는 파일 모달
	const [isOpenFileExplain, setOpenFileExplain] = useState(false);
	// 문의 완료 모달
	const [isOpenModal, setOpenModal] = useState(false);
	// 상담분류
	const [isOpenSelect, setOpenSelect] = useState<boolean>(false); // 선택 팝업 생성
	const [category, setCategory] = useState<number>(null); // 선택된 상담분류
	const [categoryText, setCategoryText] = useState<string>(''); // 선택된 상담분류
	const options = [
		{ id: 1, text: 'Penggunaan' }, // 이용 문의
		{ id: 2, text: 'Pembayaran' }, // 결제 문의
		{ id: 4, text: 'Pengiriman' }, // 배송 문의
		{ id: 7, text: 'Akun' }, // 회원 문의
		{ id: 8, text: 'Event' }, // 이벤트 문의
		{ id: 99, text: 'Komplain' }, // 오류제보
		{ id: 6, text: 'Lainnya' }, // 기타
	];
	const [formTitle, setFormTitle] = useState<string>('');
	const [formText, setFormText] = useState<string>('');
	const [formEmail, setFormEmail] = useState<string>(userState.email);
	// 업로드될 파일
	const [imgFile, setImgFile] = useState([]);
	// 업로드된 파일 개수
	const [fileCnt, setFileCnt] = useState<number>(0);
	// 필수 작성 여부, 유효성 확인
	const requiredRef = useRef([]);
	const [invalid, setInvalid] = useState<boolean>(true);
	const [incomplete, setIncomplete] = useState<boolean>(true);
	const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

	// 파일 업로드
	const setThumbnail = (e) => {
		const fileArr = e.target.files;

		let maxFileCnt = 3; // 첨부파일 최대 개수
		let remainFileCnt = maxFileCnt - imgFile.length; // 추가로 첨부가능한 개수
		let curFileCnt = fileArr.length; // 현재 선택된 첨부파일 개수

		// 첨부파일 개수 확인
		if (curFileCnt > remainFileCnt) {
			setToastType(1);
			setToastContents('Lampirkan maksimal 3 foto.');
			setOpenToast(true);
		}

		let fileURLs = [];
		let file;

		for (let i = 0; i < Math.min(curFileCnt, remainFileCnt); i++) {
			file = fileArr[i];
			let reader = new FileReader();
			console.log('file : ', file.type);
			// image/png
			if (file.type.includes('image')) {
				// 20MB 용량 제한
				if (file.size > 20 * 1024 * 1024) {
					setToastType(1);
					setToastContents('Ukuran lampiran terlalu besar.');
					setOpenToast(true);
					return false;
				}
			} else if (file.type.includes('video')) {
				// 50MB 용량 제한
				if (file.size > 50 * 1024 * 1024) {
					setToastType(1);
					setToastContents('Ukuran lampiran terlalu besar.');
					setOpenToast(true);
					return false;
				}
			}
			reader.onload = () => {
				fileURLs[i] = reader.result;
				setImgFile([...imgFile, { image: reader.result as string, file: file }]);
			};
			reader.readAsDataURL(file);
		}
	};
	useEffect(() => {
		setFileCnt(imgFile.length);
	}, [imgFile]);
	// 업로드 파일 미리보기 영역
	const ImageArea = imgFile.map((item, index) => {
		return (
			<div className="imageArea" key={index}>
				<img src={item.image} alt="" />
				<button onClick={() => setImgFile(imgFile.filter((element) => element.image !== item.image))} />
			</div>
		);
	});
	// 작성 중 유효성 체크
	const validation = (e) => {
		e.target.className = '';
		if (
			// 제목 3자 이상 작성
			(e.target.id == 'formTitle' && e.target.value.length < 3) ||
			// 이메일 형식 준수
			(e.target.id == 'formEmail' && !exptext.test(e.target.value))
		) {
			e.target.className = 'lack';
		}
		// 상담분류, 제목, 내용 필수 작성
		setIncomplete(requiredRef.current.some((el) => el.value.length == 0));
		// 작성 기준
		setInvalid(requiredRef.current.some((el) => el.className == 'lack'));
	};
	// 인풋 나갈 때 안내 팝업 생성
	const onBlur = (e) => {
		// 인풋 별 팝업 문구 설정
		const toastContents = () => {
			switch (e.target.id) {
				case 'formTitle':
					return 'Masukkan minimal 3 huruf';
					break;
				case 'formEmail':
					return '유효하지 않은 이메일';
					break;
			}
		};
		if (
			// 제목 3자 이상 작성
			(e.target.id == 'formTitle' && e.target.value.length < 3) ||
			// 이메일 형식 준수
			(e.target.id == 'formEmail' && !exptext.test(e.target.value))
		) {
			setToastType(1);
			setToastContents(toastContents);
			setOpenToast(true);
			e.target.className = 'lack';
		}
	};
	// 문의 등록 버튼 클릭
	const onSubmit = async () => {
		if (isLoading) return;
		setLoading(true);
		const formData = new FormData();
		const questionSubject = (document.getElementById('formTitle') as HTMLInputElement).value;
		const questionText = (document.getElementById('formText') as HTMLInputElement).value;
		formData.append('categoryCode', category.toString());
		formData.append('questionSubject', questionSubject);
		formData.append('questionText', questionText);
		imgFile.map((item, index) => {
			formData.append(`file${index + 1}`, item.file);
		});
		for (let key of formData.keys()) {
			console.log(key, ':', formData.get(key));
		}
		await Api.post('/board/qnaInsert', formData).then((res) => {
			if (res.data !== undefined) {
				if (res.data.statusCode == 200) {
					// 작성완료
					setOpenModal(true);
				} else {
					console.log('eeeeeellllllllse : ', res.data);
				}
			} else {
				alert('fail');
			}
		});

		setLoading(false);
	};
	// 연속 줄바꿈 제한
	const textCheck = (text: string) => {
		var txt = text.replace(/\n\n\n$/gm, '\n\n');
		setFormText(txt);
	};

	return (
		<>
			<Page
				title="Tulis Pertanyaan"
				contents={
					<S.pageContainer>
						<section>
							<h2>
								<S.subhead_4>Pilih kategori pertanyaan</S.subhead_4>{' '}
								<S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField className="select" onClick={() => setOpenSelect(true)}>
								<div className="inputWrap">
									<input type="text" placeholder="Pengaturan" value={categoryText} readOnly />
									<Chevron fill={S.color.gray600} />
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Judul</S.subhead_4> <S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="formTitle"
										type="text"
										placeholder="Masukkan dalam 30 karakter"
										onBlur={(e) => onBlur(e)}
										value={formTitle}
										onChange={(e) => {
											setFormTitle(e.target.value);
											validation(e);
										}}
										maxLength={30}
										ref={(el) => (requiredRef.current[0] = el)}
									/>
									<S.inputCount>
										<S.bodyText_XS>{formTitle.length}/30</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Isi</S.subhead_4> <S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<textarea
										id="formText"
										rows={5}
										placeholder="Masukkan dalam 1,000 karakter."
										value={formText}
										onChange={(e) => {
											textCheck(e.target.value);
											validation(e);
										}}
										maxLength={1000}
										ref={(el) => (requiredRef.current[1] = el)}></textarea>
									<S.inputCount className="inTextArea">
										<S.bodyText_XS>{formText.length}/1000</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Lampiran</S.subhead_4>
							</h2>
							<S.uploadFile>
								<input
									id="uploadFile"
									type="file"
									accept="image/jpg,image/png,image/jpeg,image/gif,video/mp4,video/mov"
									onChange={setThumbnail}
									// multiple
								/>
								<button
									id="addFileButton"
									onClick={() => document.getElementById('uploadFile').click()}>
									<Plus fill="white" />
									<S.bodyText_XS color="white">
										{fileCnt}
										/3
									</S.bodyText_XS>
								</button>
								<div id="imageContainer">{ImageArea}</div>
							</S.uploadFile>
							{/* <p style={{ marginTop: "0.8rem" }}>
								<S.bodyText_XS color={S.color.gray600}>
									Anda bisa melampirkan maksimal 3 file foto
									ukuran masing-masing 20 MB dengan format
									GIF, PNG, JPG.
								</S.bodyText_XS>
							</p> */}
							<S.textList $gray style={{ marginTop: '1.2rem' }}>
								<li>
									<S.bodyText_S>
										Gambar: GIF, PNG, JPG. Ukuran maksimal setiap gambar adalah 20MB.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Video: MP4, MOV. Ukuran maksimal setiap video adalah 50MB.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Jumlah File: Anda dapat mengunggah maksimal 3 file (gabungan gambar dan video).
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Penyimpanan dan Penghapusan File: File yang Anda unggah untuk keperluan
										pengembalian atau pertukaran barang akan dihapus secara otomatis 1 bulan setelah
										proses pengembalian/pertukaran selesai dan percakapan Anda dengan kami berakhir.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
					</S.pageContainer>
				}
				button="Kirim"
				buttonAction={() => onSubmit()}
				// 필수 값 비어있거나 작성 기준에 맞지 않는 경우 버튼 비활성화
				disabled={incomplete || invalid || category === null}
				absolute
			/>
			{/* 문의 분류 */}
			<Filter
				isOpen={isOpenSelect}
				close={() => setOpenSelect(false)}
				select={category}
				setSelect={setCategory}
				setSelectText={setCategoryText}
				options={options}
			/>
			{/* 양식에 맞지 않는 파일 */}
			<Modal
				isOpen={isOpenFileExplain}
				contents={
					<>
						<h2>
							<S.headline_4>Format file tidak didukung</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda dapat melampirkan foto berukuran maks. 20 MB dengan format GIF, PNG, atau JPG.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL={'Konfirmasi'}
				buttonActionL={() => setOpenFileExplain(false)}
			/>
			{/* 문의 완료 */}
			<Modal
				isOpen={isOpenModal}
				contents={
					<>
						<h2>
							<S.headline_4>Pertanyaan Anda berhasil dikirim</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Jawaban dapat dilihat di Pertanyaan Saya.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL={'OK'}
				buttonActionL={() => navigate('/HELP004001')}
			/>
		</>
	);
};

