// 분해하기/합성하기
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
	LoadingAtom,
	UserAtom,
	NewsPrice,
	DecompositionRate,
} from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import Filter from "../Components/Filter";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";
import Modal from "../Components/Modal";
import UPGD001002 from "./UPGD001002";

import { ReactComponent as Check } from "../Images/check.svg";
import { ReactComponent as Chevron } from "../Images/chevron.svg";
import { ReactComponent as Arrow } from "../Images/arrow_right.svg";
import point from "../Images/point.png";
import compose_title from "../Images/compose_title.png";
import compose_title_ribbon from "../Images/compose_title_ribbon.png";
import { ReactComponent as Question } from "../Images/compose_q.svg";
import { config } from "../config/config";

// 분해
const SelecArea = styled(S.flexRowBetween)`
	width: fit-content;
	max-width: 12.8rem;
	height: 2.8rem;
	border: 1px solid ${S.color.gray300};
	border-radius: 0.6rem;
	margin: 1.4rem;
	padding: 0 0.2rem 0 1rem;
	span {
		color: ${S.color.gray600};
	}
	svg {
		height: 1.8rem;
		transform: rotate(90deg);
	}
`;
const TreasureList = styled.div`
	width: 100%;
	padding: 0.2rem 1.4rem;
	display: flex;
	flex-wrap: wrap;
`;
const TreasureListItem = styled.button<{ $checked?: boolean; $news?: boolean }>`
	width: calc(50% - 0.5rem);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 1.4rem;
	border-radius: 1rem;
	overflow: hidden;
	position: relative;
	border: 1px solid ${S.color.gray300};
	border-color: ${(props) => props.$checked && S.color.primaryVariant};
	background-color: ${(props) => props.$checked && props.$news && "#FFF8EE"};
	.itemImage {
		width: 100%;
		height: 10rem;
		position: relative;
		flex-shrink: 0;
	}
	.itemInfo {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 0.5rem 1.2rem 1.2rem;
	}
	.name {
		margin-bottom: 0.6rem;
	}
	.checkCircle {
		display: ${(props) => props.$checked && "flex"};
	}
`;
const Circle = styled(S.flexRowCenter)`
	width: 2.2rem;
	height: 2.2rem;
	background-color: ${S.color.primaryVariant};
	display: none;
	border-radius: 50%;
	position: absolute;
	left: 1.2rem;
	top: 1.2rem;
	svg {
		width: 70%;
		height: 70%;
		fill: white;
		stroke: white;
	}
`;
const CheckedInfoArea = styled.div`
	width: 100%;
	height: 0;
	background-color: ${S.color.dimmed70};
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	bottom: 0;
	transition: height 100ms linear;
	&.view {
		height: 13rem;
	}
	button {
		width: 100%;
		background-color: ${S.color.primary};
		flex-grow: 1;
		color: white;
	}
`;
const CheckedInfo = styled.div`
	margin: 1.4rem;
	& > div {
		margin-bottom: 0.6rem;
	}
	& > div:last-child {
		margin-bottom: 0;
	}
`;
const DecomConfirmArea = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${S.color.gray100};
	border-radius: 0.6rem;
	padding: 1.6rem 0 2rem;
	margin-bottom: 2rem;
`;
const DecomConfirmWrap = styled.div`
	width: 100%;
	display: flex;
	overflow-x: scroll;
	& > div:first-child {
		margin-left: 1.6rem;
	}
	& > div:last-child {
		margin-right: 1.6rem;
	}
`;
const DecomConfirmImage = styled.div<{ $news?: boolean }>`
	width: 8rem;
	height: 8rem;
	margin-left: 1rem;
	flex-shrink: 0;
	border: 1px solid ${S.color.gray400};
	border-color: ${(props) => props.$news && S.color.primaryVariant};
	background-color: white;
	background-color: ${(props) => props.$news && "#FFF8EE"};
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	img {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const DecomConfirmInfo = styled.div`
	margin: 1.6rem 1.6rem 0;
	p {
		width: 40%;
	}
	p:last-child {
		text-align: right;
	}
`;
const CompletePoint = styled(S.flexRowCenter)`
	flex-direction: column;
	background-color: ${S.color.gray100};
	border-radius: 0.6rem;
	padding: 1.6rem;
`;
// 합성
const TitleArea = styled(S.flexRowCenter)`
	flex-direction: column;
	margin-top: 1.4rem;
	padding-top: 5.4rem;
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 5.7rem;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%);
		background-image: url(${compose_title});
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
`;
const Ribbon = styled(S.flexRowCenter)`
	/* width: 80%; */
	height: 2.6rem;
	margin-bottom: 1.2rem;
	/* background-color: ${S.color.primary}; */
	background-color: #ffa443;
	position: relative;
	&::before,
	&::after {
		content: "";
		display: block;
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		left: -2rem;
		background-image: url(${compose_title_ribbon});
		background-size: contain;
		background-repeat: no-repeat;
		z-index: -1;
	}
	&::after {
		left: unset;
		right: -2rem;
		transform: rotate(180deg);
	}
`;
const SpecialArea = styled(S.flexRowCenter)`
	margin: 1rem 0.7rem 1.4rem;
`;
const SpecialItem = styled(S.flexRowCenter)`
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	width: 1%;
	max-width: 35%;
	height: 18rem;
	border: 1px solid #3d2100;
	border-radius: 1rem;
	overflow: hidden;
	margin: 0 0.5rem;
`;
const SpecialItemImage = styled(S.flexRowCenter)`
	width: 100%;
	height: 10.3rem;
	background-color: #ffea88;
	padding: 0.8rem;
	div {
		width: 100%;
		height: 100%;
		background-color: white;
		border-radius: 0.6rem;
		box-shadow: inset 0 0.2rem #f7bb6e;
		position: relative;
		flex-direction: column;
		overflow: hidden;
		img {
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.soldout {
		z-index: 0;
	}
	img.soldout {
		opacity: 0.2;
	}
`;
const SpecialItemInfo = styled(S.flexRowCenter)`
	flex-shrink: 0;
	flex-direction: column;
	width: 100%;
	height: 7.7rem;
	background-color: #ffcd6b;
	box-shadow: inset 0 -0.4rem #ee831b;
	border-radius: 0 0 1rem 1rem;
	padding: 0.4rem 0.8rem;
	span {
		text-align: center;
	}
	.userProfile {
		width: 3.4rem;
		height: 3.4rem;
		border: 1px solid #3d2100;
		border-radius: 1rem;
		background-color: #ffeb89;
		padding: 0.4rem;
		div {
			background-color: white;
			height: 100%;
			border-radius: 0.8rem;
			overflow: hidden;
		}
	}
	.userNick {
		height: 2rem;
		border: 1px solid #3d2100;
		background-color: #ffeb89;
		padding: 0.2rem 2rem;
		border-radius: 2rem;
		margin-top: 0.3rem;
	}
`;
const NormalArea = styled(S.flexRowStart)`
	height: 5rem;
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	background-color: ${S.color.gray200};
	padding: 1.4rem 1rem 0;
	overflow-y: scroll;
	& > span {
		text-align: center;
		margin-bottom: 0.4rem;
	}
`;
const NormalItem = styled(S.flexRowCenter)`
	width: 100%;
	padding: 1.2rem;
	background-color: white;
	border-radius: 1rem;
	box-shadow: 0 0.3rem ${S.color.gray300};
	margin-bottom: 1rem;
	position: relative;
	.itemImage {
		width: 9rem;
		height: 7.3rem;
		border: 1px solid ${S.color.gray200};
		border-radius: 0.8rem;
		margin-right: 2.2rem;
		flex-shrink: 0;
		overflow: hidden;
		position: relative;
		img {
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.count {
		width: 3rem;
		height: 3rem;
		background-color: ${S.color.gray300};
		position: absolute;
		left: 8.8rem;
		top: 5.7rem;
		bottom: -0.2rem;
		border-radius: 50%;
	}
	img.soldout {
		opacity: 0.2;
	}
	.itemName {
		flex-grow: 1;
		margin-right: 1rem;
	}
`;
const Explain = styled(S.bodyText_S)`
	text-align: center;
	padding: 0.6rem;
`;

const ComConfirmArea = styled.div`
	display: flex;
	background-color: ${S.color.gray100};
	border-radius: 0.6rem;
	padding: 1.4rem;
	margin-bottom: 2rem;
`;
const ComConfirmImage = styled.div<{ $news?: boolean }>`
	width: 14.2rem;
	height: 12.7rem;
	flex-shrink: 0;
	border-color: ${(props) => props.$news && S.color.primaryVariant};
	background-color: white;
	background-color: ${(props) => props.$news && "#FFF8EE"};
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	img {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	div {
		width: 4rem;
		height: 4rem;
		border-radius: 4rem;
		background-color: ${S.color.primary};
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		span {
			color: white;
		}
	}
`;
const ComConfirmInfo = styled(S.flexRowCenter)`
	flex-direction: column;
	align-items: flex-start;
	margin-left: 1.2rem;
`;

interface decomData {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
}
interface masterData {
	pcmIdx: string;
	title: string;
	memo: string;
	startDt: string;
	endDt: string;
	displayYn: string;
	useYn: string;
	reg: string;
}
interface comData {
	pcmIdx: string;
	pccIdx: string;
	imageUrl: string;
	percent: string;
	componentWeight: number;
	composeType: string;
	name: string;
	limitCnt: number;
	getter: { nick: string; profileImageUrl: string };
}

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 뉴스룸 등록 기준 금액
	const newsPrice = useRecoilValue(NewsPrice);
	// 분해 반환 포인트율
	const decompositionRate = useRecoilValue(DecompositionRate);
	// 황성화 탭 인덱스
	const defaultTab = location.state ? location.state.data : 0;
	const [activeTab, setActiveTab] = useState<number>(defaultTab);
	// 팝업
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenNewsConfirm, setOpenNewsConfirm] = useState<boolean>(false);
	const [isOpenCompleteConfirm, setOpenCompleteConfirm] =
		useState<boolean>(false);
	const [isOpenDecomPopup, setOpenDecomPopup] = useState<boolean>(false);
	const [isOpenComposeDisable, setOpenComposeDisable] =
		useState<boolean>(false);
	const [isOpenUPGD, setOpenUPGD] = useState<boolean>(false);
	const [UPGDTarget, setUPGDTarger] = useState<comData>();
	const [isOpenComPopup, setOpenComPopup] = useState<boolean>(false);
	// 정렬
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const defaultOrderBy = 2;
	const [orderBy, setOrderBy] = useState<number>(defaultOrderBy);
	const orderByRef = useRef<number>(null);
	const sortList = [
		{ id: 0, text: "Dari harga tertinggi" }, // 가격 높은순
		{ id: 1, text: "Dari harga terendah" }, // 가격 낮은순
		{ id: 2, text: "Dari tanggal terbaru" }, // 최신순
		{ id: 3, text: "Dari tanggal terlama" }, // 오래된순
	];
	// 보유 보물 리스트 정보
	const [decomDatas, setDecomDatas] = useState<decomData[]>([]);
	const [decomNextPage, setDecomNextPage] = useState<boolean>(true);
	const decomPage = useRef<number>(1);
	const decomObserver = useRef<HTMLDivElement>(null);
	// 선택된 보물 정보
	const [checkedItem, setCheckedItem] = useState<decomData[]>([]);
	const [checkedPrice, setCheckedPrice] = useState<number>(0);
	const [checkedNewsItem, setCheckedNewsItem] = useState<decomData>();
	// 분해 정보 영역
	const anchorRef = useRef<HTMLDivElement>(null);
	// 합성 대상 리스트
	const [comMasterDatas, setComMasterDatas] = useState<masterData>();
	const [comDatas, setComDatas] = useState<comData[]>([]);
	// 보유 보물 리셋
	const decomReset = () => {
		setCheckedItem([]);
		setCheckedPrice(0);
		setDecomDatas([]);
		setDecomNextPage(true);
		decomPage.current = 1;
	};
	// 합성 대상 리셋
	const comReset = () => {
		setComDatas([]);
	};
	// 보유 보물 목록 조회
	const decomFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("decomFetch", orderByRef.current);
		await Api.get("/present/memberPresentList", {
			stateCode: "00",
			page: decomPage.current,
			orderBy: orderByRef.current ?? orderBy,
			lockYn: 0,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				setDecomDatas((prevDatas) => [...prevDatas, ...data]);
				setDecomNextPage(data.length === 10);
				if (data.length) {
					decomPage.current += 1;
				}
			} else {
				alert("fail");
			}
		});

		setLoading(false);
	};
	// 합성 대상 목록 조회
	const comFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("comFetch");
		await Api.get("/present/presentComposeList", {
			// pccIdx: 1,
		}).then((res) => {
			const data = res.data.data;
			// alert(data.masterData);
			setComMasterDatas(data.masterData);
			// setComMasterDatas(null);
			if (data.composeData) {
				setComDatas(data.composeData);
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (!decomObserver.current || !decomNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				decomFetch();
			}
		});
		io.observe(decomObserver.current);

		return () => {
			io.disconnect();
		};
	}, [decomNextPage, activeTab]);
	useEffect(() => {
		comFetch();
	}, [activeTab]);
	// 리셋
	useEffect(() => {
		if (activeTab === 0) {
			orderByRef.current = orderBy;
			decomReset();
			console.log(orderBy);
		} else {
			comReset();
		}
	}, [orderBy]);
	// 분해 아이템 선택
	const checkItem = async (data: decomData, agree?: boolean) => {
		let newData = [...checkedItem];
		let newPrice = checkedPrice;
		if (newData.includes(data)) {
			// 선택되어있는 보물인 경우
			for (let i = 0; i < newData.length; i++) {
				if (newData[i] === data) {
					newData.splice(i, 1);
					i--;
				}
			}
			newPrice = newPrice - data.boxPrice;
		} else {
			// 선택되어있지 않은 보물인 경우
			// 50개 이상 선택되었는지 여부 확인
			if (newData.length >= 50) {
				// 50개 초과 선택 불가
				setToastType(1);
				setToastContents(
					"Jumlah maksimal sekali penukaran adalah 50 buah."
				);
				setOpenToast(true);
			} else {
				if (data.displayPrice >= newsPrice && !agree) {
					// 뉴스룸 보물 선택 확인 팝업 생성
					setOpenNewsConfirm(true);
					setCheckedNewsItem(data);
				} else {
					newData.push(data);
					newPrice = newPrice + data.boxPrice;
				}
			}
		}
		setCheckedItem(newData);
		setCheckedPrice(newPrice);
	};
	// 분해 하기
	const decomposition = async () => {
		if (isLoading) return;
		setLoading(true);
		let pumIdxArr = [];
		for (const item of checkedItem) {
			pumIdxArr.push(item.pumIdx);
		}
		console.log(pumIdxArr);
		await Api.post(`/present/decomposition`, { pumIdx: pumIdxArr }).then(
			(res) => {
				if (res.data !== undefined) {
					if (res.data.statusCode === 200) {
						setOpenDecomPopup(false); // 현재 모달 닫기
						setOpenCompleteConfirm(true); // 완료 모달 열기
					}
				} else {
					alert("fail");
				}
			}
		);

		setLoading(false);
	};
	// 합성 대상 리스트 - 스페셜
	const specialItem = () => {
		const specialData = comDatas.filter((item) => item.composeType == "0");
		const isSoldout = (data) => {
			return data.limitCnt <= 0;
		}; // 품절 여부 확인
		// if (comDatas[0] !== undefined) {
		// 	alert(specialData.length);
		// }
		return (
			<>
				{comMasterDatas ? (
					<>
						{specialData.map((data) => {
							return (
								<SpecialItem
									onClick={() => {
										setUPGDTarger(data);
										isSoldout(data)
											? setOpenComposeDisable(true)
											: setOpenUPGD(true);
									}}
								>
									<SpecialItemImage>
										<S.flexRowEnd>
											<img
												className={
													isSoldout(data) && "soldout"
												}
												src={imgUrl + data.imageUrl}
												alt=""
											/>
											{isSoldout(data) && (
												<S.subhead_4
													className="soldout"
													color={S.color.error}
												>
													SOLD OUT
												</S.subhead_4>
											)}
										</S.flexRowEnd>
									</SpecialItemImage>
									<SpecialItemInfo>
										{isSoldout(data) ? (
											<>
												<div className="userProfile">
													<div>
														<img
															src={
																imgUrl +
																data.getter
																	.profileImageUrl
															}
															alt=""
														/>
													</div>
												</div>
												<div className="userNick">
													<S.bodyText_XS>
														{data.getter.nick}
													</S.bodyText_XS>
												</div>
											</>
										) : (
											<>
												<S.bodyText_S
													$ellipsis={3}
													style={{ flexGrow: 1 }}
												>
													{data.name}
												</S.bodyText_S>
												<S.subhead_4
													color={S.color.error}
												>
													{Util.addComma(
														data.componentWeight
													)}
													P
												</S.subhead_4>
											</>
										)}
									</SpecialItemInfo>
								</SpecialItem>
							);
						})}
					</>
				) : (
					<SpecialItem>
						<SpecialItemImage>
							<S.flexRowCenter>
								<Question />
							</S.flexRowCenter>
						</SpecialItemImage>
						<SpecialItemInfo></SpecialItemInfo>
					</SpecialItem>
				)}
			</>
		);
	};
	// 합성 대상 리스트 - 일반
	const normalItem = () => {
		const normalData = comDatas.filter((item) => item.composeType == "1");
		const isSoldout = (data) => {
			return data.limitCnt <= 0;
		}; // 품절 여부 확인
		return (
			<>
				{normalData.map((data) => {
					return (
						<NormalItem
							onClick={() => {
								setUPGDTarger(data);
								isSoldout(data)
									? setOpenComposeDisable(true)
									: setOpenUPGD(true);
							}}
						>
							<div className="itemImage">
								<img
									className={isSoldout(data) && "soldout"}
									src={imgUrl + data.imageUrl}
									alt=""
								/>
							</div>
							<S.subhead_3 $ellipsis={1} className="itemName">
								{data.name}
							</S.subhead_3>
							<S.subhead_3 color={S.color.error}>
								{isSoldout(data)
									? "SOLD OUT"
									: `${Util.addComma(data.componentWeight)}P`}
							</S.subhead_3>
							<S.flexRowCenter className="count">
								<S.subhead_4 color="white">
									x{data.limitCnt}
								</S.subhead_4>
							</S.flexRowCenter>
						</NormalItem>
					);
				})}
			</>
		);
	};
	// 합성 하기
	const composition = async () => {
		if (isLoading) return;
		setLoading(true);
		let pumIdxArr = [];
		for (const item of checkedItem) {
			pumIdxArr.push(item.pumIdx);
		}
		alert(pumIdxArr);
		// await Api.post(`/present/compose`, {
		// 	pumIdx: pumIdxArr,
		// 	pccIdx: 0, // 합성 타겟 일련번호
		// 	count: 0, // 합성 획득 갯수
		// })
		// 	.then((res) => {
		// 		console.log(res.data);
		// 		if (res.data.statusCode === 200) {
		// 			setOpenDecomPopup(false); // 현재 모달 닫기
		// 			setOpenCompleteConfirm(true); // 완료 모달 열기
		// 		}
		// 	})
		// .catch((error) => {
		// 	if (error.response.data.error == -999) {
		// 		setOpenOverlap(true);
		// 	} else {
		// 		console.log(error.response.data);
		// 	}
		// });
		setLoading(false);
	};

	return (
		<>
			<Page
				title="Tukar / Gabung"
				contents={
					<TabMenu
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabs={[
							{
								index: 0,
								name: "Harta Karun",
								content: (
									<>
										{/* 정렬 */}
										<SelecArea
											onClick={() => setOpenFilter(true)}
										>
											<S.bodyText_S $ellipsis={1}>
												{sortList[orderBy].text}
											</S.bodyText_S>
											<Chevron fill={S.color.gray600} />
										</SelecArea>
										{/* 리스트 */}
										<RefreshHandler
											handleRefresh={() => decomReset()}
											contents={
												<S.wrapper>
													{decomDatas.length === 0 ? (
														<NoList type={2} />
													) : (
														<>
															<TreasureList>
																{decomDatas?.map(
																	(
																		decomData,
																		index
																	) => (
																		<TreasureListItem
																			onClick={() =>
																				checkItem(
																					decomData
																				)
																			}
																			key={
																				decomData.pumIdx
																			}
																			style={{
																				marginRight:
																					index %
																						2 ===
																						0 &&
																					"1rem",
																			}}
																			$checked={checkedItem.includes(
																				decomData
																			)}
																			$news={
																				decomData.displayPrice >=
																				newsPrice
																			}
																		>
																			<div className="itemImage">
																				<S.heightImg
																					src={
																						imgUrl +
																						decomData.imageUrl
																					}
																				/>
																			</div>
																			<div className="itemInfo">
																				<S.subhead_4
																					color="black"
																					className="name"
																					$ellipsis={
																						2
																					}
																				>
																					{
																						decomData.name
																					}
																				</S.subhead_4>
																				<p>
																					<S.bodyText_XS
																						color={
																							S
																								.color
																								.gray600
																						}
																					>
																						Harga
																						normal
																						&nbsp;|
																						&nbsp;
																						{Util.addComma(
																							decomData.displayPrice
																						)}{" "}
																						IDR
																					</S.bodyText_XS>
																				</p>
																			</div>
																			<Circle className="checkCircle">
																				<Check />
																			</Circle>
																		</TreasureListItem>
																	)
																)}
															</TreasureList>
															<div
																style={{
																	height:
																		checkedItem.length ===
																		0
																			? 0
																			: "15rem",
																}}
															/>
														</>
													)}
													<div ref={decomObserver} />
												</S.wrapper>
											}
										/>
										<CheckedInfoArea
											ref={anchorRef}
											className={
												checkedItem.length === 0
													? ""
													: "view"
											}
										>
											<CheckedInfo>
												<S.flexRowBetween>
													<S.bodyText_M_long color="white">
														Harta karun terpilih
													</S.bodyText_M_long>
													<S.subhead_3 color="white">
														{checkedItem.length}
														(buah)
													</S.subhead_3>
												</S.flexRowBetween>
												<S.flexRowBetween>
													<S.bodyText_M_long color="white">
														Poin yang akan didapat
													</S.bodyText_M_long>
													<S.subhead_3 color="white">
														{Util.addComma(
															checkedPrice *
																decompositionRate
														)}
														P
													</S.subhead_3>
												</S.flexRowBetween>
											</CheckedInfo>
											<button
												onClick={() =>
													setOpenDecomPopup(true)
												}
											>
												<S.button_M color="white">
													Tukarkan ke Poin
												</S.button_M>
											</button>
										</CheckedInfoArea>
									</>
								),
							},
							{
								index: 1,
								name: "Status Transaksi",
								content: (
									<S.wrapper>
										{comMasterDatas ? (
											<TitleArea>
												<Ribbon>
													<S.bodyText_S color="white">
														harta karun sintetis{" "}
														{Util.dateFormat(
															comMasterDatas.startDt,
															"date"
														)}
														{" ~ "}
														{Util.dateFormat(
															comMasterDatas.endDt,
															"date"
														)}
													</S.bodyText_S>
												</Ribbon>
												<S.subhead_3
													color={
														S.color.primaryVariant
													}
												>
													Pilih harta karun yang anda
													inginkan.
												</S.subhead_3>
												<S.bodyText_S
													color={S.color.gray500}
												>
													(Tidak bisa memilih lebih
													dari satu)
												</S.bodyText_S>
											</TitleArea>
										) : (
											<TitleArea>
												<Ribbon>
													<S.bodyText_S color="white">
														Harta karun baru akan
														segera tersedia
													</S.bodyText_S>
												</Ribbon>
												<S.subhead_3
													color={
														S.color.primaryVariant
													}
												>
													Harta Karun Gabungan Sedang
													Dalam Persiapan
												</S.subhead_3>
											</TitleArea>
										)}
										<SpecialArea>
											{specialItem()}
										</SpecialArea>
										{comMasterDatas && (
											<React.Fragment>
												<NormalArea>
													<S.bodyText_M_long>
														Jumlah campuran tersebut
														diupdate{"\n"}setiap
														hari pukul 15.00.
													</S.bodyText_M_long>
													{normalItem()}
												</NormalArea>
												<Explain
													color={S.color.gray800}
												>
													위 합성 보물은 한정 수량으로
													조기품절이 될 수 있습니다.
													보물 하단에 기재된 수량을
													확인하시기 바랍니다.
												</Explain>
											</React.Fragment>
										)}
									</S.wrapper>
								),
							},
						]}
					/>
				}
			/>
			{/* 정렬 */}
			<Filter
				division={0}
				isOpen={isOpenFilter}
				close={() => setOpenFilter(false)}
				defaultOrderBy={defaultOrderBy}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
			/>
			{/* 뉴스룸 보물 선택 확인 */}
			<Modal
				isOpen={isOpenNewsConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>
								Barang news room telah terpilih.
							</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Apakah Anda memilih ini?
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenNewsConfirm(false)}
				buttonTextR="Konfirmasi"
				buttonActionR={() => {
					setOpenNewsConfirm(false);
					checkItem(checkedNewsItem, true);
				}}
			/>
			{/* 분해 보물 확인 */}
			<Modal
				isOpen={isOpenDecomPopup}
				contents={
					<>
						<h2>
							<S.headline_4>Penukaran Harta Karun</S.headline_4>
						</h2>
						<DecomConfirmArea>
							<DecomConfirmWrap>
								{checkedItem?.map((item, index) => (
									<DecomConfirmImage
										key={index}
										$news={item.displayPrice >= newsPrice}
									>
										<img src={imgUrl + item.imageUrl} />
									</DecomConfirmImage>
								))}
							</DecomConfirmWrap>
							<DecomConfirmInfo>
								<S.flexRowBetween
									style={{ marginBottom: "1.2rem" }}
								>
									<p>
										<S.bodyText_S>
											Jumlah Penukaran
										</S.bodyText_S>
									</p>
									<Arrow />
									<p>
										<S.subhead_4>
											{checkedItem.length}
										</S.subhead_4>
									</p>
								</S.flexRowBetween>
								<S.flexRowBetween>
									<p>
										<S.bodyText_S>
											Poin yang didapat
										</S.bodyText_S>
									</p>
									<Arrow />
									<p>
										<S.subhead_4>
											{Util.addComma(
												checkedPrice * decompositionRate
											)}
											P
										</S.subhead_4>
									</p>
								</S.flexRowBetween>
							</DecomConfirmInfo>
						</DecomConfirmArea>
						<div style={{ textAlign: "center" }}>
							<S.bodyText_M_long color={S.color.gray800}>
								Harta karun yang telah ditukar tidak bisa{"\n"}
								dipulihkan kembali.{"\n"}Apakah Anda yakin ingin
								melanjutkan?
							</S.bodyText_M_long>
						</div>
					</>
				}
				buttonTextL={"Batal"}
				buttonActionL={() => setOpenDecomPopup(false)}
				buttonTextR={"Tukar"}
				buttonActionR={() => decomposition()}
			/>
			{/* 분해 완료 */}
			<Modal
				isOpen={isOpenCompleteConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>Penukaran Berhasil</S.headline_4>
						</h2>
						<CompletePoint>
							<img
								src={point}
								alt=""
								style={{
									height: "4.4rem",
									marginBottom: "0.3rem",
								}}
							/>
							<p>
								<S.mainTitle_S>
									Anda mendapat poin{" "}
								</S.mainTitle_S>
								<S.mainTitle_S color={S.color.primary}>
									{Util.addComma(
										checkedPrice * decompositionRate
									)}
									p!
								</S.mainTitle_S>
							</p>
						</CompletePoint>
					</>
				}
				buttonTextL="Pergi ke Klaim Harta Karun"
				buttonActionL={() =>
					navigate(
						userState.goldboxCnt > 0 ? "/BUPO001001" : "/POIN001001"
					)
				}
				buttonTextR="OK"
				buttonActionR={() => {
					setOpenCompleteConfirm(false);
					decomReset();
				}} // 분해 화면 리셋
				buttonColumn
			/>
			{/* 합성 불가 */}
			<Modal
				isOpen={isOpenComposeDisable}
				contents={
					<>
						<h2>
							<S.headline_4>
								Informasi tidak bisa melakukan pencampuran
							</S.headline_4>
						</h2>
						{/* <p> */}
						<S.bodyText_M_long color={S.color.gray800}>
							Harta karun tidak bisa dicampur karena telah terjual
							habis.
						</S.bodyText_M_long>
						{/* </p> */}
					</>
				}
				buttonTextL="OK"
				buttonActionL={() => setOpenComposeDisable(false)}
			/>
			{/* 합성 재료 선택 */}
			{isOpenUPGD && (
				<UPGD001002
					isOpen={isOpenUPGD}
					close={() => setOpenUPGD(false)}
					UPGDTarget={UPGDTarget}
					setOpenComPopup={setOpenComPopup}
				/>
			)}
			{/* 합성 보물 확인 */}
			<Modal
				isOpen={isOpenComPopup}
				contents={
					<>
						<h2>
							<S.headline_4>Pisah harta karun</S.headline_4>
						</h2>
						<ComConfirmArea>
							<ComConfirmImage>
								<img />
								<S.flexRowCenter>
									<S.mainTitle_XS>Satu</S.mainTitle_XS>
								</S.flexRowCenter>
							</ComConfirmImage>
							<ComConfirmInfo>
								<S.subhead_4>[CHANEL] 22 Mini</S.subhead_4>
								<S.bodyText_XS
									color={S.color.gray700}
									style={{ margin: "0.8rem 0 0.3rem" }}
								>
									Harta karun terpilih
								</S.bodyText_XS>
								<p>
									<S.bodyText_XS
										color={S.color.primaryVariant}
									>
										0
									</S.bodyText_XS>
									<S.bodyText_XS color={S.color.gray700}>
										{" "}
										(buah)
									</S.bodyText_XS>
								</p>
							</ComConfirmInfo>
						</ComConfirmArea>
						<div style={{ textAlign: "center" }}>
							<S.bodyText_M_long color={S.color.error}>
								Harta karun yang telah dipisah tidak bisa{"\n"}
								dipulihkan kembali.{"\n"}Apakah anda akan
								melakukannya?
							</S.bodyText_M_long>
						</div>
					</>
				}
				buttonTextL={"Batal"}
				buttonActionL={() => setOpenComPopup(false)}
				buttonTextR={"Pemisahan"}
				buttonActionR={() => composition()}
			/>
		</>
	);
};
