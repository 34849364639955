// 공통 스타일
import styled, { css } from 'styled-components';

import check from '../Images/check.png';
import newsBg from '../Images/news_bg.png';
import xWhite from '../Images/x_white_bold.png';

// 색상
const color = {
	primary: '#FF9800',
	primaryVariant: '#FF6B00',
	secondary: '#22408F',
	secondaryVariant: '#19306A',
	point01: '#0066FF',
	point02: '#00C000',
	point03: '#FEE852',
	error: '#FF1900',
	orange50: '#FFF3E0',
	orange100: '#FFE0B2',
	orange800: '#EF6C00',
	orange900: '#E65100',
	gray100: '#F5F5F5',
	gray200: '#EEEEEE',
	gray300: '#E0E0E0',
	gray400: '#BDBDBD',
	gray500: '#9E9E9E',
	gray600: '#757575',
	gray700: '#616161',
	gray800: '#424242',
	gray900: '#212121',
	dimmed40: 'rgba(0, 0, 0, 0.4)',
	dimmed70: 'rgba(0, 0, 0, 0.7)',
	dimmed80: 'rgba(0, 0, 0, 0.8)',
};
// 텍스트 - 말줄임표
const ellipsisText = css`
	word-break: break-all;
	white-space: normal;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
`;
// 텍스트 - 타이틀
const mainTitle = styled.span<{ color?: string; fontSize?: string }>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => props.fontSize && props.fontSize};
	font-family: 'concertOneRegular';
	padding-bottom: 0.2rem;
	/* text-align: center; */
`;
const mainTitle_XL = styled(mainTitle)`
	font-size: 2.6rem;
	line-height: 2.83rem;
`;
const mainTitle_L = styled(mainTitle)`
	font-size: 2.2rem;
	line-height: 2.6rem;
`;
const mainTitle_M = styled(mainTitle)`
	font-size: 1.8rem;
	line-height: 2.2rem;
`;
const mainTitle_S = styled(mainTitle)`
	font-size: 1.6rem;
	line-height: 2rem;
`;
const mainTitle_XS = styled(mainTitle)`
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	padding-bottom: 0.3rem;
`;
// 텍스트 - 헤드
const headline = styled.span<{
	color?: string;
	fontSize?: string;
	$ellipsis?: number;
}>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.8rem')};
	font-family: 'RobotoBold';
	${(props) => props.$ellipsis && ellipsisText};
	-webkit-line-clamp: ${(props) => props.$ellipsis && props.$ellipsis};
`;
const headline_1 = styled(headline)`
	font-size: 3rem;
	line-height: 4rem;
`;
const headline_2 = styled(headline)`
	font-size: 2.4rem;
	line-height: 3.4rem;
`;
const headline_3 = styled(headline)`
	font-size: 2rem;
	line-height: 3rem;
`;
const headline_4 = styled(headline)`
	font-size: 1.8rem;
	line-height: 2.6rem;
`;
const headline_5 = styled(headline)`
	font-size: 1.2rem;
	line-height: 1.8rem;
`;
// 텍스트 - 서브헤드
const subheadText = styled.span<{
	color?: string;
	fontSize?: string;
	$ellipsis?: number;
}>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => props.fontSize && props.fontSize} !important;
	font-family: 'RobotoMedium';
	${(props) => props.$ellipsis && ellipsisText};
	-webkit-line-clamp: ${(props) => props.$ellipsis && props.$ellipsis};
`;
const subhead_1 = styled(subheadText)`
	font-size: 1.8rem;
	line-height: 2.2rem;
	font-family: 'RobotoMedium';
`;
const subhead_2 = styled(subheadText)`
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
const subhead_3 = styled(subheadText)`
	font-size: 1.4rem;
	line-height: 2.2rem;
`;
const subhead_4 = styled(subheadText)`
	font-size: 1.2rem;
	line-height: 1.8rem;
`;
// 텍스트 - 바디
const bodyText = styled.span<{
	color?: string;
	fontSize?: string;
	$ellipsis?: number;
}>`
	color: ${(props) => (props.color ? props.color : 'black')} !important;
	font-size: ${(props) => props.fontSize && props.fontSize} !important;
	font-family: 'RobotoRegular';
	${(props) => props.$ellipsis && ellipsisText};
	-webkit-line-clamp: ${(props) => props.$ellipsis && props.$ellipsis};
`;
const bodyText_L = styled(bodyText)`
	font-size: 1.6rem;
	line-height: 2rem;
`;
const bodyText_L_long = styled(bodyText)`
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
const bodyText_M = styled(bodyText)`
	font-size: 1.4rem;
	line-height: 1.8rem;
`;
const bodyText_M_long = styled(bodyText)`
	font-size: 1.4rem;
	line-height: 2.2rem;
`;
const bodyText_S = styled(bodyText)`
	font-size: 1.2rem;
	line-height: 1.8rem;
`;
const bodyText_XS = styled(bodyText)`
	font-size: 1rem;
	line-height: 1.4rem;
`;
// 텍스트 - 버튼
const buttonText = styled.span<{
	color?: string;
	fontSize?: string;
	$ellipsis?: number;
}>`
	color: ${(props) => (props.color ? props.color : 'black')};
	font-size: ${(props) => props.fontSize && props.fontSize} !important;
	font-family: 'RobotoMedium';
	${(props) => props.$ellipsis && ellipsisText};
	-webkit-line-clamp: ${(props) => props.$ellipsis && props.$ellipsis};
`;
const button_L = styled(buttonText)`
	font-size: 1.8rem;
	line-height: 2.11rem;
`;
const button_M = styled(buttonText)`
	font-size: 1.6rem;
	line-height: 1.875rem;
`;
const button_S = styled(buttonText)`
	font-size: 1.4rem;
	line-height: 1.64rem;
`;
const button_XS = styled(buttonText)`
	font-size: 1.2rem;
	line-height: 1.4rem;
`;
// 이미지 - 높이 100
const heightImg = styled.img`
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
// flex - row center
const flexRowStart = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const flexRowCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const flexRowBetween = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const flexRowEnd = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
const flexColEnd = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
`;
// 컨트롤러
const controlsWrap = styled.div`
	display: flex;
	input {
		margin-top: 0.1rem;
	}
`;
// 체크박스
const checkbox = styled.input<{ size?: string }>`
	appearance: none;
	width: ${(props) => (props.size === 'L' ? '2rem' : '1.6rem')};
	height: ${(props) => (props.size === 'L' ? '2rem' : '1.6rem')};
	border-radius: ${(props) => (props.size === 'L' ? '0.6rem' : '0.5rem')};
	border: 1px solid #bdbdbd;
	position: relative;
	margin-right: ${(props) => (props.size === 'L' ? '1rem' : '0.6rem')};
	flex-shrink: 0;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-position: center;
		background-size: 70%;
		background-repeat: no-repeat;
	}
	&:checked {
		background-color: ${color.primary};
		border: 0;
		&::after {
			background-image: url(${check});
		}
	}
	&:disabled {
		background-color: ${color.gray100};
	}
`;
// 라디오
const radio = styled.input`
	appearance: none;
	width: 1.6rem;
	height: 1.6rem;
	border: 1px solid ${color.gray400};
	border-radius: 0.8rem;
	margin-right: 1rem;
	position: relative;
	flex-shrink: 0;

	&::after {
		content: '';
		width: 1rem;
		height: 1rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}
	&:checked {
		&::after {
			background-color: ${color.primary};
		}
	}
	&:disabled {
		border: 1px solid ${color.gray300};
		background-color: ${color.gray100};
		&::after {
			background-color: transparent;
		}
	}
`;
// 버튼
const button = styled.button<{ $variant?: boolean }>`
	width: 100%;
	font-family: 'RobotoMedium';
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	font-size: 1.8rem;
	flex-shrink: 0;
	color: white;
	span {
		color: white;
	}
	&:disabled {
		color: ${(props) => props.$variant && color.gray600};
		background-color: ${(props) => (props.$variant ? color.gray600 : color.gray400)};
		box-shadow: 0 0.4rem 0.1rem ${color.gray500};
	}
`;
// 버튼 - 감싸는 영역
const buttonWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	button {
		width: 48.5%;
		flex-grow: 1;
		flex-shrink: 1;
		margin-right: 1rem;
	}
	button:last-child {
		margin-right: 0;
	}
	&.column {
		flex-wrap: wrap;
		button {
			width: 100%;
			margin-right: 0;
		}
		button:last-child {
			margin-top: 0.4rem;
		}
	}
`;
// 버튼 - 페이지
const buttonPrimary = styled(button)`
	background-color: ${(props) => (props.$variant ? color.primaryVariant : color.primary)};
	box-shadow: ${(props) => (props.$variant ? '0 0.4rem 0.1rem #C75714' : '0 0.4rem 0.1rem #f57809')};
`;
// 버튼 - 팝업
const buttonPrimary_S = styled(buttonPrimary)`
	height: 4rem;
	margin-top: 2rem;
	margin-bottom: 0.8rem;
`;
// 버튼 - 선형
const buttonPrimary_B = styled.button`
	width: 100%;
	height: 4rem;
	border-radius: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${color.primary};
	background-color: white;
	span {
		color: ${color.primary};
	}
`;
// 뱃지
const badge = styled.div`
	width: fit-content;
	background-color: ${color.orange50};
	padding: 0.1rem 0.6rem;
	border-radius: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		color: ${color.primary};
	}
	&.disabled {
		background-color: ${color.gray200};
		color: ${color.gray600};
	}
`;
const borderBadge = styled.div<{ $large?: boolean }>`
	width: fit-content;
	border: 1px solid ${color.primary};
	color: ${color.primary};
	padding: ${(props) => (props.$large ? '0.7rem 1.4rem' : '0.1rem 0.6rem')};
	border-radius: ${(props) => (props.$large ? '3rem' : '0.4rem')};
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		color: ${color.primary} !important;
	}
`;
// 인풋
const inputField = styled.div<{ $disabled?: boolean }>`
	width: 100%;
	display: flex;
	.inputWrap {
		flex-grow: 1;
		border: 1px solid ${color.gray300};
		border-radius: 1rem;
		background-color: white;
		padding: 1.2rem 1.4rem;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: center;
		background-color: ${(props) => (props.$disabled ? color.gray100 : 'none')};
	}
	svg {
		width: 1.8rem;
		height: 1.8rem;
		transform: rotate(90deg);
	}
	input {
		line-height: 1.8rem;
		flex-shrink: 1;
		/* &.lack {
			border: 1px solid ${color.error};
			color: ${color.error};
		} */
	}
	textarea {
		width: 100%;
		flex-shrink: 0;
	}
	input,
	textarea {
		font-size: 1.2rem;
		flex-grow: 1;
	}
	input[type='button'] {
		border: 1px solid red;
	}
	button {
		background-color: ${color.primary};
		font-size: 1.2rem;
		padding: 0 1.4rem;
		border-radius: 1rem;
		margin-left: 0.8rem;
		span {
			color: white;
			text-align: center;
		}
		&:disabled {
			background-color: ${color.gray400};
		}
	}
`;
const inputCount = styled.p`
	text-align: right;
	&.inTextArea {
		margin-top: 0.8rem;
	}
	span {
		color: ${color.gray600} !important;
	}
`;
// 업로드 파일 선택
const uploadFile = styled.div`
	width: 100%;
	flex-shrink: 1;
	display: flex;
	overflow: hidden;
	input[type='file'] {
		display: none;
	}
	#imageContainer {
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		overflow-x: scroll;
	}
	.imageArea {
		width: 8rem;
		height: 8rem;
		flex-shrink: 0;
		background-color: ${color.gray200};
		border-radius: 1rem;
		position: relative;
		overflow: hidden;
		margin-left: 1rem;
		img {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		button {
			width: 2.2rem;
			height: 2.2rem;
			background-color: ${color.gray900};
			position: absolute;
			top: 0.8rem;
			right: 0.8rem;
			border-radius: 50%;
			background-image: url(${xWhite});
			background-position: center;
			background-size: 1.2rem;
			background-repeat: no-repeat;
		}
		/* svg {
			width: 1rem;
			height: 1rem;
		} */
	}
	#addFileButton {
		width: 8rem;
		height: 8rem;
		flex-shrink: 0;
		background-color: ${color.gray800};
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		svg {
			width: 3rem;
			height: 3rem;
			margin-bottom: 0.5rem;
		}
	}
`;
// 검색
const searchArea = styled.div`
	display: flex;
	padding: 2rem 1.4rem 1rem;

	.searchBox {
		display: flex;
		height: 3.4rem;
		border: 1px solid ${color.gray300};
		flex-grow: 1;
		flex-shrink: 1;
		border-radius: 0.6rem;
	}
	input {
		width: 0;
		font-size: 1.2rem;
		flex-grow: 1;
		flex-shrink: 1;
		margin-left: 1rem;
	}
`;
const searchButton = styled.button`
	padding: 0.7rem 1.4rem;
	img {
		width: 1.8rem;
		height: 100%;
	}
`;
const searchFilter = styled.button`
	height: 3.4rem;
	padding: 0.8rem;
	border: 1px solid ${color.gray300};
	border-radius: 0.6rem;
	margin-left: 0.6rem;
	position: relative;
	img {
		height: 100%;
	}
	p {
		position: absolute;
		left: -0.2rem;
		bottom: -0.2rem;
		width: 1.4rem;
		height: 1.4rem;
		background-color: ${color.primaryVariant};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
// 설명
const expandedExplain = styled.div<{ $expand?: boolean }>`
	/* height: 4rem; */
	margin: 2rem 0 0;
	/* position: relative; */
	& > div {
		width: 100%;
		color: ${color.gray800};
		border: 1px solid ${color.gray300};
		background-color: white;
		border-radius: 0.6rem;
		padding: 1.2rem 1.4rem;
		/* position: absolute; */
		bottom: 0;
	}
	header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	svg {
		height: 1.8rem;
		transform: ${(props) => (props.$expand ? 'rotate(90deg)' : 'rotate(-90deg)')};
		transform: rotate(90deg);
	}
	ul {
		margin-top: 0.8rem;
		display: ${(props) => (props.$expand ? 'block' : 'none')};
	}
`;
// 슬라이드 메뉴
const slideMenuWrap = styled.div`
	height: 2.8rem;
	overflow: hidden;
	position: relative;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 1.4rem;
		height: 100%;
		background: linear-gradient(to right, white, transparent);
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1.4rem;
		height: 100%;
		background: linear-gradient(to right, transparent, white);
	}
`;
const slideMenu = styled.ul`
	width: 100%;
	height: 100%;
	display: flex;
	overflow-x: scroll;
	padding: 0 1.4rem;
	li:last-child {
		margin-right: 0;
	}
`;
const slideMenuItem = styled.li<{ $active?: boolean }>`
	height: 100%;
	margin-right: 0.4rem;
	button {
		height: 100%;
		padding: 0 2rem;
		background-color: ${(props) => (!props.$active ? color.gray100 : color.primary)};
		border: ${(props) => !props.$active && '1px solid' + color.gray200};
		border-radius: 2rem;
	}
	span {
		white-space: nowrap;
		color: ${(props) => (!props.$active ? color.gray600 : 'white')} !important;
	}
`;
// 회원 프로필 영역
const setProfileArea = styled(flexRowCenter)`
	position: relative;
	margin-bottom: 1.6rem;
	& > p {
		position: absolute;
		bottom: -1.2rem;
		left: 7rem;
		color: ${color.error};
	}
`;
const userProfile = styled.div<{ $url: string }>`
	width: 6rem;
	height: 6rem;
	position: relative;
	flex-shrink: 0;
	margin-right: 1rem;
	/* background-image: url(${(props) => props.$url});
	background-position: center;
	background-size: cover;
	overflow: hidden; */
	input {
		display: none;
	}
	label {
		width: 100%;
		height: 100%;
		display: block;
		overflow: hidden;
		position: relative;
		border: 1px solid ${color.gray300};
		border-radius: 3rem;
		background-image: url(${(props) => props.$url});
		background-size: cover;
		background-color: white;
		background-position: center center;
	}
	svg {
		position: absolute;
		top: 0;
		right: 0;
		width: 1.8rem;
		height: 1.8rem;
		background-color: ${color.gray500};
		border-radius: 0.9rem;
	}
`;
const userImageArea = styled.div`
	position: relative;
`;
const userImage = styled.div<{ $large?: boolean }>`
	width: ${(props) => (props.$large ? '3.5rem' : '2.4rem')};
	height: ${(props) => (props.$large ? '3.5rem' : '2.4rem')};
	border: 1px solid ${color.gray300};
	border-radius: 2rem;
	margin-right: 0.8rem;
	position: relative;
	overflow: hidden;
	background-color: white;
	img {
		width: 100%;
	}
`;
const userTier = styled.div<{ $url?: string; $large?: boolean }>`
	width: ${(props) => (props.$large ? '1.8rem' : '1.4rem')};
	height: ${(props) => (props.$large ? '1.8rem' : '1.4rem')};
	position: absolute;
	bottom: 0;
	right: 0.3rem;
	background-image: url(${(props) => props.$url});
	background-size: contain;
`;
const borderBox = styled.div`
	border: 1px solid ${color.gray300};
	border-radius: 1rem;
	overflow: hidden;
	position: relative;
	& > img {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const treasureBox = styled(borderBox)`
	padding: 2rem;
	margin-bottom: 2rem;
	div {
		height: 8rem;
		margin-bottom: 0.8rem;
		position: relative;
		text-align: center;
		img {
			height: 100%;
		}
	}
	p {
		/* margin: 0 1.4rem 1.4rem; */
		span {
			text-align: center;
		}
	}
`;
const treasureInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	& > span {
		text-align: center;
		margin-bottom: 1rem;
	}
	.frame {
		height: 6.8rem;
		margin-top: 0.4rem;
	}
	.brand {
		height: 1.5rem;
		margin-bottom: 2rem;
	}
	.detail {
		width: 100%;
		/* height: 30rem; */
		border: 1px solid ${color.gray300};
		border-radius: 1rem;
		margin-bottom: 3rem;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.detailInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		& > p {
			background-color: #22408f;
			padding: 0.5rem 1.4rem;
			border-radius: 0.3rem;
		}
		& > div {
			margin-bottom: 2.4rem;
		}
		h1 {
			font-family: 'RobotoBold';
			margin: 2.4rem 0 0.8rem;
			font-size: 1.6rem;
		}
		p {
			font-family: 'RobotoRegular';
			text-align: center;
			font-size: 1.4rem;
			line-height: 2.2rem;
			color: ${color.gray800};
		}
	}
`;
const bar = styled.div<{ $size?: string; $color?: string }>`
	height: ${(props) => (props.$size ? props.$size : '2rem')};
	margin-bottom: ${(props) => (props.$size ? props.$size : '2rem')};
	border-bottom: 1px solid ${color.gray300};
	border-bottom-color: ${(props) => (props.$color ? props.$color : color.gray300)};
`;
// 페이지
const page = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const pageTitle = styled.div`
	width: 100%;
	height: 4.4rem;
	background-color: ${color.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-shrink: 0;

	& > span {
		text-shadow: 0 0.2rem 0.2rem ${color.primaryVariant};
	}
	button.close {
		width: 4.4rem;
		height: 4.4rem;
		position: absolute;
		padding: 1rem;
		top: 0;
		right: 0;
		img {
			width: 100%;
		}
	}
	button.goBack {
		height: 100%;
		position: absolute;
		padding: 0.8rem 1.4rem;
		top: 0;
		left: 0;
		img {
			height: 100%;
		}
	}
	button.badge {
		height: 100%;
		position: absolute;
		padding: 0.8rem 1.4rem;
		top: 0;
		right: 0;
		span {
			color: white;
			border: 1px solid white;
			border-radius: 2rem;
			padding: 0.4rem 1rem;
		}
	}
	button.information {
		height: 100%;
		position: absolute;
		padding: 0 1.4rem;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const pageContainer = styled.div`
	section {
		padding: 3rem 1.4rem;
		border-bottom: 0.5rem solid ${color.gray300};
	}
	section:first-child {
		padding-top: 2rem;
	}
	section:last-child {
		border-bottom: 0;
	}
	section.low {
		padding: 1.5rem 1.4rem;
	}

	h1 {
		margin: 2rem 0 1.2rem;
	}
	h1:first-child {
		margin-top: 0;
	}
	& > h2,
	section > h2 {
		margin: 1.6rem 0 0.6rem;
	}
	& > h2:first-child,
	section > h2:first-child {
		margin-top: 0;
	}
	h3 {
		margin: 1.6rem 0 0.6rem;
	}
	& > p {
		margin-bottom: 1.2rem;
	}
	.inputArea {
		& > div {
			margin-bottom: 1.2rem;
		}
	}
`;
const wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;
// 팝업
const toastContainer = styled.div<{ $isOpen: boolean }>`
	width: calc(100% - 2.8rem);
	position: absolute;
	bottom: 0.8rem;
	left: 1.4rem;
	padding: 2rem;
	border-radius: 1.4rem;
	background-color: ${color.dimmed80};
	color: white;
	display: flex;
	flex-direction: column;
	opacity: ${(props) => (props.$isOpen ? 1 : 0)};
	visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
	transition:
		visibility 150ms linear,
		opacity 100ms linear;
	div {
		margin-top: 0.8rem;
		margin-bottom: 0.6rem;
	}
`;
const bottomPopupContainer = styled.div<{ $isOpen: boolean }>`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	background-color: ${color.dimmed70};
	opacity: ${(props) => (props.$isOpen ? 1 : 0)};
	visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
	transition:
		visibility 150ms linear,
		opacity 100ms linear;
	overflow: hidden;
`;
const bottomPopupWrapper = styled.div`
	width: 100%;
	border-radius: 3rem 3rem 0 0;
	background-color: white;
	box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
	position: relative;
	overflow: hidden;
`;
const popupContainer = styled.div<{ $isOpen: boolean }>`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${color.dimmed70};
	opacity: ${(props) => (props.$isOpen ? 1 : 0)};
	visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
	transition:
		visibility 150ms linear,
		opacity 100ms linear;
`;
const popupWrapper = styled.div<{ $bottom?: boolean }>`
	width: ${(props) => (props.$bottom ? '100%' : 'calc(100% - 2.8rem)')};
	border-radius: ${(props) => (props.$bottom ? '3rem 3rem 0 0' : '1rem')};
	max-height: calc(100% - 2.8rem);
	padding: 2rem;
	background-color: white;
	box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
	position: relative;
	overflow-y: scroll;

	h1 {
		margin-right: 2rem;
		margin-bottom: 1.2rem;
	}
	& > h2 {
		margin-right: 2rem;
		margin-bottom: 1.2rem;
	}
	h3 {
		margin: 1.6rem 0 1.4rem;
	}
	& > p {
		margin-top: 0.8rem;
		display: flex;
	}
	& > p:first-child {
		margin-top: 0;
	}
`;
const popupIcon = styled.div`
	width: 14rem;
	height: 11.4rem;
	position: absolute;
	top: -6.7rem;
	left: 2rem;
	img {
		position: absolute;
	}
	.captain {
		height: 7.8rem;
		bottom: 0;
		left: 0;
	}
	.bubble {
		height: 8.8rem;
		top: 0;
		right: 0;
	}
	.icon {
		height: 5rem;
		top: 4rem;
		right: 0.1rem;
		transform: translate(-50%, -50%);
	}
`;
const popupXIcon = styled.button`
	width: 5.8rem;
	height: 5.8rem;
	position: absolute;
	padding: 2rem;
	top: 0.4rem;
	right: 0rem;
	svg {
		width: 100%;
		height: 100%;
		fill: ${color.gray700};
	}
`;
const popupBox = styled.div`
	width: 100%;
	background-color: ${color.gray100};
	border-radius: 1rem;
	padding: 1.6rem;
	margin: 1.6rem 0 0;
`;
const popupBorderBox = styled(borderBox)`
	width: 11rem;
	height: 10rem;
	margin-right: 1.6rem;
`;
// 리스트
// 한 줄 1 아이템
// 내보물함, 거래현황, 뉴스룸
const presentList = styled.div`
	width: 100%;
	padding: 0 1.4rem 5rem;
	display: flex;
	flex-direction: column;
`;
const listItem = styled.div<{ $emphasize?: boolean }>`
	/* width: 100%; */
	max-width: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	border-bottom: 1px solid ${color.gray300};
	&:last-child {
		border-bottom: 0;
	}
	background-image: ${(props) => (props.$emphasize ? `url(${newsBg})` : 'unset')};
	background-size: 102%;
	background-repeat: no-repeat;
	background-position: center 15px;
	background-color: ${(props) => (props.$emphasize ? '#F84242' : 'white')};
	padding: ${(props) => (props.$emphasize ? '2rem' : '2rem 0')};
	position: relative;
	h1 {
		height: 4.5rem;
		display: ${(props) => (props.$emphasize ? 'block' : 'none')};
		margin-bottom: 1rem;
		text-align: center;
		img {
			height: 100%;
		}
	}
	.itemWrap {
		height: ${(props) => (props.$emphasize ? '13rem' : '11rem')};
		display: flex;
		position: relative;
	}
	.itemImage {
		background-color: white;
		width: ${(props) => (props.$emphasize ? '15rem' : '12rem')};
		flex-shrink: 0;
		border: 1px solid ${color.gray300};
		border-radius: 1rem;
		margin-right: ${(props) => (props.$emphasize ? '1.6rem' : '1.4rem')};
		position: relative;
		overflow: hidden;
	}
	.itemInfo {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		span {
			margin-bottom: ${(props) => (props.$emphasize ? '0.2rem' : '0.6rem')};
		}
		span:last-child {
			margin-bottom: 0;
		}
	}
	.userInfoArea {
		width: 100%;
		position: relative;
		height: ${(props) => (props.$emphasize ? '3.5rem' : 'unset')};
		& > div:first-child {
			position: ${(props) => (props.$emphasize ? 'absolute' : 'relative')};
			left: 0;
			top: 0;
		}
		.nick {
			padding: 0.5rem 1rem 0.4rem 2.5rem;
			background-color: rgba(255, 255, 255, 0.2);
			border-radius: 0.6rem;
			margin-left: 2rem;
		}
	}
`;
const handlingButtonArea = styled.div`
	/* display: flex;
	justify-content: space-between; */
	position: absolute;
	right: 0;
`;
const handlingButton = styled.button<{ $url: string }>`
	width: 1.8rem;
	height: 1.8rem;
	border-radius: 50%;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(${(props) => props.$url});
	margin-left: 0.6rem;
`;
// 한 줄 2 아이템
// 보물, 거래소
const treasureList = styled.div`
	width: 100%;
	padding: 0 1.4rem 5rem;
	display: flex;
	flex-wrap: wrap;
`;
const treasureListItem = styled.button`
	width: calc(50% - 0.5rem);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0.6rem;
	margin-bottom: 1.4rem;
	.itemImage {
		width: 100%;
		height: 13.5rem;
		border: 1px solid ${color.gray300};
		border-radius: 1rem;
		/* margin: 1rem 0; */
		position: relative;
		overflow: hidden;
	}
	.treasureChest {
		position: absolute;
		right: 0;
		bottom: 0;
		display: flex;
		padding: 0.5rem;
		img {
			height: 1.8rem;
			margin-left: 0.4rem;
		}
	}
	.name {
		flex-grow: 1;
		margin-top: 0.6rem;
	}
`;
const textList = styled.ul<{
	$gray?: boolean;
	$white?: boolean;
	$small?: boolean;
}>`
	& > li {
		padding-left: 1.4rem;
		margin-bottom: ${(props) => (props.$small ? '0.6rem' : '0.8rem')};
		position: relative;
	}
	& > li.subTitle {
		padding-left: 0;
		margin: 1.2rem 0 0.8rem;
		position: unset;
	}
	& > li:last-child {
		margin-bottom: 0;
	}
	& > li::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0.9rem;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: 0.2rem;
		background-color: ${color.gray400};
	}
	& > li span {
		color: ${(props) => props.$gray && color.gray800} !important;
		color: ${(props) => props.$white && 'white'} !important;
	}
`;
const inputList = styled.ul`
	display: flex;
	flex-direction: column;
	li {
		margin-bottom: 2rem;
	}
	li:last-child {
		/* margin-bottom: 0.8rem; */
		margin-bottom: 0;
	}
`;
const helpItem = styled(flexRowBetween)`
	padding: 2rem 0;
	margin: 0 1.4rem;
	border-top: 1px solid ${color.gray300};
	&:first-child {
		margin-top: 1rem;
	}
	h1 {
		margin-bottom: 0.6rem;
	}
	p {
		margin-top: 0.8rem;
	}
	svg {
		width: 1.8rem;
		margin-left: 1.4rem;
	}
	& > div {
		width: 100%;
	}
`;

export {
	color,
	mainTitle,
	mainTitle_XL,
	mainTitle_L,
	mainTitle_M,
	mainTitle_S,
	mainTitle_XS,
	headline,
	headline_1,
	headline_2,
	headline_3,
	headline_4,
	headline_5,
	subhead_1,
	subhead_2,
	subhead_3,
	subhead_4,
	bodyText,
	bodyText_L,
	bodyText_L_long,
	bodyText_M,
	bodyText_M_long,
	bodyText_S,
	bodyText_XS,
	button_L,
	button_M,
	button_S,
	button_XS,
	heightImg,
	flexRowStart,
	flexRowCenter,
	flexRowBetween,
	flexRowEnd,
	flexColEnd,
	controlsWrap,
	checkbox,
	radio,
	button,
	buttonWrap,
	buttonPrimary,
	buttonPrimary_S,
	buttonPrimary_B,
	badge,
	borderBadge,
	inputField,
	inputCount,
	uploadFile,
	searchArea,
	searchButton,
	searchFilter,
	expandedExplain,
	slideMenuWrap,
	slideMenu,
	slideMenuItem,
	setProfileArea,
	userProfile,
	userImageArea,
	userImage,
	userTier,
	borderBox,
	treasureBox,
	treasureInfo,
	bar,
	page,
	pageContainer,
	wrapper,
	toastContainer,
	pageTitle,
	bottomPopupContainer,
	bottomPopupWrapper,
	popupContainer,
	popupWrapper,
	popupIcon,
	popupXIcon,
	popupBox,
	popupBorderBox,
	presentList,
	listItem,
	handlingButtonArea,
	handlingButton,
	treasureList,
	treasureListItem,
	textList,
	inputList,
	helpItem,
};

