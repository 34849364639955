// 회원가입
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";
import Api from "../libs/api";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import Authentication from "../Components/Authentication";

import google from "../Images/login_google.png";
import whatsapp from "../Images/login_whatsapp.png";
import apple from "../Images/login_apple.png";

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.8rem 1.4rem 1.8rem;
	.termsButton {
		text-decoration: underline;
	}
`;
const PopupLogo = styled(S.flexRowCenter)`
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	background-color: white;
	margin-right: 0.6rem;
	img {
		width: 75%;
	}
`;

export default () => {
	const navigate = useNavigate();
	const [userState, setUserState] = useRecoilState(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	const [isOpenAlert, setOpenAlert] = useState<boolean>(false);
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	// 휴대폰 인증
	const [name, setName] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [overlapData, setOverlapData] = useState<{
		loginType: "";
		email: "";
	}>({
		loginType: "", // kakao 1, naver 2, apple 4, google 5
		email: "",
	});
	const [complete, setComplete] = useState<boolean>(false);

	// 가입 하기
	const authAgree = async () => {
		console.log(name);
		await Api.patch("/auth/authAgree", {
			useAgreeYn: 1,
			financialAgreeYn: 1,
			mailSendYn: 1,
			recipientNo: Util.onlyNum(phoneNumber),
			name: name,
		}).then((res) => {
			if (res.data !== undefined) {
				console.log(res.data);
				if (res.data.statusCode == 200) {
					navigate("/", { replace: true });
				}
			} else {
				alert("fail");
			}
		});
	};

	return (
		<Page
			title="Keanggotaan"
			contents={
				<S.pageContainer style={{ height: "100%" }}>
					<Container>
						{/* 번호인증 */}
						<div>
							<Authentication
								type={0}
								name={name}
								setName={setName}
								phoneNumber={phoneNumber}
								setPhoneNumber={setPhoneNumber}
								setOverlapData={setOverlapData}
								setOpenAlert={setOpenAlert}
								complete={complete}
								setComplete={setComplete}
							/>
						</div>
						<div>
							<S.bodyText_S color={S.color.gray700}>
								Menyetujui Syarat dan{" "}
								<span
									className="termsButton"
									onClick={() => setOpenPopup(true)}
								>
									Ketentuan Layanan
								</span>{" "}
								serta{" "}
								<span
									className="termsButton"
									onClick={() => setOpenPopup(true)}
								>
									Kebijakan Privasi.
								</span>
							</S.bodyText_S>
						</div>
					</Container>
					{/* 약관 */}
					<Modal
						isOpen={isOpenPopup}
						contents={
							<>
								<h2>
									<S.headline_4>
										Menyetujui syarat dan{`\n`}ketentuan
									</S.headline_4>
								</h2>
								<p>
									<S.bodyText_M_long color={S.color.gray800}>
										Menyetujui syarat dan ketentuan
									</S.bodyText_M_long>
								</p>
							</>
						}
						close={() => setOpenPopup(false)}
					/>
					{/* 중복 번호 제한 */}
					<Modal
						isOpen={isOpenAlert}
						close={() => setOpenAlert(false)}
						contents={
							<>
								<h2>
									<S.headline_4>
										Nomor telepon telah terdaftar.
									</S.headline_4>
								</h2>
								<p>
									<S.bodyText_M_long>
										Apakah ingin mencoba untuk log-in?
									</S.bodyText_M_long>
								</p>
								<S.popupBox style={{ margin: 0 }}>
									<S.flexRowCenter>
										<PopupLogo>
											<img src={google} />
										</PopupLogo>
										<S.bodyText_M_long
											color={S.color.gray900}
										>
											{overlapData.email}
										</S.bodyText_M_long>
									</S.flexRowCenter>
								</S.popupBox>
							</>
						}
						buttonTextL={"Log-in"}
						buttonActionL={() => setOpenAlert(false)}
					/>
				</S.pageContainer>
			}
			button="Daftar"
			disabled={!complete}
			buttonAction={() => authAgree()}
		/>
	);
};
