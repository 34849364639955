import { useEffect } from "react";
import React, { PropsWithChildren } from "react";
import Util from "../Store/Util";

import * as S from "../Styles/Style";

interface DefaultType {
	isOpen: boolean;
	close: () => void;
	contents: { agree: boolean; type: number };
}

export default ({
	isOpen,
	close,
	contents,
}: PropsWithChildren<DefaultType>) => {
	const closeEvent = () => {
		const timer = setTimeout(() => {
			close();
		}, 2000);
		return () => clearTimeout(timer);
	};

	useEffect(() => {
		if (isOpen) {
			closeEvent();
			return () => close();
		}
	}, [isOpen]);

	return (
		<S.toastContainer $isOpen={isOpen}>
			{/* 내용 */}
			<p>
				<S.subhead_3>
					Pengiriman notifikasi{" "}
					{contents.type == 0 ? "event " : "iklan tengah malam "}
					{contents.agree ? "disetujui" : "dimatikan"}
					{/* 승인 / 거부 */}
				</S.subhead_3>
			</p>
			<div>
				<p>
					<S.subhead_4>
						Pengirim : GOPANG{"\n"}Tanggal : {Util.getNow()}
					</S.subhead_4>
				</p>
			</div>
			<p>
				<S.bodyText_S color={S.color.gray400}>
					Pengaturan notifikasi: Informasi Profil {">"} Pengaturan
					Notifikasi {">"}{" "}
					{contents.type == 0 ? "Event " : "Iklan Tengah Malam"}
					{/* 혜택 / 야간알림 */}
				</S.bodyText_S>
			</p>
		</S.toastContainer>
	);
};
