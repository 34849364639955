// 합성 재료 선택
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom, UserAtom, NewsPrice } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import Filter from "../Components/Filter";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";
import Modal from "../Components/Modal";

import { ReactComponent as Check } from "../Images/check.svg";
import { ReactComponent as Chevron } from "../Images/chevron.svg";
import xWhite from "../Images/x_white.png";
import { config } from "../config/config";

const Container = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
`;
const TargetInfoArea = styled(S.flexRowCenter)`
	padding: 1.4rem 2rem 2rem 1.4rem;
	border-bottom: 1px solid ${S.color.gray300};
	margin-bottom: 2rem;
	.targetImage {
		flex-shrink: 0;
		width: 12rem;
		height: 10rem;
		overflow: hidden;
		position: relative;
		margin-right: 1.6rem;
		img {
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.targetText {
		height: 10rem;
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& > div {
			height: 1.5rem;
			background-color: ${S.color.gray300};
			border-radius: 0.5rem;
			margin-top: 0.4rem;
			margin-bottom: 0.7rem;
			position: relative;
			overflow: hidden;
			.bar {
				position: absolute;
				height: 100%;
				background-color: ${S.color.primaryVariant};
				top: 0;
				left: 0;
			}
			.percent {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		button {
			width: 100%;
			height: 3rem;
			color: #592d1b;
			background: linear-gradient(#ffdf72, #ffc23f);
			box-shadow: 0 0.2rem 0.1rem #ff6927;
			border-radius: 0.5rem;
			span {
			}
			&:disabled {
				color: #757575;
				background: linear-gradient(#ececec, #c2c2c2);
				box-shadow: 0 0.2rem 0.1rem #888888;
			}
		}
	}
`;
const MaterialArea = styled.div`
	padding: 0 1.4rem;
`;
const SelecArea = styled(S.flexRowBetween)`
	width: fit-content;
	max-width: 12.8rem;
	height: 2.8rem;
	border: 1px solid ${S.color.gray300};
	border-radius: 0.6rem;
	/* margin: 1.4rem; */
	padding: 0 0.2rem 0 1rem;
	span {
		color: ${S.color.gray600};
		/* word-break: break-all;
		white-space: normal;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical; */
	}
	svg {
		height: 1.8rem;
		transform: rotate(90deg);
	}
`;
const TreasureList = styled.div`
	width: 100%;
	padding: 0.2rem 1.4rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1.6rem;
`;
const TreasureListItem = styled.div`
	width: calc((100% / 3) - 0.75rem);
	margin-bottom: 2rem;
`;
const TreasureImageWrap = styled.button<{
	$checked?: boolean;
	$news?: boolean;
}>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 0.6rem;
	border-radius: 1rem;
	overflow: hidden;
	position: relative;
	border: 1px solid ${S.color.gray300};
	border-color: ${(props) => props.$checked && S.color.primaryVariant};
	background-color: ${(props) => props.$checked && props.$news && "#FFF8EE"};
	.itemImage {
		width: 100%;
		height: 11rem;
		position: relative;
		flex-shrink: 0;
	}
	.itemInfo {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 0.5rem 1.2rem 1.2rem;
	}
	.name {
		margin-bottom: 0.6rem;
	}
	.checkCircle {
		display: ${(props) => props.$checked && "flex"};
	}
`;
const Circle = styled(S.flexRowCenter)`
	width: 2.2rem;
	height: 2.2rem;
	background-color: ${S.color.primaryVariant};
	display: none;
	border-radius: 50%;
	position: absolute;
	left: 1.2rem;
	top: 1.2rem;
	svg {
		width: 70%;
		height: 70%;
		fill: white;
		stroke: white;
	}
`;

interface upgradeData {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
}

export default ({ isOpen, close, UPGDTarget, setOpenComPopup }) => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 뉴스룸 등록 기준 금액
	const newsPrice = useRecoilValue(NewsPrice);
	// 팝업
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenNewsConfirm, setOpenNewsConfirm] = useState<boolean>(false);
	const [isOpenCompleteConfirm, setOpenCompleteConfirm] =
		useState<boolean>(false);
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	const [isOpenComposeDisable, setOpenComposeDisable] =
		useState<boolean>(false);
	// 정렬
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const defaultOrderBy = 0;
	const [orderBy, setOrderBy] = useState<number>(defaultOrderBy);
	const orderByRef = useRef<number>(null);
	const sortList = [
		{ id: 0, text: "Dari harga tertinggi" }, // 가격 높은순
		{ id: 1, text: "Dari harga terendah" }, // 가격 낮은순
		{ id: 2, text: "Dari tanggal terbaru" }, // 최신순
		{ id: 3, text: "Dari tanggal terlama" }, // 오래된순
	];
	// 보유 보물 리스트 정보
	const [upgradeDatas, setUpgradeDatas] = useState<upgradeData[]>([]);
	const [upgradeNextPage, setUpgradeNextPage] = useState<boolean>(true);
	const upgradePage = useRef<number>(1);
	const upgradeObserver = useRef<HTMLDivElement>(null);
	// 선택된 보물 정보
	const [checkedItem, setCheckedItem] = useState<upgradeData[]>([]);
	const [checkedPrice, setCheckedPrice] = useState<number>(0);
	const [checkedNewsItem, setCheckedNewsItem] = useState<upgradeData>();
	// 분해 정보 영역
	const anchorRef = useRef<HTMLDivElement>(null);
	// 보유 보물 리셋
	const upgradeReset = () => {
		setCheckedItem([]);
		setCheckedPrice(0);
		setUpgradeDatas([]);
		setUpgradeNextPage(true);
		upgradePage.current = 1;
	};
	// 보유 보물 리스트 불러오기
	const upgradeFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("upgradeFetch", orderByRef.current);
		await Api.get("/present/memberPresentList", {
			stateCode: "00",
			page: upgradePage.current,
			orderBy: orderByRef.current ?? orderBy,
			lockYn: 0,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				setUpgradeDatas((prevDatas) => [...prevDatas, ...data]);
				setUpgradeNextPage(data.length === 10);
				if (data.length) {
					upgradePage.current += 1;
				}
			} else {
				alert("fail");
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (!upgradeObserver.current || !upgradeNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				upgradeFetch();
			}
		});
		io.observe(upgradeObserver.current);

		return () => {
			io.disconnect();
		};
	}, [upgradeNextPage]);
	// 리셋
	useEffect(() => {
		orderByRef.current = orderBy;
		upgradeReset();
		console.log(orderBy);
	}, [orderBy]);
	// 합성 재료 아이템 선택
	const checkItem = async (data: upgradeData, agree?: boolean) => {
		let newData = [...checkedItem];
		let newPrice = checkedPrice;
		// 합성 퍼센트 계산
		// ([{(선택상품가격X적용비율)→백의자리에서 버림}/대상상품가격] X 100)→소수점셋째자리에서 버림
		const calcPrice = Math.ceil((data.displayPrice * 0.8) / 100) * 100;
		const calcPercent =
			Math.floor((calcPrice / UPGDTarget.componentWeight) * 100 * 100) /
			100;

		if (newData.includes(data)) {
			// 선택되어있는 보물인 경우
			for (let i = 0; i < newData.length; i++) {
				if (newData[i] === data) {
					newData.splice(i, 1);
					i--;
				}
			}
			newPrice = Number((newPrice - calcPercent).toFixed(3));
		} else {
			// 선택되어있지 않은 보물인 경우
			// 100% 이상 여부 확인
			if (newPrice >= 100) {
				// 100% 초과 선택 불가
			} else {
				newData.push(data);
				newPrice = Number((newPrice + calcPercent).toFixed(3));
			}
		}
		setCheckedItem(newData);
		setCheckedPrice(newPrice);
	};

	return (
		<S.popupContainer $isOpen={true} style={{ backgroundColor: "white" }}>
			<S.page>
				<S.pageTitle>
					<button className="close" onClick={() => close()}>
						<img src={xWhite} />
					</button>
					<S.mainTitle_M color="white">
						status transaksi
					</S.mainTitle_M>
				</S.pageTitle>
				<Container>
					<TargetInfoArea>
						<div className="targetImage">
							<img src={imgUrl + UPGDTarget.imageUrl} alt="" />
						</div>
						<div className="targetText">
							<S.subhead_4>{UPGDTarget.name}</S.subhead_4>
							<S.bodyText_XS color={S.color.gray600}>
								Harga normal &nbsp;| &nbsp;
								{Util.addComma(UPGDTarget.componentWeight)} IDR
							</S.bodyText_XS>
							<div>
								<div
									className="bar"
									style={{ width: `${checkedPrice}%` }}
								/>
								<S.mainTitle_XS
									color="white"
									className="percent"
								>
									{checkedPrice > 100 ? "100" : checkedPrice}%
								</S.mainTitle_XS>
							</div>
							<button
								disabled={checkedPrice < 100}
								onClick={() => setOpenComPopup(true)}
							>
								<S.mainTitle_S>Gabungkan</S.mainTitle_S>
							</button>
						</div>
					</TargetInfoArea>
					<MaterialArea>
						<h1>
							<S.subhead_2>Harta karun saya</S.subhead_2>
						</h1>
						<p style={{ margin: "0.6rem 0 1.4rem" }}>
							<S.bodyText_XS color={S.color.gray600}>
								Barang yang tidak ingin dicampur harus dikunci
								di 'Harta Karun Saya'.
							</S.bodyText_XS>
						</p>
						<S.flexRowBetween>
							{/* 정렬 */}
							<SelecArea onClick={() => setOpenFilter(true)}>
								<S.bodyText_S $ellipsis={1}>
									{sortList[orderBy].text}
								</S.bodyText_S>
								<Chevron fill={S.color.gray600} />
							</SelecArea>
							<p>
								<S.bodyText_XS color={S.color.gray600}>
									Harta karun terpilih{" "}
								</S.bodyText_XS>
								<S.bodyText_XS
									color={S.color.primaryVariant}
									style={{ fontWeight: "bold" }}
								>
									{checkedItem.length}
								</S.bodyText_XS>
								<S.bodyText_XS color={S.color.gray600}>
									(buah)
								</S.bodyText_XS>
							</p>
						</S.flexRowBetween>
					</MaterialArea>
					{/* 리스트 */}
					<RefreshHandler
						handleRefresh={() => upgradeReset()}
						contents={
							<S.wrapper>
								{upgradeDatas.length === 0 ? (
									<NoList type={3} />
								) : (
									<>
										<TreasureList>
											{upgradeDatas?.map(
												(upgradeData, index) => (
													<TreasureListItem>
														<TreasureImageWrap
															onClick={() =>
																checkItem(
																	upgradeData
																)
															}
															key={
																upgradeData.pumIdx
															}
															style={{
																marginRight:
																	index %
																		2 ===
																		0 &&
																	"1rem",
															}}
															$checked={checkedItem.includes(
																upgradeData
															)}
															$news={
																upgradeData.displayPrice >=
																newsPrice
															}
														>
															<div className="itemImage">
																<S.heightImg
																	src={
																		imgUrl +
																		upgradeData.imageUrl
																	}
																/>
															</div>
															<Circle className="checkCircle">
																<Check />
															</Circle>
														</TreasureImageWrap>
														<div className="itemInfo">
															<S.subhead_4
																className="name"
																$ellipsis={2}
															>
																{
																	upgradeData.name
																}
															</S.subhead_4>
															<p>
																<S.bodyText_S
																	color={
																		S.color
																			.primaryVariant
																	}
																>
																	{Util.addComma(
																		upgradeData.displayPrice
																	)}{" "}
																	IDR
																</S.bodyText_S>
															</p>
														</div>
													</TreasureListItem>
												)
											)}
										</TreasureList>
										<div
											style={{
												height:
													checkedItem.length === 0
														? 0
														: "15rem",
											}}
										/>
									</>
								)}
								<div ref={upgradeObserver} />
							</S.wrapper>
						}
					/>
				</Container>
				{/* 정렬 */}
				<Filter
					division={1}
					isOpen={isOpenFilter}
					close={() => setOpenFilter(false)}
					defaultOrderBy={defaultOrderBy}
					orderBy={orderBy}
					setOrderBy={setOrderBy}
				/>
			</S.page>
		</S.popupContainer>
	);
};
