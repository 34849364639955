// 이벤트
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom, IsOverlapAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import Popup from "../Components/Popup";
import RefreshHandler from "../Components/RefreshHandler";

import search from "../Images/search.png";

const EventPopupContents = styled.div`
	padding: 2.5rem 1.4rem;
	header {
		margin: 1rem 0 1.5rem;
		text-align: center;
	}
`;

interface eventData {
	beIdx: number;
	subject: string;
	eventBannerImageUrl: string;
	eventContentsImageUrl: string;
	eventLinkUrl: string;
	eventStartDt: string;
	eventEndDt: string;
	progressYn: string;
	displayYn: number;
	displayYnNm: string;
	regIdx: number;
}
interface eventDetailData {
	beIdx: number;
	subject: string;
	eventBannerImageUrl: string;
	eventContentsImageUrl: string;
	eventLinkUrl: string;
	eventContents: string;
	eventStartDt: string;
	eventEndDt: string;
	displayYn: number;
	regIdx: number;
}

export default () => {
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 팝업

	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	// 이벤트 리스트 정보
	const [eventDatas, setEventDatas] = useState<eventData[]>([]);
	const [eventNextPage, setEventNextPage] = useState<boolean>(true);
	const eventPage = useRef<number>(1);
	const eventObserver = useRef<HTMLDivElement>(null);
	// 검색
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	// 이벤트 상세 정보
	const [eventDetailData, setEventDetailData] = useState<eventDetailData>();

	// 이벤트 리셋
	const eventReset = () => {
		setEventDatas([]);
		setEventNextPage(true);
		eventPage.current = 1;
	};
	// 이벤트 리스트 불러오기
	const eventFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("eventFetch");
		searchYnRef.current = false;
		await Api.get(`/board/eventList`, {
			page: eventPage.current,
			keyword: keywordRef.current == "" ? undefined : keywordRef.current,
			limit: 15,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;

				setEventDatas((prevDatas) => [...prevDatas, ...data]);
				setEventNextPage(data.length === 15);
				if (data.length) {
					eventPage.current += 1;
				}
			} else {
				alert("fail");
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		if (!eventObserver.current || !eventNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				eventFetch();
			}
		});
		io.observe(eventObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [eventNextPage]);
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), eventReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		eventReset();
	};
	// 이벤트 진행 상태 확인
	const eventState = (startDt, endDt) => {
		const today = new Date();
		const startDate = new Date(Util.dateFormat(startDt, "date"));
		const endDate = new Date(Util.dateFormat(endDt, "date"));
		if (today > endDate) {
			return (
				<S.badge className="disabled">
					<S.bodyText_XS color={S.color.gray600}>
						Selesai
					</S.bodyText_XS>
				</S.badge>
			); // 종료
		} else if (today >= startDate && today <= endDate) {
			return (
				<S.borderBadge>
					<S.bodyText_XS color={S.color.primary}>
						Sedang berlangsung
					</S.bodyText_XS>
				</S.borderBadge>
			); // 진행중
		} else if (today < startDate) {
			return (
				<S.badge>
					<S.bodyText_XS color={S.color.primary}>
						Terjadwal
					</S.bodyText_XS>
				</S.badge>
			); // 예정
		}
	};
	// 이벤트 상세 정보 불러오기
	const eventDetailFetch = async (beIdx) => {
		await Api.get(`/board/eventView/` + beIdx).then((res) => {
			const data = res.data.data;
			console.log(data);
			setEventDetailData(data);
		});
	};
	return (
		<>
			<Page
				title="Event"
				contents={
					<>
						{/* 검색 */}
						<S.searchArea>
							<div className="searchBox">
								<input
									id="keyword"
									type="text"
									placeholder="kata pencarian"
									style={{ marginLeft: "1rem" }}
								/>
								<S.searchButton onClick={() => searchAction()}>
									<img src={search} />
								</S.searchButton>
							</div>
						</S.searchArea>
						{/* 리스트 */}
						<RefreshHandler
							handleRefresh={() => eventReset()}
							contents={
								<S.wrapper>
									{eventDatas.length === 0 ? (
										<NoList
											type={5}
											searchKeyword={keyword}
										/>
									) : (
										eventDatas?.map((eventData, index) => (
											<S.helpItem
												key={index}
												onClick={() => {
													eventDetailFetch(
														eventData.beIdx
													);
													setOpenPopup(true);
												}}
											>
												<div>
													{eventState(
														eventData.eventStartDt,
														eventData.eventEndDt
													)}
													<h1
														style={{
															marginTop: "0.8rem",
														}}
													>
														<S.subhead_3
															$ellipsis={2}
														>
															{eventData.subject}
														</S.subhead_3>
													</h1>
												</div>
											</S.helpItem>
										))
									)}
									<div ref={eventObserver} />
								</S.wrapper>
							}
						/>
					</>
				}
			/>
			{/* 이벤트 상세내용 */}
			<Popup
				isOpen={isOpenPopup}
				close={() => setOpenPopup(false)}
				title={
					eventDetailData !== undefined
						? eventDetailData.subject
						: "Evevt"
				}
				contents={
					<EventPopupContents>
						<header>
							<S.headline>
								{eventDetailData !== undefined &&
									eventDetailData.subject}
							</S.headline>
						</header>
						{/* TODO: 이미지 */}
					</EventPopupContents>
				}
			/>
		</>
	);
};
