// 점검
import React from "react";
import styled from "styled-components";

import * as S from "../Styles/Style";

import inspect from "../Images/inspection.png";

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.inspectionLogo {
		width: 12.8rem;
		height: 12rem;
		background-image: url(${inspect});
		background-size: contain;
		margin-bottom: 1.8rem;
	}
	p {
		color: #ff5b00;
		border-radius: 4rem;
		margin-top: 2.2rem;
		margin-bottom: 0.4rem;
	}
	span {
		text-align: center;
	}
	.inspection {
		margin-top: 1.2rem;
	}
`;

export default () => {
	return (
		<Container>
			<div className="inspectionLogo" />
			<S.mainTitle_XL color={S.color.gray900}>
				Sedang Melakukan{"\n"}Pemeliharaan Layanan
			</S.mainTitle_XL>
			<p>
				<S.subhead_2>Waktu Pemeliharaan </S.subhead_2>
				<S.subhead_2>13:00~15:00</S.subhead_2>
			</p>
			<S.bodyText_M_long color={S.color.gray900}>
				Kami sedang melakukan pemeliharaan{"\n"}layanan untuk memberikan
				pengalaman{"\n"}yang lebih nyaman bagi Anda.{"\n"}Harap tunggu
				hingga pemeliharaan selesai untuk{"\n"}menggunakan layanan
				dengan lancar.
			</S.bodyText_M_long>
			<S.bodyText_S color={S.color.gray600} className="inspection">
				* Waktu pemeliharaan dapat diperpanjang{"\n"}tergantung pada
				situasi pengerjaan.
			</S.bodyText_S>
		</Container>
	);
};
