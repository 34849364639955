import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from 'recoil';

import * as S from '../Styles/Style';

import defaultProfile from '../Images/default_profile.png';
import { ReactComponent as XIcon } from '../Images/x.svg';
import { config } from '../config/config';

const PopupWrapper = styled.div`
	width: calc(100% - 2.8rem);
	max-height: 100vh;
	position: relative;
	background-color: white;
	border-radius: 1rem;
	overflow: hidden;
	h1 {
		margin: 2rem 2rem 1.6rem;
	}
`;
const LargeImage = styled.img`
	width: 100%;
`;

interface DefaultType {
	isOpen: boolean;
	close: () => void;
	$url?: string;
}

export default ({ isOpen, close, $url }: PropsWithChildren<DefaultType>) => {
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;

	const source = () => {
		if ($url) {
			if ($url.includes('http')) {
				return $url;
			} else {
				return imgUrl + $url;
			}
		} else {
			return defaultProfile;
		}
	};
	return (
		<S.popupContainer $isOpen={isOpen} onClick={() => close()}>
			<PopupWrapper>
				<h1>
					<S.headline_4>Foto Profil</S.headline_4>
				</h1>
				<S.popupXIcon onClick={() => close()}>
					<XIcon fill={S.color.gray700} />
				</S.popupXIcon>
				{$url.includes('.mp4') || $url.includes('.mov') ? (
					<video
						style={{ objectFit: 'cover' }}
						muted
						autoPlay
						width={'100%'}
						height={'100%'}
						loop
						webkit-playsinline
						playsInline
						preload="metadata">
						<source src={$url ? imgUrl + $url : defaultProfile} type="video/mp4" />
						<source src={$url ? imgUrl + $url : defaultProfile} type="video/mov" />
					</video>
				) : (
					<LargeImage src={source()} />
				)}
			</PopupWrapper>
		</S.popupContainer>
	);
};

