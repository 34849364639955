// 보물상자 오픈 결과
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { UserAtom, NewsPrice } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";

import gachaBg from "../Images/gacha_bg.png";
import gachaRibbon from "../Images/gacha_ribbon.png";
import gachaBtn1 from "../Images/gacha_btn1.png";
import gachaBtn2 from "../Images/gacha_btn2.png";
import shine_frame_L from "../Images/shine_frame_L.png";
import shine_frame_M from "../Images/shine_frame_M.png";
import shine_frame_R from "../Images/shine_frame_R.png";
import { config } from "../config/config";

const Container = styled.div`
	height: 100%;
	background-image: url(${gachaBg});
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 5rem 1.4rem 2rem;
`;
const Ribbon = styled.div`
	height: 8rem;
	img {
		height: 100%;
	}
`;
const ResultList = styled.ul`
	width: 100%;
	margin: 1.2rem 0;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	li {
		width: 100%;
		background-color: white;
		border-radius: 1rem;
		box-shadow: 0 0.4rem 0 #c8b39f;
		display: flex;
		padding: 0 1.4rem;
		margin-bottom: 2rem;
	}
	.newsBorder {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			width: auto;
			height: 100%;
			flex-grow: 0;
			flex-shrink: 0;
		}
		img:nth-child(2) {
			flex-grow: 1;
		}
	}
	li.newsItem {
		padding: 0.2rem 1.4rem;
		box-shadow: none;
		position: relative;
		.newsBorder {
			display: flex;
		}
	}
`;
const ItemImage = styled.div`
	width: 10rem;
	height: 8rem;
	margin-right: 0.8rem;
	flex-shrink: 0;
	img {
		height: 100%;
	}
`;
const ItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const ButtonArea = styled.div`
	width: 100%;
	max-width: 40rem;
	height: 6rem;
	display: flex;
	justify-content: space-between;
	button {
		width: 48%;
		img {
			width: 100%;
		}
	}
`;

export default (props) => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const newsPrice = useRecoilValue(NewsPrice);
	const location = useLocation();
	const data = location.state?.data;
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;

	useEffect(() => {
		console.log(data);
	}, []);

	return (
		<Container>
			<Ribbon>
				<img src={gachaRibbon} alt="" />
			</Ribbon>
			<ResultList>
				{data?.map((item, index) => (
					<li
						key={index}
						className={
							item.displayPrice >= newsPrice ? "newsItem" : ""
						}
					>
						<ItemImage>
							<img src={imgUrl + item.imageUrl} alt="" />
						</ItemImage>
						<ItemInfo>
							<p style={{ marginBottom: "0.2rem" }}>
								<S.subhead_3 $ellipsis={1}>
									{item.name}
								</S.subhead_3>
							</p>
							<p>
								<S.bodyText_XS color={S.color.gray600}>
									Harga normal &nbsp;| &nbsp;
									{Util.addComma(item.displayPrice)} IDR
								</S.bodyText_XS>
							</p>
						</ItemInfo>
						{/* 강조효과 테두리 */}
						<S.flexRowCenter className="newsBorder">
							<img src={shine_frame_L} alt="" />
							<img src={shine_frame_M} alt="" />
							<img src={shine_frame_R} alt="" />
						</S.flexRowCenter>
					</li>
				))}
			</ResultList>
			<ButtonArea>
				{/* 확인 */}
				<button onClick={() => navigate("/", { replace: true })}>
					<img src={gachaBtn2} />
				</button>
				{/* 한번 더 */}
				<button
					onClick={() => {
						userState.goldboxCnt > 0
							? navigate("/BUPO001001", { replace: true })
							: navigate("/POIN001001", { replace: true });
					}}
				>
					<img src={gachaBtn1} />
				</button>
			</ButtonArea>
		</Container>
	);
};
