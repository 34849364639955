// 내보물함(보관)
import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { TRSRAtom, TRSRIdxAtom } from "../Recoil/Atoms/PopupState";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

import lock from "../Images/lock.png";
import unlock from "../Images/unlock.png";
import search from "../Images/search.png";
import sliders from "../Images/sliders.png";
import { config } from "../config/config";

const MyButton = styled.button<{ disabled?: boolean }>`
	width: 1%;
	min-width: calc(100% / 2 - 0.8rem);
	height: 3.4rem;
	flex: 1;
	border: 1px solid ${S.color.gray400};
	border-color: ${(props) => props.disabled && S.color.gray300};
	border-radius: 0.6rem;
	/* margin-top: 1.4rem; */
	margin-top: 0.8rem;
	margin-right: 0.8rem;
	background-color: ${(props) => props.disabled && S.color.gray200};
	span {
		white-space: nowrap;
		color: ${(props) => props.disabled && S.color.gray400} !important;
		text-align: center;
	}
`;

interface DefaultType {
	setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
	filter: string[];
	orderBy: number;
}

interface Data {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	LEN_NAME: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
}

export default ({
	setOpenFilter,
	filter,
	orderBy,
}: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 보물상세정보 팝업 생성
	const setOpenTRSR = useSetRecoilState<boolean>(TRSRAtom);
	const SetTRSRIdx = useSetRecoilState<number>(TRSRIdxAtom);
	// 잠금 상태 변경 토스트 팝업
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	// 내보물함 리스트 정보
	const [myDatas, setMyDatas] = useState<Data[]>([]);
	const [myNextPage, setMyNextPage] = useState<boolean>(true);
	const myPage = useRef<number>(1);
	const myObserver = useRef<HTMLDivElement>(null);
	// 검색
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	// A
	const filterRef = useRef<string[]>([]);
	const orderByRef = useRef<number>(null);
	//
	// 내보물함 리셋
	const myReset = () => {
		setMyDatas([]);
		setMyNextPage(true);
		myPage.current = 1;
	};
	// 내 보물함 목록 조회
	const myFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("myFetch");
		await Api.get(`/present/memberPresentList`, {
			page: myPage.current,
			filter:
				filterRef.current.length > 0 ? filterRef.current.join() : null,
			orderBy: orderByRef.current ?? orderBy,
			stateCode: "00, 01, 02, 03",
			keyword: keywordRef.current === "" ? undefined : keywordRef.current,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				setMyDatas((prevDatas) => [...prevDatas, ...data]);
				setMyNextPage(data.length === 10);
				if (data.length) {
					myPage.current += 1;
				}
			} else {
				alert("fail");
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (!myObserver.current || !myNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				myFetch();
			}
		});
		io.observe(myObserver.current);

		return () => {
			io.disconnect();
		};
	}, [myNextPage]);
	// 리스트 리셋
	useEffect(() => {
		filterRef.current = filter;
		orderByRef.current = orderBy;
		myReset();
	}, [orderBy, filter]);
	// 내 보물함 잠금
	const presentLockUpdate = async (
		pumIdx: number,
		lockYn: number,
		index: number
	) => {
		console.log(pumIdx, lockYn === 1 ? 0 : 1);
		await Api.patch(`/present/memberPresentLockUpdate`, {
			pumIdx: pumIdx,
			lockYn: lockYn === 1 ? 0 : 1,
		}).then((res) => {
			// 잠금 결과 팝업 생성
			setToastType(0);
			setToastContents(
				lockYn === 1 ? "Kunci telah terbuka." : "Telah terkunci."
			);
			setOpenToast(true);

			// 리셋 없이 잠금 상태만 변경
			const newData = [...myDatas];
			newData[index].lockYn = lockYn === 1 ? 0 : 1;
			setMyDatas(newData);
		});
	};
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), myReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		searchYnRef.current = true;
		myReset();
	};
	// 내보물함 리스트
	const RenderItem = ({ data, type, index }) => {
		return (
			<S.listItem
				key={data.pumIdx}
				onClick={() =>
					type == "delivery" &&
					navigate("/DLVR002001", {
						state: {
							item: data,
						},
					})
				}
				style={{ paddingTop: index == 0 && "0.6rem" }}
			>
				<div className="itemWrap">
					<div
						className="itemImage"
						onClick={() => {
							SetTRSRIdx(data.pmIdx);
							setOpenTRSR(true);
						}}
					>
						<S.heightImg src={imgUrl + data.imageUrl} />
					</div>
					<div className="itemInfo">
						<S.bodyText_XS
							color={S.color.gray400}
							style={{ margin: "0.4rem 0 1.4rem" }}
						>
							{Util.dateFormat(data.modDt, "date")}
						</S.bodyText_XS>
						<S.subhead_3 $ellipsis={2}>{data.name}</S.subhead_3>
						<S.bodyText_XS color={S.color.gray600}>
							Harga normal &nbsp;| &nbsp;
							{Util.addComma(data.displayPrice)} IDR
						</S.bodyText_XS>
					</div>
					<S.handlingButtonArea>
						{type == "my" && (
							<S.handlingButton
								onClick={() => {
									presentLockUpdate(
										data.pumIdx,
										data.lockYn,
										index
									);
								}}
								$url={data.lockYn === 1 ? lock : unlock}
							></S.handlingButton>
						)}
						{type == "delivery" && (
							<S.badge>
								<S.bodyText_XS color={S.color.primary}>
									Permintaan pengiriman
								</S.bodyText_XS>
							</S.badge>
						)}
					</S.handlingButtonArea>
				</div>
				{type == "my" && (
					<S.flexRowBetween
						style={{ flexWrap: "wrap", marginTop: "0.4rem" }}
					>
						<MyButton disabled>
							<S.bodyText_S color={S.color.gray700}>
								Lakukan Transaksi
							</S.bodyText_S>
						</MyButton>
						<MyButton
							onClick={() => navigate("/UPGD001001")}
							disabled={data.lockYn === 1}
						>
							<S.bodyText_S color={S.color.gray700}>
								Tukarkan ke Poin
							</S.bodyText_S>
						</MyButton>
						<MyButton
							// TODO: 배송중, 배송완료인 경우 상세정보로 이동
							onClick={() =>
								navigate("/DLVR001001", {
									state: {
										item: data,
									},
								})
							}
						>
							<S.bodyText_S color={S.color.gray700}>
								Kirim
							</S.bodyText_S>
						</MyButton>
					</S.flexRowBetween>
				)}
			</S.listItem>
		);
	};

	return (
		<S.wrapper>
			{/* 내보물함 */}
			{/* 검색 */}
			<S.searchArea>
				<div className="searchBox">
					<input
						id="keyword"
						type="text"
						placeholder="kata pencarian"
					/>
					<S.searchButton onClick={() => searchAction()}>
						<img src={search} />
					</S.searchButton>
				</div>
				<S.searchFilter
					onClick={() => {
						if (isLoading) return;
						setOpenFilter(true);
					}}
				>
					<img src={sliders} />
					{filter.length > 0 && (
						<p>
							<S.bodyText_XS color="white">
								{filter.length}
							</S.bodyText_XS>
						</p>
					)}
				</S.searchFilter>
			</S.searchArea>
			<RefreshHandler
				handleRefresh={() => {
					myReset();
				}}
				contents={
					<S.wrapper>
						{myDatas.length === 0 || myDatas === undefined ? (
							<NoList type={8} searchKeyword={keyword} bottom />
						) : (
							<S.presentList>
								{myDatas?.map((data, index) => (
									<RenderItem
										key={index}
										data={data}
										type="my"
										index={index}
									/>
								))}
							</S.presentList>
						)}
						<div ref={myObserver} />
					</S.wrapper>
				}
			/>
		</S.wrapper>
	);
};
