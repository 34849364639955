import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";
import Api from "../libs/api";

import * as S from "../Styles/Style";

import point from "../Images/point.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const MethodRadio = styled(S.controlsWrap)`
	margin-bottom: 1.6rem;
	align-items: center;
`;
const PointInfo = styled(S.flexRowBetween)`
	margin-bottom: 1.6rem;
	img {
		width: 2.2rem;
		height: 2.2rem;
		margin-left: 0.4rem;
	}
`;
const PointInput = styled(S.flexRowBetween)`
	margin-bottom: 0.8rem;
`;

interface DefaultType {
	type: number; // 결제 타입 구분 1:상자/2:배송비
	totalPrice: number; // 총 결제 금액
	paymentType: number; // 결제 방식
	setPaymentType: React.Dispatch<React.SetStateAction<number>>; // 결제 방식 set
	pointToUse: string; // 사용할 포인트
	setPointToUse: React.Dispatch<React.SetStateAction<string>>; // 사용할 포인트 set
}

export default ({
	type,
	totalPrice,
	paymentType,
	setPaymentType,
	pointToUse,
	setPointToUse,
}: PropsWithChildren<DefaultType>) => {
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const userState = useRecoilValue(UserAtom);
	// 결제 안내 아코디언
	const [viewInfo, setViewInfo] = useState<boolean>(false);
	const [visibleRatio, setVisibleRatio] = useState<boolean>(false);

	useEffect(() => {
		ratioCheck();
	}, []);
	useEffect(() => {
		setPointToUse("0");
	}, [paymentType, totalPrice]);
	// 포인트 전체 사용 선택
	const usePointAll = () => {
		if (totalPrice <= 0) {
			setPointToUse("0");
		} else if (userState.freePoint > totalPrice) {
			// 보유 포인트 > 구매 금액
			setPointToUse(Util.addComma(totalPrice));
		} else if (userState.freePoint < totalPrice) {
			// 보유 포인트 < 구매 금액
			setPointToUse(Util.addComma(userState.freePoint));
		} else {
			setPointToUse("0");
		}
	};
	// 사용할 포인트 입력
	const usePointInput = (e) => {
		if (totalPrice <= 0) {
			e.target.value = "";
		} else {
			if (
				Util.toNumber(e.target.value) >= totalPrice &&
				userState.freePoint >= totalPrice
			) {
				setPointToUse(Util.addComma(totalPrice));
			} else if (Util.toNumber(e.target.value) >= userState.freePoint) {
				setPointToUse(Util.addComma(userState.freePoint));
			} else {
				setPointToUse(Util.onChangeComma(e.target.value));
			}
		}
	};
	// 확률 관련 내용 생성 여부
	const ratioCheck = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.get("/common/gradePercentage", {
			page: 1,
			limit: 30,
		}).then((res) => {
			console.log(res);
			if (res.data !== undefined) {
				if (res.data.data == null) {
					setVisibleRatio(false);
				} else {
					setVisibleRatio(true);
				}
			} else {
				setVisibleRatio(false);
				alert("fail");
			}
		});
		setLoading(false);
	};
	// 결제 안내
	const explain = () => {
		const data =
			type === 1 ? (
				<div>
					<header>
						<S.subhead_4>Informasi Pembayaran</S.subhead_4>
						<Chevron fill={S.color.gray400} />
					</header>
					<S.textList $gray>
						<li>
							<S.bodyText_S>
								Membeli Random Box memungkinkan Anda untuk
								mendapatkan satu hadiah secara acak.
								{visibleRatio &&
									"\n\nDaftar produk yang bisa didapatkan dapat dilihat di daftar harta karun atau dengan menekan tombol tanda seru (!) pada peti harta karun yang akan dibuka, lalu periksa daftar produk.\n\n"}
								Jika Anda sudah membuka peti harta karun dan
								mendapatkan hadiahnya, pembatalan pembayaran
								tidak dapat dilakukan.
							</S.bodyText_S>
						</li>
						<li className="subTitle">
							<S.bodyText_S>
								[Informasi Pembatalan Pembayaran]
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Pembatalan pembayaran hanya dapat dilakukan
								untuk peti harta karun yang belum digunakan
								dalam waktu 7 hari sejak tanggal pembayaran.
								Silakan hubungi Layanan 1:1 untuk memprosesnya.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Jika Anda sudah membuka peti harta karun dan
								mendapatkan hadiahnya, pembatalan pembayaran
								tidak dapat dilakukan.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Proses pengembalian dana setelah pembatalan
								pembayaran mungkin memakan waktu 2-5 hari kerja,
								tergantung pada metode pembayaran Anda.
							</S.bodyText_S>
						</li>
						<li className="subTitle">
							<S.bodyText_S>
								[Informasi Pengiriman/Penggantian]
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Untuk menerima hadiah dari peti harta karun,
								Anda perlu mengajukan permintaan pengiriman
								terpisah di "Harta Karun Saya".{`\n`}(Ongkos
								kirim 3,000P, Jeju/daerah pulau 6,000P)
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Pembatalan pengiriman dapat dilakukan melalui
								"Status Pengiriman" di "Harta Karun Saya" jika
								statusnya masih "Permintaan Pengiriman".
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Penggantian dan pengembalian produk yang
								diterima karena alasan seperti perubahan pikiran
								atau ketidakpuasan tidak dapat dilakukan.
							</S.bodyText_S>
						</li>
					</S.textList>
				</div>
			) : (
				<div>
					<header>
						<S.subhead_4>
							Informasi Pembayaran Ongkos Kirim
						</S.subhead_4>
						<Chevron fill={S.color.gray400} />
					</header>
					<S.textList $gray>
						<li>
							<S.bodyText_S>
								Ongkos kirim yang telah dibayarkan akan secara
								otomatis dibatalkan jika Anda membatalkan
								pesanan. Pengembalian dana akan diproses dalam
								waktu maksimal 5 hari kerja, tergantung pada
								kebijakan bank dan kartu kredit Anda.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Anda dapat membatalkan pengiriman sendiri
								melalui [Harta Karun Saya {`>`}Permintaan
								Pengiriman {`>`} Status Pengiriman] jika produk
								belum dikirimkan.
							</S.bodyText_S>
						</li>
					</S.textList>
				</div>
			);
		return data;
	};

	return (
		<>
			<section>
				<MethodRadio>
					<S.radio
						id="paymentType2"
						name="paymentType"
						type="radio"
						disabled={totalPrice < 1}
						checked={paymentType == 2}
						onChange={(e) => setPaymentType(2)}
					></S.radio>
					<label htmlFor="paymentType2">
						<S.subhead_2 color={S.color.gray800}>
							Metode Pembayaran
						</S.subhead_2>
					</label>
				</MethodRadio>
				<MethodRadio>
					<S.radio
						id="paymentType1"
						name="paymentType"
						type="radio"
						disabled={totalPrice < 1}
						defaultChecked
						checked={paymentType == 1}
						onChange={(e) => setPaymentType(1)}
					></S.radio>
					<label htmlFor="paymentType1">
						<S.subhead_2 color={S.color.gray800}>
							Pembayaran dengan poin
						</S.subhead_2>
					</label>
				</MethodRadio>
				<PointInfo>
					<S.subhead_3 color={S.color.gray800}>Poin Saya</S.subhead_3>
					<S.flexRowCenter>
						<S.subhead_2>
							{Util.addComma(userState.freePoint)}
						</S.subhead_2>
						<img src={point} />
					</S.flexRowCenter>
				</PointInfo>
				<PointInput>
					<S.subhead_3 color={S.color.gray800}>Gunakan</S.subhead_3>
					<S.bodyText_S color={S.color.gray600}>
						Poin bisa digunakan mulai dari 1P.
					</S.bodyText_S>
				</PointInput>
				<S.inputField
					$disabled={
						paymentType !== 1 || userState.freePoint < totalPrice
					}
				>
					<div className="inputWrap">
						<input
							type="text"
							placeholder="0P"
							value={pointToUse + "P"}
							readOnly
							onChange={(e) => usePointInput(e)}
						/>
					</div>
					<button
						onClick={() => usePointAll()}
						disabled={
							paymentType !== 1 ||
							userState.freePoint < totalPrice
						}
					>
						<S.button_XS>Gunakan Poin</S.button_XS>
					</button>
				</S.inputField>
			</section>
			<section>
				<p>
					<S.subhead_2 color={S.color.gray800}>
						Informasi pembayaran
					</S.subhead_2>
				</p>
				<S.bar $size="1.6rem" />
				<S.flexRowBetween>
					<S.bodyText_M_long color={S.color.gray800}>
						{type === 1 ? "Total Harga" : "Ongkos Kirim"} (Rp)
					</S.bodyText_M_long>
					<S.subhead_2>{Util.addComma(totalPrice)}</S.subhead_2>
				</S.flexRowBetween>
				{paymentType === 1 && (
					<S.flexRowBetween style={{ marginTop: "0.8rem" }}>
						<S.bodyText_M_long color={S.color.gray800}>
							Poin (P)
						</S.bodyText_M_long>
						<S.subhead_2>- {pointToUse}</S.subhead_2>
					</S.flexRowBetween>
				)}
				<S.bar $size="1.6rem" $color={S.color.gray500} />
				<S.flexRowBetween>
					<S.subhead_3 color={S.color.gray800}>
						Total Pembayaran ({paymentType === 2 ? "Rp" : "P"})
					</S.subhead_3>
					<S.subhead_2 color={S.color.primary}>
						{Util.addComma(totalPrice - Util.toNumber(pointToUse))}
					</S.subhead_2>
				</S.flexRowBetween>
				<S.expandedExplain
					$expand={viewInfo}
					onClick={() => {
						setViewInfo(!viewInfo);
					}}
				>
					{explain()}
				</S.expandedExplain>
			</section>
		</>
	);
};
