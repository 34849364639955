// 번호 인증
import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import Api from '../libs/api';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { UserAtom, LoadingAtom } from '../Recoil/Atoms/UserState';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';
import Util from '../Store/Util';

import * as S from '../Styles/Style';

interface DefaultType {
	type: number;
	// 이름
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	// 번호
	phoneNumber: string;
	setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
	// 번호 중복체크 필요시
	setOverlapData?: React.Dispatch<React.SetStateAction<object>>;
	setOpenAlert?: React.Dispatch<React.SetStateAction<boolean>>;
	// 번호 인증 완료 여부
	complete: boolean;
	setComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({
	type,
	name,
	setName,
	phoneNumber,
	setPhoneNumber,
	setOverlapData,
	setOpenAlert,
	complete,
	setComplete,
}: PropsWithChildren<DefaultType>) => {
	const [userState, setUserState] = useRecoilState(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);

	// 번호 인증
	const [sendYn, setSendYn] = useState<boolean>(false);
	const [otp, setOtp] = useState<string>('');
	const [diffMin, setDiffMin] = useState<string>('');
	const [diffSec, setDiffSec] = useState<string>('');
	const [phoneNumberExplain, setPhoneNumberExplain] = useState<string>('');
	const [validationExplain, setValidationExplain] = useState<string>('');
	const resetRef = useRef<boolean>(false);

	// 입력값 변경 이벤트
	const onChangeEvent = (target) => {
		// 이름, 번호 수정 시 리셋
		if (target.name == 'phoneNumber' || target.name == 'name') {
			setSendYn(false);
			setOtp('');
			setPhoneNumberExplain('');
			setValidationExplain('');
			setComplete(false);
			resetRef.current = true;
		}

		// 입력 최대값 체크
		if (target.value.length > target.maxLength) {
			if (target.name == 'phoneNumber') {
				setPhoneNumber(target.value.slice(0, target.maxLength));
			} else if (target.name == 'otp') {
				setOtp(target.value.slice(0, target.maxLength));
			}
		} else {
			if (target.name == 'phoneNumber') {
				setPhoneNumber(Util.phoneNumFormat(target.value));
			} else if (target.name == 'otp') {
				setOtp(Util.phoneNumFormat(target.value));
			} else if (target.name == 'name') {
				setName(target.value);
			}
		}
	};
	// 휴대폰 인증 전송
	const validationSend = async () => {
		if (isLoading) return;
		// Kirim ulang kode verifikasi 재전송
		// 필수값 입력 안한 경우 리턴
		if (name.length <= 0) return;
		if (phoneNumber.length <= 0) return;
		setLoading(true);
		setOtp(''); // 인증번호 리셋
		setPhoneNumberExplain(''); // 안내문구 리셋
		setValidationExplain(''); // 안내문구 리셋
		await Api.get('/auth/phoneNumberValidationSend', {
			name: name,
			recipientNo: Util.onlyNum(phoneNumber),
			// recipientNo: `82126785984`, // 정상발송 test
			// recipientNo: 123456, // 중복번호 test
			// recipientNo: 12345678, // 유효하지않은 번호 test
			pass: type == 1, // true : 중복체크 안함(마이페이지)
		}).then((res) => {
			// if (res.data.data.validateTime != undefined) {
			if (res.data != undefined) {
				resetRef.current = false;
				setSendYn(true); // 인증번호 입력 영역 생성
				// 전송 성공 시 인증시간 셋팅
				const nowTime = new Date();
				nowTime.setMinutes(nowTime.getMinutes() + 3);
				timeCheck(Util.getDateString(nowTime));

				// const validateTime = res.data.data.validateTime;
				// timeCheck(validateTime);

				// const otp = res.data.data.otp; // 테스트용
				// if (otp !== undefined) {
				// 	setOtp(otp); // 테스트용
				// }
			} else {
				const code = res.response.data.error;
				if (code == -201) {
					// 이미 존재하는 번호
					setOpenAlert(true);
					setOverlapData(JSON.parse(res.response.data.message));
				} else if (code == -202) {
					// 유효하지 않은 번호
					setPhoneNumberExplain('Silakan periksa kembali nomor telepon Anda.');
				} else if (code == -203) {
					// 1분 후 재전송 가능
					setPhoneNumberExplain('Ulangi setelah 1 menit jika belum menerima kode OTP.');
				} else {
					alert('fail');
				}
			}
		});
		setLoading(false);
	};
	// 인증 번호 유효 시간 확인
	const timeCheck = (validateTime: string) => {
		const validateTimeFormat = new Date(Util.dateFormat(validateTime, 'newDate'));
		const Timer = () => {
			const nowTime = new Date();
			const diffTime = (validateTimeFormat.getTime() - nowTime.getTime()) / 1000;
			if (diffTime < 0 || resetRef.current) {
				console.log('clearInterval');
				clearInterval(interval);
			}
			let min = Math.floor(diffTime / 60);
			let sec = Math.floor(diffTime % 60);
			if (diffTime < 0) {
				// 타이머 종료
				setDiffMin('00');
				setDiffSec('00');
				setSendYn(false); // 인증번호 입력 영역 비활성화
				setValidationExplain('Waktu sudah habis. Silakan mengajukan ulang kode OTP.');
			} else {
				setDiffMin(min >= 10 ? String(min) : '0' + min);
				setDiffSec(sec >= 10 ? String(sec) : '0' + sec);
			}
		};
		const interval = setInterval(Timer, 1000);
	};
	// 인증 번호 확인
	const validationCheck = async (otp: string) => {
		setPhoneNumberExplain(''); // 안내문구 리셋
		setValidationExplain(''); // 안내문구 리셋
		await Api.patch('/auth/phoneNumberValidation', {
			recipientNo: Util.onlyNum(phoneNumber),
			otp: otp,
			name: name,
		}).then((res) => {
			console.log(res);
			if (res.data !== undefined) {
				const code = res.data.statusCode;
				if (code == 200) {
					// 인증완료
					setToastType(1);
					setToastContents('Proses verifikasi telah berhasil.');
					setOpenToast(true);
					setComplete(true);
					resetRef.current = true;
				}
			} else {
				const code = res.response.data.error;
				if (code == -402) {
					// 유저 정보 없음(DB저장 실패)
				} else if (code == -403) {
					// otp 불일치
					// 인증번호가 올바르지 않습니다.
					setValidationExplain('Kode OTP tidak sesuai.');
				} else if (code == -404) {
					// otp 캐시 오류
				} else if (code == -405) {
					// otp 타입 오류
				} else if (code == -406) {
					// otp 시간 종료(전송으로부터 3분)
					setValidationExplain('Waktu sudah habis. Silakan mengajukan ulang kode OTP.');
				} else {
					alert('fail');
				}
			}
		});
	};

	return (
		<>
			<h3>
				<S.subhead_4>Nama</S.subhead_4>
			</h3>
			<S.inputField>
				<div className="inputWrap">
					<input
						name="name"
						type="text"
						placeholder="Silakan masukkan nama Anda"
						value={name}
						onChange={(e) => onChangeEvent(e.target)}
						maxLength={30}
					/>
				</div>
			</S.inputField>
			<h3>
				<S.subhead_4>Kontak</S.subhead_4>
			</h3>
			<S.inputField>
				<div className="inputWrap">
					<input
						name="phoneNumber"
						type="text"
						value={phoneNumber}
						onChange={(e) => {
							onChangeEvent(e.target);
						}}
						placeholder="0800 - 000 - 000"
						// 이름 입력 시 활성화
						disabled={name.length < 2}
						maxLength={18}
					/>
				</div>
				<button
					onClick={() => validationSend()}
					disabled={isLoading || phoneNumber.length < 10 || name.length < 2}
					style={{ minWidth: '12rem' }}>
					<S.button_XS>{!sendYn ? 'Kirim OTP' : 'Kirim Ulang OTP'}</S.button_XS>
				</button>
			</S.inputField>
			<S.bodyText_S color={S.color.error}>{phoneNumberExplain}</S.bodyText_S>
			{/* 마이페이지에서는 인증번호 전송 시 생성 */}
			{((type == 1 && sendYn) || type == 0) && (
				<>
					<h3>
						<S.subhead_4>OTP</S.subhead_4>
					</h3>
					<S.inputField>
						<div className="inputWrap">
							<input
								name="otp"
								type="number"
								value={otp}
								onChange={(e) => {
									onChangeEvent(e.target);
								}}
								// 인증번호 발송 시 활성화
								disabled={!sendYn || complete}
								maxLength={6}
							/>
							<S.bodyText_XS>{sendYn && !complete && `${diffMin}:${diffSec}`}</S.bodyText_XS>
						</div>
						<button
							onClick={() => validationCheck(otp)}
							disabled={isLoading || otp.length < 6 || !sendYn || complete}>
							<S.button_XS>Periksa</S.button_XS>
						</button>
					</S.inputField>
				</>
			)}
			<S.bodyText_S color={S.color.error}>{validationExplain}</S.bodyText_S>
		</>
	);
};

