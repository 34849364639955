// 약관(서비스 이용약관)
import React from "react";
import styled from "styled-components";

import * as S from "../Styles/Style";

const Container = styled(S.wrapper)`
	height: 1rem;
	min-height: 100%;
	padding: 1.4rem;
	overflow-y: scroll;
	flex-grow: 1;
	flex-shrink: 1;
	p {
		margin-bottom: 1rem;
	}
	h2 {
		font-family: "RobotoMedium";
		font-size: 1.4rem;
		line-height: 2.2rem;
	}
	span {
		font-family: "RobotoRegular";
		font-size: 1.2rem;
		line-height: 1.8rem;
		display: block;
		margin-bottom: 0.8rem;
		color: ${S.color.gray800};
	}
	span.sub {
		padding-left: 1rem;
	}
	span.small {
		font-size: 1rem;
		padding-left: 1rem;
	}
`;

export default () => {
	return (
		<Container>
			<p>
				<h2>1. Pendahuluan</h2>
			</p>
			<p>
				<span>
					Syarat dan Ketentuan ini dibuat untuk mengatur hak,
					kewajiban, dan tanggung jawab pengguna dalam menggunakan
					aplikasi GoPang (selanjutnya disebut "Aplikasi") yang
					dioperasikan oleh PT Bomulsun HBiz Indonesia (selanjutnya
					disebut "Perusahaan") dan semua layanan terkait yang
					disediakan melalui Aplikasi (selanjutnya disebut "Layanan")
				</span>
			</p>
			<p>
				<h2>2. Istilah dan Definisi</h2>
			</p>
			<p>
				<span>
					① "Aplikasi" merujuk pada sebuah tempat bisnis virtual yang
					didirikan oleh "Perusahaan" menggunakan perangkat komunikasi
					seperti komputer atau ponsel untuk memungkinkan pengguna
					melakukan transaksi pembelian barang atau jasa (selanjutnya
					disebut "barang, dll."). Istilah "Aplikasi" juga digunakan
					untuk merujuk pada penyedia layanan aplikasi.
				</span>
				<span>
					② "Pengguna" adalah anggota atau non-anggota yang mengakses
					"Aplikasi" dan menerima layanan yang disediakan oleh
					"Aplikasi" sesuai dengan Syarat dan Ketentuan Layanan ini.
				</span>
				<span>
					③ "Random Box" adalah kotak virtual yang berisi salah satu
					dari sekian banyak produk yang dipublikasikan oleh
					"Perusahaan" di "Aplikasi". Produk dalam "Random Box"
					ditentukan secara acak dan merupakan objek transaksi antara
					perusahaan dan pengguna.
				</span>
				<span>
					④ "Gabung" mengacu pada tindakan pengguna untuk menukar
					produk tertentu yang diperoleh dari "Random Box" dengan
					produk lainnya.
				</span>
				<span>
					⑤ "Tukar" mengacu pada tindakan pengguna untuk menukar
					produk tertentu yang diperoleh dari "Random Box" dengan
					poin.
				</span>
				<span>
					⑥ "Poin" adalah poin dalam aplikasi yang dapat digunakan
					oleh pengguna untuk berbagai tujuan seperti membeli "Random
					Box" atau membayar biaya pengiriman.
				</span>
			</p>
			<p>
				<h2>3. Ketentuan, Penjelasan, dan Perubahan</h2>
			</p>
			<p>
				<span>
					① Aplikasi wajib menampilkan isi Syarat dan Ketentuan
					Layanan ini, nama perusahaan, nama perwakilan, alamat kantor
					(termasuk alamat untuk penanganan keluhan konsumen), nomor
					identitas bisnis, dan penanggung jawab perlindungan data
					pribadi di halaman layanan Aplikasi sehingga mudah diakses
					oleh pengguna. Syarat dan Ketentuan Layanan dapat
					ditampilkan melalui tautan (link).
				</span>
				<span>
					② Sebelum pengguna menyetujui Syarat dan Ketentuan Layanan
					ini, Aplikasi wajib menyediakan tautan atau pop-up yang
					berisi penjelasan mengenai poin-poin penting seperti
					pembatalan pesanan, tanggung jawab pengiriman, dan ketentuan
					pengembalian uang agar pengguna dapat memahaminya dengan
					baik.
				</span>
				<span>
					③ Aplikasi dapat mengubah Syarat dan Ketentuan Layanan ini
					sepanjang tidak melanggar undang-undang Informasi dan
					Transaksi Elektronik (UU ITE), Undang-Undang Perlindungan
					Konsumen, Undang-Undang Perlindungan Data Pribadi,
					Undang-Undang Perdagangan, dan peraturan perundang-undangan
					terkait lainnya.
				</span>
				<span>
					④ Jika Aplikasi mengubah Syarat dan Ketentuan Layanan ini,
					maka tanggal efektif dan alasan perubahan akan dicantumkan
					dan diumumkan bersama dengan Syarat dan Ketentuan Layanan
					yang berlaku saat ini di halaman utama Aplikasi setidaknya 7
					hari sebelum tanggal efektif. Namun, jika perubahan tersebut
					merugikan pengguna, maka masa tenggang pemberitahuan minimal
					adalah 30 hari. Dalam hal ini, Aplikasi wajib menampilkan
					perbandingan yang jelas antara Syarat dan Ketentuan Layanan
					sebelum dan sesudah perubahan agar mudah dipahami oleh
					pengguna.
				</span>
				<span>
					⑤ Perubahan Syarat dan Ketentuan Layanan ini hanya berlaku
					untuk kontrak yang dibuat setelah tanggal efektif perubahan.
					Untuk kontrak yang telah dibuat sebelumnya, Syarat dan
					Ketentuan Layanan yang berlaku adalah yang sebelumnya.
					Namun, jika pengguna yang telah membuat kontrak ingin tunduk
					pada Syarat dan Ketentuan Layanan yang baru, pengguna dapat
					mengirimkan pemberitahuan kepada aplikasi selama masa
					pemberitahuan perubahan Syarat dan Ketentuan Layanan sesuai
					dengan poin 3, dan jika disetujui oleh aplikasi, maka Syarat
					dan Ketentuan Layanan yang baru akan berlaku.
				</span>
			</p>
			<p>
				<h2>4. Penyediaan dan Perubahan Layanan</h2>
			</p>
			<p>
				<span>① Aplikasi melakukan kegiatan sebagai berikut:</span>
				<span className="sub">
					1. Menyediakan informasi mengenai penggunaan Random Box dan
					memfasilitasi proses pembelian Random Box.
				</span>
				<span className="sub">
					2. Melakukan pengiriman produk spesifik yang didapatkan dari
					Random Box setelah Random Box dibuka.
				</span>
				<span className="sub">
					3. Melakukan kegiatan lain yang ditentukan oleh Aplikasi.
				</span>
				<span>
					② Dalam hal terjadi kehabisan stok barang atau perubahan
					spesifikasi teknis, Aplikasi berhak mengubah isi barang atau
					jasa yang akan diberikan pada transaksi berikutnya. Dalam
					hal ini, Aplikasi akan segera mengumumkan perubahan tersebut
					beserta tanggal berlakunya di tempat yang sama dengan
					pengumuman sebelumnya.
				</span>
				<span>
					③ Jika Aplikasi perlu mengubah layanan yang telah dibeli
					oleh pengguna karena alasan seperti kehabisan stok atau
					perubahan spesifikasi teknis, Aplikasi akan segera
					memberitahu pengguna melalui email, SMS, atau cara lain yang
					memungkinkan.
				</span>
				<span>
					④ Dalam hal terjadi kerugian pada pengguna akibat perubahan
					layanan, Aplikasi wajib memberikan ganti rugi. Namun,
					Aplikasi tidak bertanggung jawab jika dapat membuktikan
					bahwa kesalahan tersebut bukan berasal dari kesengajaan atau
					kelalaian mereka.
				</span>
			</p>
			<p>
				<h2>5. Gangguan Layanan</h2>
			</p>
			<p>
				<span>
					① Aplikasi dapat menghentikan sementara layanan jika terjadi
					gangguan pada sistem komputer atau komunikasi, seperti
					pemeliharaan, penggantian, kerusakan, gangguan jaringan,
					bencana alam, atau alasan lain yang serupa.
				</span>
				<span>
					② Aplikasi akan bertanggung jawab atas kerugian yang dialami
					pengguna atau pihak ketiga akibat penghentian sementara
					layanan sebagaimana dimaksud pada poin pertama. Namun,
					Aplikasi tidak bertanggung jawab jika dapat membuktikan
					bahwa kesalahan tersebut bukan berasal dari kesengajaan atau
					kelalaian mereka.
				</span>
				<span>
					③ Jika Aplikasi tidak lagi dapat menyediakan layanan karena
					alasan seperti perubahan jenis usaha, penghentian usaha,
					atau penggabungan dengan perusahaan lain, maka Aplikasi akan
					memberitahukan pengguna sesuai dengan ketentuan pada pasal 8
					dan memberikan kompensasi kepada pengguna sesuai dengan
					ketentuan yang telah ditetapkan sebelumnya oleh Aplikasi.
					Namun, jika Aplikasi tidak mengumumkan standar kompensasi,
					maka Aplikasi akan memberikan kompensasi kepada pengguna
					dalam bentuk barang, uang tunai, atau poin yang setara
					dengan nilai mata uang yang berlaku di Aplikasi berdasarkan
					poin atau saldo pengguna.
				</span>
			</p>
			<p>
				<h2>6. Pendaftaran</h2>
			</p>
			<p>
				<span>
					① Pengguna mengajukan permohonan menjadi anggota dengan
					mengisi formulir pendaftaran yang telah disediakan oleh
					Aplikasi dan menyatakan persetujuan terhadap Syarat dan
					Ketentuan Layanan ini.
				</span>
				<span>
					② Aplikasi akan mendaftarkan pengguna sebagai anggota yang
					mengajukan permohonan sesuai dengan poin 1, kecuali jika
					pengguna tersebut:
				</span>
				<span className="sub">
					1. Pernah kehilangan status keanggotaan sebelumnya
					berdasarkan pasal 7 ayat 3. Namun, jika sudah lewat 3 tahun
					sejak kehilangan status keanggotaan tersebut dan mendapatkan
					persetujuan dari Aplikasi untuk mendaftar kembali, maka
					pengecualian dapat diberikan.
				</span>
				<span className="sub">
					2. Informasi pendaftaran yang diberikan adalah palsu, tidak
					lengkap, atau salah.
				</span>
				<span className="sub">
					3. Pendaftaran dianggap dapat mengganggu sistem teknologi
					Aplikasi secara signifikan.
				</span>
				<span>
					③ Perjanjian keanggotaan dianggap berlaku sejak pengguna
					menerima persetujuan dari Aplikasi.
				</span>
				<span>
					④ Anggota wajib segera memperbarui informasi pendaftarannya
					jika terjadi perubahan data, baik melalui fitur perbarui
					data atau dengan menghubungi Aplikasi.
				</span>
				<span>
					⑤ Jika pengguna menolak memberikan izin atas pengumpulan,
					penggunaan, dan penyediaan data pribadi, maka pendaftaran
					anggota dapat dibatasi.
				</span>
			</p>
			<p>
				<h2>
					7. Pengakhiran Keanggotaan, Pencabutan Hak Anggota, dan
					Lain-lain
				</h2>
			</p>
			<p>
				<span>
					① Anggota dapat mengajukan permohonan untuk mengakhiri
					keanggotaannya kapan saja, dan Aplikasi akan segera
					memproses permintaan tersebut. Namun, jika masih ada poin,
					peti, atau produk yang belum digunakan, maka anggota
					dianggap telah melepaskan haknya atas aset tersebut dan
					semua aset akan hangus secara otomatis saat anggota keluar.
				</span>
				<span>
					② Jika anggota melakukan hal-hal berikut, Aplikasi dapat
					membatasi atau mencabut keanggotaannya:
				</span>
				<span className="sub">
					1. Memberikan informasi palsu atau menggunakan identitas
					orang lain saat mendaftar.
				</span>
				<span className="sub">
					2. Tidak membayar tagihan pembelian barang atau kewajiban
					lainnya yang terkait dengan penggunaan Aplikasi sesuai
					dengan tanggal jatuh tempo.
				</span>
				<span className="sub">
					3. Mengganggu penggunaan Aplikasi oleh orang lain, mencuri
					informasi, atau melakukan tindakan yang mengancam ketertiban
					transaksi elektronik.
				</span>
				<span className="sub">
					4. Melakukan tindakan yang dilarang oleh hukum, Syarat dan
					Ketentuan Layanan ini, atau bertentangan dengan ketertiban
					umum dan kesusilaan.
				</span>
				<span>
					③ Jika Aplikasi membatasi atau menghentikan keanggotaan
					seorang anggota, dan anggota tersebut mengulangi pelanggaran
					yang sama sebanyak 2 kali atau lebih, atau tidak memperbaiki
					pelanggaran tersebut dalam waktu 30 hari, maka Aplikasi
					dapat mencabut keanggotaannya.
				</span>
				<span>
					④ Jika Aplikasi mencabut keanggotaan seorang anggota, maka
					data keanggotaan anggota tersebut akan dihapus. Dalam hal
					ini, Aplikasi akan memberitahukan anggota yang bersangkutan
					dan memberikan kesempatan kepada anggota tersebut untuk
					memberikan klarifikasi selama minimal 30 hari sebelum data
					keanggotaannya dihapus.
				</span>
				<span>
					⑤ Jika terjadi hal-hal berikut, Aplikasi dapat menangguhkan
					sementara akun anggota sebagai tindakan sementara selama
					penyelidikan sedang berlangsung:
				</span>
				<span className="sub">
					1. Jika ada laporan bahwa akun tersebut telah diretas atau
					disalahgunakan.
				</span>
				<span className="sub">
					2. Jika ada laporan atau indikasi bahwa anggota telah
					melanggar Syarat dan Ketentuan.
				</span>
			</p>
			<p>
				<h2>8. Pemberitahuan kepada Anggota</h2>
			</p>
			<p>
				<span>
					① Aplikasi dapat mengirimkan pemberitahuan kepada anggota
					melalui alamat email, SMS, atau cara lain yang telah
					disepakati sebelumnya oleh anggota.
				</span>
				<span>
					② Untuk pemberitahuan kepada sejumlah besar anggota,
					Aplikasi dapat menggantinya dengan pengumuman di papan
					pengumuman Aplikasi selama minimal 1 minggu. Namun, untuk
					hal-hal yang sangat berpengaruh terhadap transaksi pribadi
					anggota, pemberitahuan akan dilakukan secara individual.
				</span>
			</p>
			<p>
				<h2>
					9. Pengajuan Pembelian dan Persetujuan Privasi, dan
					Sebagainya
				</h2>
			</p>
			<p>
				<span>
					① Pengguna Aplikasi mengajukan permohonan pembelian di
					Aplikasi menggunakan metode berikut atau metode serupa, dan
					Aplikasi wajib memberikan informasi berikut dengan jelas
					kepada pengguna ketika mengajukan permohonan pembelian:
				</span>
				<span className="sub">
					1. Informasi mengenai pembelian dan penggunaan ‘Random Box’
				</span>
				<span className="sub">
					2. Pengisian nama penerima, alamat, nomor telepon, alamat
					email (atau nomor ponsel).
				</span>
				<span className="sub">
					3. Konfirmasi mengenai isi Syarat dan Ketentuan Layanan,
					bahwa objek jual beli antara Aplikasi dan pengguna adalah
					'Random Box', bahwa perjanjian jual beli selesai setelah
					pengguna memeriksa 'Random Box' sehingga hak untuk
					membatalkan pembelian terbatas, dan konfirmasi mengenai
					biaya yang ditanggung pengguna seperti biaya pengiriman.
				</span>
				<span className="sub">
					4. Tanda setuju atau tidak setuju terhadap Syarat dan
					Ketentuan Layanan serta konfirmasi poin 3 di atas (misalnya,
					dengan mengklik mouse).
				</span>
				<span className="sub">
					5. Permohonan pembelian barang dan konfirmasi terkait
					permohonan tersebut atau persetujuan terhadap konfirmasi
					dari Aplikasi.
				</span>
				<span className="sub">6. Pemilihan metode pembayaran.</span>
				<span>
					② Apabila Aplikasi perlu memberikan informasi pribadi
					pembeli kepada pihak ketiga,
				</span>
				<span className="sub">
					1. Pihak ketiga yang menerima informasi pribadi,
				</span>
				<span className="sub">
					2. Tujuan penggunaan informasi pribadi oleh pihak ketiga
					yang menerima informasi tersebut,
				</span>
				<span className="sub">
					3. Jenis informasi pribadi yang diberikan,
				</span>
				<span className="sub">
					4. Aplikasi harus memberitahukan kepada pembeli mengenai
					jangka waktu penyimpanan dan penggunaan informasi pribadi
					oleh pihak ketiga yang menerima informasi tersebut dan
					mendapatkan persetujuan pembeli. (Hal yang sama berlaku jika
					ada perubahan pada hal-hal yang telah disetujui.)
				</span>
				<span>
					③ Apabila Aplikasi menugaskan pihak ketiga untuk mengelola
					informasi pribadi pembeli,
				</span>
				<span className="sub">
					1. Pihak ketiga yang diberi wewenang untuk mengelola
					informasi pribadi,
				</span>
				<span className="sub">
					2. Aplikasi harus memberitahukan kepada pembeli mengenai
					pihak ketiga yang diberi wewenang untuk mengelola informasi
					pribadi dan jenis pekerjaan yang didelegasikan, serta
					mendapatkan persetujuan pembeli. (Hal yang sama berlaku jika
					ada perubahan pada hal-hal yang telah disetujui.) Namun,
					jika diperlukan untuk pelaksanaan perjanjian penyediaan
					layanan dan terkait dengan peningkatan kenyamanan pembeli,
					maka pemberitahuan dapat dilakukan melalui Kebijakan Privasi
					tanpa melalui prosedur pemberitahuan dan persetujuan.
				</span>
			</p>
			<p>
				<h2>10. Terbentuknya Perjanjian</h2>
			</p>
			<p>
				<span>
					① Aplikasi dapat menolak permintaan pembelian sebagaimana
					diatur dalam Pasal 9 dalam hal-hal sebagai berikut. Namun,
					dalam hal melakukan perjanjian dengan anak di bawah umur,
					Aplikasi wajib memberitahukan bahwa anak di bawah umur atau
					wali mereka dapat membatalkan perjanjian jika tidak
					memperoleh persetujuan dari wali.
				</span>
				<span className="sub">
					1. Apabila dalam permintaan pembelian terdapat kebohongan,
					kelalaian dalam pengisian, atau kesalahan penulisan.
				</span>
				<span className="sub">
					2. Apabila anak di bawah umur membeli barang yang dilarang
					berdasarkan Undang-Undung Perlindungan Anak dan
					Undang-Undang Informasi dan Transaksi Elektronik, seperti
					konten pornografi.
				</span>
				<span className="sub">
					3. Apabila secara teknis Aplikasi menilai bahwa menerima
					permintaan pembelian tersebut akan sangat mengganggu
					operasional Aplikasi.
				</span>
				<span>
					② Perjanjian dianggap telah terbentuk pada saat pengguna
					menerima konfirmasi penerimaan sebagaimana dimaksud dalam
					Pasal 12 ayat (1).
				</span>
				<span>
					③ Pemberitahuan penerimaan dari Aplikasi harus mencakup
					konfirmasi atas permintaan pembelian pengguna, ketersediaan
					barang, serta informasi mengenai perubahan atau pembatalan
					permintaan pembelian.
				</span>
			</p>
			<p>
				<h2>11. Cara Pembayaran</h2>
			</p>
			<p>
				<span>
					Pembayaran untuk barang atau jasa yang dibeli di Aplikasi
					dapat dilakukan dengan cara-cara sebagai berikut: Dengan
					ketentuan, Aplikasi tidak diperkenankan membebankan biaya
					tambahan dalam bentuk apapun atas pembayaran yang dilakukan
					oleh pengguna.
				</span>
				<span className="sub">
					1. Transfer atau setoran melalui berbagai jenis rekening
					seperti internet banking, mobile banking, atau virtual
					account.
				</span>
				<span className="sub">
					2. Pembayaran dengan berbagai jenis kartu seperti kartu
					prabayar, kartu debit, atau kartu kredit.
				</span>
				<span className="sub">
					3. Pembayaran melalui sistem pembayaran terintegrasi (QRIS).
				</span>
				<span className="sub">
					4. Pembayaran melalui metode digital (dompet digital).
				</span>
				<span className="sub">5. Transfer bank online</span>
				<span className="sub">
					6. Pembayaran dengan uang elektronik.
				</span>
				<span className="sub">7. Pembayaran saat barang diterima.</span>
				<span className="sub">
					8. Pembayaran dengan poin yang diberikan oleh Aplikasi.
				</span>
				<span className="sub">
					9. Pembayaran dengan voucher atau kupon yang bekerja sama
					dengan Aplikasi atau diakui oleh Aplikasi.
				</span>
				<span className="sub">
					10. Pembayaran dengan metode pembayaran elektronik lainnya.
				</span>
			</p>
			<p>
				<h2>
					12. Pemberitahuan Penerimaan, Perubahan, dan Pembatalan
					Permintaan Pembelian
				</h2>
			</p>
			<p>
				<span>
					① Aplikasi akan menyediakan “Random Box” kepada pengguna
					setelah pengguna mengajukan permintaan pembelian “Random
					Box”.
				</span>
				<span>
					② Jika pengguna yang telah mengkonfirmasi pembuatan “Random
					Box” menyadari adanya kesalahan dalam permintaannya,
					pengguna dapat segera meminta perubahan atau pembatalan
					permintaan pembelian. Aplikasi wajib memproses permintaan
					pembatalan dari pengguna yang diajukan dalam waktu 7 hari
					sejak tanggal pengguna mengkonfirmasi pembuatan “Random
					Box”, dan mengembalikan uang pembayaran. Namun, jika
					pengguna telah membuka “Random Box” dan melihat isi di
					dalamnya, maka perjanjian pembelian “Random Box” dianggap
					telah selesai dan tidak dapat dibatalkan.
				</span>
			</p>
			<p>
				<h2>13. Penyediaan Barang</h2>
			</p>
			<p>
				<span>
					① Aplikasi akan melakukan pengemasan dan tindakan lain yang
					diperlukan untuk mengirimkan barang spesifik yang telah
					dipilih oleh pengguna dari “Random Box” dalam jangka waktu 7
					hari sejak pengguna mengajukan permintaan pengiriman.
				</span>
				<span>
					② Aplikasi akan mencantumkan informasi mengenai metode
					pengiriman, biaya pengiriman yang ditanggung oleh pengguna,
					dan estimasi waktu pengiriman untuk barang spesifik yang
					telah dipilih oleh pengguna dari “Random Box”. Jika Aplikasi
					gagal mengirimkan barang dalam jangka waktu yang telah
					disepakati, Aplikasi wajib mengganti kerugian yang dialami
					oleh pengguna. Namun, ketentuan ini tidak berlaku jika
					Aplikasi dapat membuktikan bahwa keterlambatan pengiriman
					bukan disebabkan oleh kesalahan atau kelalaian dari pihak
					Aplikasi.
				</span>
				<span>
					③ Pengguna dapat mengajukan keluhan mengenai kerusakan
					barang kepada Aplikasi dalam jangka waktu 7 hari sejak
					tanggal pengiriman. Setelah jangka waktu 7 hari tersebut,
					keluhan tidak dapat diajukan.
				</span>
			</p>
			<p>
				<h2>14. Pengembalian Dana</h2>
			</p>
			<p>
				<span>
					① Jika pengguna membatalkan pembelian “Random Box” sesuai
					dengan ketentuan Pasal 12 ayat (2) sebelum membuka “Random
					Box”, Aplikasi akan mengembalikan uang pembayaran atau
					melakukan tindakan yang diperlukan untuk mengembalikan uang
					pembayaran dalam jangka waktu 7 hari kerja sejak tanggal
					pembatalan.
				</span>
				<span>
					② Poin yang diperoleh melalui program penukaran, acara, atau
					sejenisnya hanya dapat digunakan di dalam Aplikasi dan tidak
					dapat diuangkan.
				</span>
			</p>
			<p>
				<h2>15. Kupon</h2>
			</p>
			<p>
				<span>
					① Kupon merupakan hadiah dari Aplikasi yang dapat dibedakan
					berdasarkan target penerbitan, saluran distribusi, dan
					tujuan penggunaan. Detail mengenai jenis kupon diskon,
					jumlah diskon, cara penggunaan, masa berlaku, dan batasan
					penggunaan akan tertera pada kupon atau halaman Layanan.
				</span>
				<span>
					② Kupon tidak dapat diuangkan dan akan hangus jika masa
					berlakunya habis atau keanggotaan dibatalkan.
				</span>
				<span>
					③ Kupon tidak dapat dipindahkan kepada pihak lain dan tidak
					boleh digunakan atau diperoleh untuk tujuan yang tidak sah.
					Jika melanggar ketentuan ini, Aplikasi berhak membatalkan
					kupon atau membatasi keanggotaan pengguna.
				</span>
			</p>
			<p>
				<h2>16. Pembatalan Pesanan</h2>
			</p>
			<p>
				<span>
					① Pengguna yang telah melakukan pembelian “Random Box” di
					Aplikasi dapat membatalkan pesanan dalam jangka waktu 7
					(tujuh) hari sesuai dengan ketentuan Undang-Undang Informasi
					dan Transaksi Elektronik (UU ITE) tentang hak pembatalan.
					Namun, jika pengguna telah membuka “Random Box” dan
					mengetahui barang di dalamnya, maka pembatalan pesanan tidak
					dapat dilakukan karena perjanjian pembelian “Random Box”
					dianggap telah selesai.
				</span>
				<span>
					② Pengguna yang telah menerima barang spesifik yang dipilih
					dari “Random Box” tidak dapat melakukan pengembalian atau
					pertukaran dalam hal-hal sebagai berikut:
				</span>
				<span className="sub">
					1. Jika barang hilang atau rusak karena kesalahan pengguna
					(Namun, pengguna masih dapat membatalkan perjanjian jika
					kerusakan hanya terjadi pada kemasan untuk memeriksa isi
					barang).
				</span>
				<span className="sub">
					2. Jika nilai barang menurun secara signifikan karena telah
					digunakan oleh pengguna.
				</span>
				<span className="sub">
					3. Jika nilai barang menurun secara signifikan karena faktor
					waktu sehingga sulit untuk dijual kembali.
				</span>
				<span className="sub">
					4. Jika barang tersebut dapat direproduksi dan kemasan asli
					dari barang tersebut telah rusak.
				</span>
				<span>
					③ Dalam hal poin 2 sampai 4 di atas, jika Aplikasi tidak
					memberikan informasi yang jelas kepada konsumen mengenai
					batasan pengembalian atau pertukaran, atau tidak menyediakan
					sampel untuk dicoba, maka pengguna tetap dapat melakukan
					pengembalian atau pertukaran.
				</span>
			</p>
			<p>
				<h2>17. Dampak Pembatalan Pesanan</h2>
			</p>
			<p>
				<span>
					① Jika Aplikasi menerima permintaan pembatalan pembelian
					“Random Box” dari pengguna dalam jangka waktu 7 hari sejak
					tanggal pembuatan “Random Box”, Aplikasi akan mengembalikan
					uang pembayaran yang telah diterima dalam jangka waktu 7
					hari kerja.
				</span>
				<span>
					② Dalam proses pengembalian uang, jika pengguna melakukan
					pembayaran menggunakan kartu kredit atau uang elektronik,
					Aplikasi akan segera meminta kepada penyedia layanan
					pembayaran untuk menghentikan atau membatalkan tagihan
					pembayaran.{" "}
				</span>
				<span>
					③ Aplikasi tidak akan membebankan biaya penalti atau meminta
					ganti rugi kepada pengguna atas pembatalan perjanjian.
					Namun, jika isi barang spesifik yang telah dipilih dari
					“Random Box” tidak sesuai dengan informasi yang tertera pada
					iklan atau promosi, dan pengguna meminta pengembalian atau
					pertukaran barang, maka Aplikasi akan menanggung biaya
					pengiriman kembali barang.
				</span>
				<span>
					④ Jika pengguna telah membayar biaya pengiriman saat
					menerima barang spesifik yang telah dipilih dari “Random
					Box”, Aplikasi akan memberikan informasi yang jelas kepada
					pengguna mengenai siapa yang akan menanggung biaya
					pengiriman jika terjadi pengembalian atau pertukaran.
				</span>
				<span>
					⑤ Jika kupon termasuk dalam transaksi yang dibatalkan, kupon
					tersebut hanya akan dikembalikan jika masa berlaku kupon
					belum habis pada saat pengguna mengajukan permohonan
					pembatalan.
				</span>
			</p>
			<p>
				<h2>18. Perlindungan Data Pribadi</h2>
			</p>
			<p>
				<span>
					① Aplikasi hanya akan mengumpulkan data pribadi pengguna
					dalam jumlah seminimal mungkin yang diperlukan untuk
					menyediakan layanan.
				</span>
				<span>
					② Aplikasi tidak akan mengumpulkan informasi yang diperlukan
					untuk perjanjian pembelian sebelum pengguna mendaftar.
					Namun, pengecualian berlaku jika verifikasi identitas
					diperlukan sebelum perjanjian pembelian untuk memenuhi
					kewajiban hukum, dan dalam hal ini hanya informasi pribadi
					tertentu yang minimal akan dikumpulkan.
				</span>
				<span>
					③ Aplikasi akan memberitahukan tujuan pengumpulan dan
					memperoleh persetujuan dari pengguna sebelum mengumpulkan
					dan menggunakan data pribadi pengguna.
				</span>
				<span>
					④ Aplikasi tidak dapat menggunakan data pribadi pengguna
					untuk tujuan selain yang telah disetujui, dan jika tujuan
					penggunaan baru muncul atau jika data tersebut akan
					diberikan kepada pihak ketiga, Aplikasi akan memberitahukan
					tujuan tersebut kepada pengguna dan memperoleh
					persetujuannya. Pengecualian berlaku jika ada ketentuan lain
					dalam peraturan perundang-undangan.
				</span>
				<span>
					⑤ Jika Aplikasi harus memperoleh persetujuan pengguna sesuai
					dengan ayat (2) dan (3), Aplikasi harus terlebih dahulu
					menyebutkan secara jelas identitas penanggung jawab
					pengelolaan data pribadi (jabatan, nama, nomor telepon, dan
					informasi kontak lainnya), tujuan pengumpulan dan penggunaan
					informasi, serta informasi terkait penyediaan informasi
					kepada pihak ketiga (pihak yang menerima informasi, tujuan
					penyediaan, dan jenis informasi yang akan diberikan).
					Pengguna dapat mencabut persetujuan ini kapan saja.
				</span>
				<span>
					⑥ Pengguna dapat kapan saja meminta untuk melihat dan
					memperbaiki data pribadinya yang dimiliki oleh Aplikasi, dan
					Aplikasi wajib segera mengambil tindakan yang diperlukan.
					Jika pengguna meminta perbaikan kesalahan, Aplikasi tidak
					akan menggunakan data pribadi tersebut sampai kesalahan
					tersebut diperbaiki.
				</span>
				<span>
					⑦ Aplikasi harus membatasi jumlah orang yang menangani data
					pribadi pengguna untuk melindungi data pribadi pengguna, dan
					Aplikasi bertanggung jawab penuh atas segala kerugian yang
					dialami pengguna akibat kehilangan, pencurian, kebocoran,
					penyediaan kepada pihak ketiga tanpa persetujuan, atau
					perubahan data pribadi pengguna, termasuk kartu kredit dan
					rekening bank.
				</span>
				<span>
					⑧ Aplikasi atau pihak ketiga yang menerima data pribadi dari
					Aplikasi harus segera menghapus data pribadi setelah tujuan
					pengumpulan atau penyediaan data tersebut tercapai.
				</span>
				<span>
					⑨ Aplikasi tidak akan menandai kotak persetujuan untuk
					pengumpulan, penggunaan, dan penyediaan data pribadi secara
					otomatis. Selain itu, Aplikasi akan secara spesifik
					menyebutkan layanan apa saja yang akan dibatasi jika
					pengguna menolak untuk memberikan persetujuan atas
					pengumpulan, penggunaan, dan penyediaan data pribadinya.
				</span>
			</p>
			<p>
				<h2>19. Kewajiban Aplikasi</h2>
			</p>
			<p>
				<span>
					① Aplikasi tidak akan melakukan tindakan yang dilarang oleh
					undang-undang dan perjanjian ini, dan akan berupaya sebaik
					mungkin untuk menyediakan barang dan jasa secara
					berkelanjutan dan stabil sesuai dengan ketentuan dalam
					perjanjian ini.
				</span>
				<span>
					② Aplikasi wajib memiliki sistem keamanan untuk melindungi
					data pribadi pengguna (termasuk informasi kredit) agar
					pengguna dapat menggunakan layanan internet dengan aman.
				</span>
				<span>
					③ Aplikasi tidak akan mengirimkan email promosi yang
					bertujuan komersial tanpa persetujuan pengguna.
				</span>
			</p>
			<p>
				<h2>20. Kewajiban Terhadap ID Anggota dan Kata Sandi</h2>
			</p>
			<p>
				<span>
					① Kecuali dalam hal yang diatur dalam Pasal 18, tanggung
					jawab atas pengelolaan ID dan kata sandi sepenuhnya berada
					pada anggota.
				</span>
				<span>
					② Anggota tidak boleh mengungkapkan atau membiarkan pihak
					ketiga menggunakan ID dan kata sandinya.
				</span>
				<span>
					③ Jika anggota mengetahui bahwa ID dan kata sandinya telah
					dicuri atau digunakan oleh pihak ketiga, anggota harus
					segera memberi tahu Aplikasi dan mengikuti petunjuk yang
					diberikan oleh Aplikasi.
				</span>
			</p>
			<p>
				<h2>21. Kewajiban Pengguna</h2>
			</p>
			<p>
				<span>
					① Pengguna Aplikasi dapat dikenakan pembatasan penggunaan
					jika melakukan tindakan berikut:
				</span>
				<span className="sub">
					1. Mendaftarkan informasi palsu saat mendaftar atau
					melakukan perubahan.
				</span>
				<span className="sub">
					2. Menyalahgunakan informasi orang lain.
				</span>
				<span className="sub">
					3. Mengubah informasi yang telah dipublikasikan oleh
					Aplikasi.
				</span>
				<span className="sub">
					4. Mengirim atau mengunggah informasi selain informasi yang
					ditentukan oleh Aplikasi (seperti program komputer).
				</span>
				<span className="sub">
					5. Melanggar hak cipta atau hak kekayaan intelektual lainnya
					milik Aplikasi atau pihak ketiga lainnya.
				</span>
				<span className="sub">
					6. Menurunkan reputasi Aplikasi atau pihak ketiga lainnya
					atau mengganggu kegiatan bisnis mereka.
				</span>
				<span className="sub">
					7. Mempublikasikan atau mengunggah pesan, gambar, atau suara
					yang tidak senonoh atau mengandung kekerasan di Aplikasi.
				</span>
				<span className="sub">
					8. Jika anggota mengganggu ketertiban transaksi Aplikasi
					dengan tujuan untuk dijual kembali:
				</span>
				<span className="small">
					1) Merupakan tindakan menjual produk dari Aplikasi tanpa
					secara langsung menerima produk tersebut dan melalui saluran
					penjualan di luar Aplikasi.
				</span>
				<span className="small">
					2) Mengirim melalui Aplikasi setelah menyelesaikan kontrak
					penjualan melalui platform lain.
				</span>
			</p>
			<p>
				<h2>22. Hak Cipta dan Pembatasan Penggunaan</h2>
			</p>
			<p>
				<span>
					① Hak cipta dan hak kekayaan intelektual lainnya atas karya
					yang dibuat oleh Aplikasi adalah milik Aplikasi.
				</span>
				<span>
					② Pengguna tidak diperkenankan untuk memperbanyak,
					mengirimkan, menerbitkan, mendistribusikan, menyiarkan, atau
					menggunakan dengan cara lain untuk tujuan komersial, atau
					mengizinkan pihak ketiga untuk menggunakan informasi yang
					diperoleh dari Aplikasi dan merupakan hak kekayaan
					intelektual Aplikasi tanpa persetujuan tertulis sebelumnya
					dari Aplikasi.
				</span>
				<span>
					③ Jika Aplikasi menggunakan hak cipta yang telah diserahkan
					kepada pengguna berdasarkan perjanjian, maka Aplikasi wajib
					memberitahukan hal tersebut kepada pengguna.
				</span>
			</p>
			<p>
				<h2>23. Penyelesaian Sengketa</h2>
			</p>
			<p>
				<span>
					① Aplikasi membentuk dan mengoperasikan mekanisme penanganan
					keluhan untuk menanggapi pendapat dan keluhan yang sah dari
					pengguna serta memberikan kompensasi atas kerugian yang
					dialami.
				</span>
				<span>
					②Aplikasi akan memprioritaskan penanganan keluhan dan
					pendapat yang disampaikan oleh pengguna. Namun, jika
					penanganan secara cepat tidak memungkinkan, Aplikasi akan
					segera memberitahukan alasannya dan jadwal penanganan kepada
					pengguna.
				</span>
				<span>
					③ Dalam hal terjadi sengketa terkait transaksi elektronik
					antara Aplikasi dan pengguna, dan pengguna mengajukan
					permohonan penyelesaian sengketa, maka penyelesaian dapat
					dilakukan melalui Badan Perlindungan Konsumen Nasional
					(BPKN), Badan Arbitrase Nasional Indonesia (BANI), atau
					lembaga penyelesaian sengketa konsumen online.
				</span>
			</p>
			<p>
				<h2>24. Yurisdiksi dan Hukum yang Berlaku</h2>
			</p>
			<p>
				<span>
					① Gugatan atas perselisihan transaksi elektronik antara
					Aplikasi dan pengguna dapat diajukan ke pengadilan yang
					wilayah hukumnya meliputi tempat tinggal pengguna atau
					tempat pelaksanaan perjanjian pada saat gugatan diajukan.
					Namun, jika tempat tinggal atau tempat tinggal sementara
					pengguna tidak jelas pada saat gugatan diajukan, atau jika
					pengguna adalah warga negara asing, maka gugatan diajukan
					sesuai dengan ketentuan hukum perdata mengenai yurisdiksi
					pengadilan.
				</span>
				<span>
					② Perselisihan transaksi elektronik antara Aplikasi dan
					pengguna akan diselesaikan berdasarkan hukum Indonesia.
				</span>
			</p>
			<p>
				<h2>25. Pembatasan Penggunaan Layanan</h2>
			</p>
			<p>
				<span>
					① Pengguna dilarang melakukan tindakan yang melanggar
					kewajiban pengguna sebagaimana diatur dalam Pasal 21, dan
					jika melakukan tindakan tersebut, Aplikasi dapat mengambil
					tindakan pembatasan penggunaan layanan, termasuk menghapus
					informasi terkait (foto, video, dll.) dan tindakan
					pembatasan lainnya.
				</span>
				<span>
					{" "}
					className="sub" 1. Pembatasan penggunaan akun: membatasi
					penggunaan akun anggota untuk jangka waktu tertentu atau
					secara permanen.
				</span>
				<span>
					② Jika pembatasan penggunaan sebagaimana dimaksud pada ayat
					(1) di atas adalah sah, Aplikasi tidak bertanggung jawab
					atas kerugian yang diderita pengguna akibat pembatasan
					penggunaan tersebut, termasuk kompensasi atau pengembalian
					produk.
				</span>
				<span>
					③ Aplikasi dapat menghentikan layanan pada akun yang
					bersangkutan sampai penyelesaian investigasi atas
					alasan-alasan berikut:
				</span>
				<span className="sub">
					1. Pengguna melakukan tindakan yang mengganggu penggunaan
					layanan pengguna lain dengan tujuan jahat.
				</span>
				<span className="sub">
					2. Pengguna mengeksploitasi bug dalam layanan atau melakukan
					penipuan dalam proses pembayaran untuk mendapatkan
					keuntungan yang tidak sah.
				</span>
				<span className="sub">
					3. Pengguna diduga menggunakan program ilegal atau terlibat
					dalam aktivitas ilegal lainnya.
				</span>
			</p>
			<p>
				<h2>26. Tanggung Jawab atas Kerugian</h2>
			</p>
			<p>
				<span>
					① Jika pengguna melanggar ketentuan dalam perjanjian ini
					sehingga menimbulkan kerugian pada Aplikasi, maka pengguna
					wajib mengganti kerugian tersebut. Namun, jika kerugian
					tersebut disebabkan oleh kesalahan Aplikasi, maka Aplikasi
					yang bertanggung jawab.
				</span>
				<span>
					② Jika pengguna melakukan tindakan melanggar hukum atau
					melanggar perjanjian ini sehingga Aplikasi menerima tuntutan
					ganti rugi atau gugatan hukum dari pihak ketiga, maka
					pengguna wajib membebaskan Aplikasi dari segala tuntutan dan
					biaya yang timbul. Jika Aplikasi tidak dapat dibebaskan dari
					tuntutan tersebut, maka pengguna wajib mengganti kerugian
					yang diderita Aplikasi. Namun, jika kerugian tersebut
					disebabkan oleh kesalahan Aplikasi, maka Aplikasi yang
					bertanggung jawab.
				</span>
			</p>
			<p>
				<h2>27. Penafian Tanggung Jawab</h2>
			</p>
			<p>
				<span>
					① Aplikasi tidak bertanggung jawab atas ketidakmampuan untuk
					menyediakan layanan yang disebabkan oleh bencana alam,
					perang, kerusuhan, keadaan darurat, cacat teknis yang tidak
					dapat diatasi dengan teknologi saat ini, atau keadaan
					memaksa lainnya.
				</span>
				<span>
					② Aplikasi tidak bertanggung jawab atas kerugian yang
					disebabkan oleh pemeliharaan, penggantian, pemeriksaan,
					perbaikan, atau pekerjaan serupa pada fasilitas layanan.
					Namun, ketentuan ini tidak berlaku jika kerugian tersebut
					disebabkan oleh kelalaian atau kesengajaan dari Aplikasi.
				</span>
				<span>
					③ Aplikasi tidak bertanggung jawab atas gangguan penggunaan
					layanan yang disebabkan oleh kelalaian atau kesengajaan
					pengguna. Namun, ketentuan ini tidak berlaku jika pengguna
					memiliki alasan yang tidak dapat dihindari atau alasan yang
					sah.
				</span>
				<span>
					④ Aplikasi tidak berkewajiban untuk ikut campur dalam
					transaksi atau perselisihan yang terjadi antara pengguna
					dengan pengguna lain atau pihak ketiga melalui layanan ini,
					dan tidak bertanggung jawab atas kerugian yang timbul
					karenanya.
				</span>
				<span>
					⑤ Sebagian layanan yang disediakan mungkin menggunakan
					layanan yang disediakan oleh pihak ketiga lain, dan Aplikasi
					tidak bertanggung jawab atas kerugian yang timbul dari
					layanan yang disediakan oleh pihak ketiga tersebut, kecuali
					jika kerugian tersebut disebabkan oleh kelalaian berat atau
					kesengajaan dari Aplikasi.
				</span>
				<span>
					⑥ Aplikasi tidak bertanggung jawab atas kerugian yang
					dialami pengguna terkait dengan penggunaan layanan gratis.
					Namun, ketentuan ini tidak berlaku jika kerugian tersebut
					disebabkan oleh kelalaian berat atau kesengajaan dari
					Aplikasi.
				</span>
				<span>
					⑦ Aplikasi tidak bertanggung jawab jika pengguna tidak
					memperoleh atau kehilangan keuntungan yang diharapkan dari
					penggunaan layanan.
				</span>
				<span>
					⑧ Aplikasi tidak bertanggung jawab atas kehilangan
					peringkat, poin, atau item lainnya dalam layanan pengguna.
					Namun, ketentuan ini tidak berlaku jika kerugian tersebut
					disebabkan oleh kelalaian atau kesengajaan dari Aplikasi.
					Selain itu, Aplikasi dapat menarik kembali atau menukar
					item, koin, atau kupon yang diberikan kepada pengguna secara
					tidak tepat akibat kesalahan server atau alasan lainnya.
				</span>
				<span>
					⑨ Aplikasi tidak bertanggung jawab atas pembayaran oleh
					pihak ketiga yang disebabkan oleh kelalaian pengguna dalam
					mengelola kata sandi perangkat seluler atau kata sandi yang
					disediakan oleh penyedia layanan pasar. Namun, ketentuan ini
					tidak berlaku jika kerugian tersebut disebabkan oleh
					kelalaian atau kesengajaan dari Aplikasi
				</span>
				<span>
					⑩ Aplikasi tidak bertanggung jawab jika pengguna tidak dapat
					menggunakan seluruh atau sebagian fitur konten karena
					perubahan perangkat seluler, nomor telepon seluler, versi
					sistem operasi, roaming internasional, atau perubahan
					penyedia layanan.
				</span>
				<span>
					⑪ Aplikasi tidak bertanggung jawab jika pengguna menghapus
					konten atau informasi akun yang disediakan oleh Aplikasi.
					Namun, ketentuan ini tidak berlaku jika penghapusan tersebut
					disebabkan oleh kelalaian atau kesengajaan dari Aplikasi.
				</span>
			</p>
			<p>
				<span>Perusahaan: PT Bomulsun HBiz Indonesia</span>
			</p>
			<p>
				<span>
					Alamat: The Smith Office Alam Sutera unit 0709,
					Desa/Kelurahan Kunciran, Kec. Pinang, Kota Tangerang,
					Provinsi Banten 15144
				</span>
			</p>
			<p>
				<span>NOMOR INDUK BERUSAHA (NIB): 1503240242224</span>
			</p>
			<p>
				<span>CEO: Hong Booyong</span>
			</p>
			<p>
				<span>Tanggal berlaku: 2025. 01. 03.</span>
			</p>
		</Container>
	);
};
