// 이용 내역
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom, UserAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import HELP003002 from "./HELP003002";
import HELP003003 from "./HELP003003";

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	const defaultTab = location.state ? location.state.data : 0;
	const userState = useRecoilValue(UserAtom);
	// 황성화 탭 인덱스
	const [activeTab, setActiveTab] = useState<number>(defaultTab);

	return (
		<>
			<Page
				title="Riwayat Penggunaan"
				contents={
					<>
						<TabMenu
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabs={[
								{
									index: 0,
									name: "Peti Harta Karun",
									content: <HELP003002 />,
								},
								{
									index: 1,
									name: "Point",
									content: <HELP003003 />,
								},
							]}
						/>
					</>
				}
				badge="Beli Peti"
				badgeAction={() => navigate("/POIN001001")}
			/>
		</>
	);
};
