import { atom } from "recoil";

// 상자 가격 기준
const GoldBoxPrice = atom({
	key: "GoldBoxPrice",
	default: 50000,
	// default: 1000, // test
});
// 배송비 가격 기준
const DeliveryPrice = atom({
	key: "DeliveryPrice",
	default: 15000,
	// default: 1500, // test
});
// 분해 반환 포인트 비율
const DecompositionRate = atom({
	key: "DecompositionRate",
	default: 0.5,
});
// 뉴스룸 등록 가격 기준
const NewsPrice = atom({
	key: "NewsPrice",
	default: 300000,
});
// 뉴스룸 내 강조 가격 기준
const EmphasizePrice = atom({
	key: "EmphasizePrice",
	default: 10000000,
});
const LoadingAtom = atom({
	key: "isLoading",
	default: false,
});
const IsLoginAtom = atom({
	key: "isLogin",
	default: false,
});
const IsOverlapAtom = atom({
	key: "isOverlap",
	default: false,
});
const UserAtom = atom({
	key: "UserAtom",
	default: {
		mmIdx: 0,
		joinChannelCode: "", // 가입 경로 코드
		name: "",
		phoneNumber: "",
		email: "",
		pw: "",
		nick: "",
		profileImageUrl: "",
		stateCode: "", // 회원 상태 코드
		gradeCode: "", // 회원 등급 코드
		userExp: "", // 경험치
		freePoint: 0,
		useAgreeYn: "", // 이용 약관 동의
		financialAgreeYn: "", // 전자금융 거래 이용 약관 동의
		privacyAgreeYn: "", // 개인정보 수집 이용 동의
		mailSendYn: 0,
		smsSendYn: 1,
		ageOverYn: "만 14세이상 여부 확인",
		joinDt: "회원 가입 일시",
		joinIp: "회원 가입 IP",
		modDt: "회원 정보 수정 일시",
		modIp: "회원 정보 수정 IP",
		leaveDt: "회원 탈퇴 일시",
		leaveIp: "회원 탈퇴 IP",
		goldboxCnt: 0,
		loginType: "로그인 타입 키워드(apple)",
		loginTypeCode: "로그인 타입 코드(4 = apple)",
		uid: "로그인 서비스 제공 일련번호",
		referralCode: "추천 코드",
	},
});
const NickChangeCount = atom({
	key: "NickChangeCount",
	default: 0,
});
// 배송완료 처리를 위한 atom
const DeliveryRequest = atom({
	key: "DeliveryRequest",
	default: false,
});

export {
	GoldBoxPrice,
	DeliveryPrice,
	DecompositionRate,
	NewsPrice,
	EmphasizePrice,
	LoadingAtom,
	IsLoginAtom,
	IsOverlapAtom,
	UserAtom,
	NickChangeCount,
	DeliveryRequest,
};
