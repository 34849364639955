// 배송 상세정보
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../libs/api';
import styled from 'styled-components';
import Util from '../Store/Util';
import { useSetRecoilState } from 'recoil';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';

import * as S from '../Styles/Style';
import Page from '../Components/Page';
import Modal from '../Components/Modal';

import { ReactComponent as Copy } from '../Images/copy.svg';
import { ReactComponent as Chevron } from '../Images/chevron.svg';
import { ReactComponent as DeliveryStatus } from '../Images/deliveryStatus.svg';
import { ReactComponent as DeliveryStatus1 } from '../Images/deliveryStatus_1.svg';
import { ReactComponent as DeliveryStatus2 } from '../Images/deliveryStatus_2.svg';
import { ReactComponent as DeliveryStatus3 } from '../Images/deliveryStatus_3.svg';
import { ReactComponent as DeliveryStatus4 } from '../Images/deliveryStatus_4.svg';
import { config } from '../config/config';

const InfoBox = styled(S.borderBox)`
	padding: 1.6rem;
	& > div {
		.deliveryStatusIcon {
			width: 3rem;
			height: 3rem;
			margin-right: 0.6rem;
		}
		.copyIcon {
			/* width: 1.464rem; */
			/* height: 1.5rem; */
			margin-left: 0.4rem;
		}
		& > p {
			margin-right: 1rem;
		}
	}
`;
const DeliveryStatusArea = styled(S.flexRowBetween)`
	align-items: flex-start;
	margin-top: 1.6rem;
	.chevron {
		margin-top: 0.8rem;
	}
`;
const DeliveryStatusItem = styled(S.flexRowCenter)`
	flex-direction: column;
	svg {
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.6rem;
	}
`;

export default () => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const location = useLocation();
	const deliveryItem = location.state ? location.state.item : '';
	const setOpenToast = useSetRecoilState(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	// 배송취소 팝업 생성
	const [isOpenCancel, setOpenCancel] = useState(false);
	// 배송상태 구분
	const [deliveryState, setDeliveryState] = useState<number>(1);

	useEffect(() => {
		console.log('deliveryItem : ', deliveryItem.deliveryState);
		const state = () => {
			switch (deliveryItem.deliveryState) {
				case '00': // 배송요청
					return 0;
				case '10': // 배송준비
					return 1;
				case '20': // 배송중
					return 2;
				case '30': // 배송완료
					return 3;
				case '-10': // 배송취소
					return -1;
			}
		};
		setDeliveryState(state());
	}, []);

	const statueData = [
		{ text: 'Pesanan\nDiterima' },
		{ text: 'Persiapan\nPengiriman' },
		{ text: 'Dalam\nPengiriman' },
		{ text: 'Pengiriman\nSelesai' },
	];
	// 배송상태 아이콘
	const statusIcon = (index) => {
		const fill = index == deliveryState ? S.color.primary : S.color.gray500;

		switch (index) {
			case 0:
				return <DeliveryStatus1 fill={fill} />;
			case 1:
				return <DeliveryStatus2 fill={fill} />;
			case 2:
				return <DeliveryStatus3 fill={fill} />;
			case 3:
				return <DeliveryStatus4 fill={fill} />;
		}
	};
	// 결제수단 구분
	const paymentType = (paymentType) => {
		switch (paymentType) {
			case '1':
				return 'point';
			default:
				return '-';
		}
	};
	// 배송 취소
	const deliveryCancel = async (pdmIdx) => {
		await Api.patch(`/member/info/memberPresentDeliveryUpdate`, {
			pdmIdx: pdmIdx,
		}).then((res) => {
			console.log(res);
			setOpenCancel(false);
			navigate(-1);
		});
	};

	return (
		<>
			<Page
				title="Detail Pesanan"
				contents={
					<S.pageContainer>
						<section>
							<S.treasureBox>
								<div>
									<S.heightImg src={imgUrl + deliveryItem.imageUrl} />
								</div>
								<p>
									<S.subhead_3 $ellipsis={1}>{deliveryItem.name}</S.subhead_3>
								</p>
							</S.treasureBox>
							<h1>
								<S.subhead_2>Status pengiriman</S.subhead_2>
							</h1>
							<InfoBox>
								{deliveryState == -1 ? (
									<S.flexRowCenter>
										<DeliveryStatus class="deliveryStatusIcon" />
										<S.button_S color={S.color.gray800}>Batalkan Pengiriman</S.button_S>
									</S.flexRowCenter>
								) : (
									<>
										<S.flexRowStart>
											<S.headline_5
												color={S.color.gray800}
												style={{
													marginRight: '0.6rem',
												}}>
												NinjaVan
											</S.headline_5>
											<S.subhead_4 color={S.color.gray800}>
												{deliveryItem.invoiceNumber}
											</S.subhead_4>
											<Copy
												class="copyIcon"
												onClick={() => {
													navigator.clipboard.writeText(deliveryItem.invoiceNumber);
													// 복사 완료
													setToastType(0);
													setToastContents('Berhasil disalin.');
													setOpenToast(true);
												}}
											/>
										</S.flexRowStart>
										<DeliveryStatusArea>
											{statueData.map((item, index) => (
												<>
													{index != 0 && <Chevron fill={S.color.gray300} class="chevron" />}
													<DeliveryStatusItem>
														{statusIcon(index)}
														<S.bodyText_XS
															color={
																index == deliveryState
																	? S.color.primary
																	: S.color.gray600
															}
															style={{
																textAlign: 'center',
															}}>
															{item.text}
														</S.bodyText_XS>
													</DeliveryStatusItem>
												</>
											))}
										</DeliveryStatusArea>
									</>
								)}
							</InfoBox>
							<h1>
								<S.subhead_2>Informasi pengiriman</S.subhead_2>
							</h1>
							<InfoBox>
								<S.flexRowStart
									style={{
										marginBottom: '0.8rem',
										alignItems: 'flex-start',
									}}>
									<p>
										<S.subhead_4 color={S.color.gray500}>Alamat</S.subhead_4>
									</p>
									<div>
										<p>
											<S.subhead_4 color={S.color.gray800}>Home</S.subhead_4>
										</p>
										<S.bodyText_S color={S.color.gray800}>
											{deliveryItem.address1} {deliveryItem.address2} {deliveryItem.postcode}
										</S.bodyText_S>
									</div>
								</S.flexRowStart>
								<S.flexRowStart>
									<p>
										<S.subhead_4 color={S.color.gray500}>Kontak</S.subhead_4>
									</p>
									<S.bodyText_S>{deliveryItem.tel}</S.bodyText_S>
								</S.flexRowStart>
								<S.bar $size={'1.2rem'} />
								<div>
									<p style={{ marginBottom: '0.6rem' }}>
										<S.subhead_4 color={S.color.gray600}>Permintaan khusus pengiriman</S.subhead_4>
									</p>
									<S.bodyText_S color={S.color.gray800}>
										{deliveryItem.deliveryMessage ?? ' '}
									</S.bodyText_S>
								</div>
							</InfoBox>
							<h1>
								<S.flexRowBetween>
									<S.subhead_2>Informasi Pembayaran</S.subhead_2>
									{/* 배송 취소 상태면 버튼 삭제 */}
									{deliveryState != -1 && (
										<S.borderBadge $large onClick={() => setOpenCancel(true)}>
											<S.bodyText_XS>Pembatalan pengiriman</S.bodyText_XS>
										</S.borderBadge>
									)}
								</S.flexRowBetween>
							</h1>
							<InfoBox>
								<S.flexRowBetween>
									<S.subhead_4 color={S.color.gray600}>Total Pembayaran</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										+ {Util.addComma(deliveryItem.deliveryPrice)} IDR
									</S.bodyText_S>
								</S.flexRowBetween>
								<S.flexRowBetween style={{ margin: '0.8rem 0' }}>
									<S.subhead_4 color={S.color.gray600}>Poin yang Digunakan</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										- {Util.addComma(deliveryItem.usePoint)} P
									</S.bodyText_S>
								</S.flexRowBetween>
								<S.flexRowBetween>
									<S.subhead_4 color={S.color.gray600}>Metode Pembayaran</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										{paymentType(deliveryItem.paymentType)}
									</S.bodyText_S>
								</S.flexRowBetween>
							</InfoBox>
						</section>
						<section>
							<h2>
								<S.subhead_3 color={S.color.gray800}>
									Informasi Pembatalan, Penukaran, dan Pengembalian Barang
								</S.subhead_3>
							</h2>
							<S.textList $gray>
								<li>
									<S.bodyText_S>
										Anda dapat membatalkan pesanan selama status pesanan masih dalam proses
										pengiriman. Setelah barang dikirim, pembatalan tidak dapat dilakukan.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Jika sudah lewat 1 jam setelah pembayaran ongkos kirim namun status pesanan
										masih tertera "dalam proses pembayaran", mohon hubungi layanan pelanggan kami.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Poin yang telah Anda gunakan untuk pembayaran akan dikembalikan secara otomatis
										setelah pesanan dibatalkan. Waktu yang dibutuhkan untuk pengembalian dana
										tergantung pada metode pembayaran yang Anda pilih dan biasanya memakan waktu 3-7
										hari kerja.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Barang yang rusak atau tidak sesuai dengan pesanan dapat ditukarkan atau
										dikembalikan. Silakan lampirkan foto atau video bukti kerusakan saat menghubungi
										layanan pelanggan kami dalam waktu 7 hari sejak barang diterima.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Barang yang akan dikembalikan harus dalam kondisi lengkap dan tidak rusak.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Barang yang sudah terpakai, rusak, atau kotor tidak dapat ditukarkan atau
										dikembalikan.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
					</S.pageContainer>
				}
				backTo={'/MYME002001'}
				backToState={{ data: 1 }}
			/>
			{/* 배송 취소 확인 */}
			<Modal
				isOpen={isOpenCancel}
				contents={
					<>
						<h2>
							<S.headline_4>Batalkan pengiriman</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Batalkan permintaan pengiriman?{'\n'}Ongkos kirim: akan dikembalikan ke metode pembayaran
							Anda dalam waktu maksimal 7 hari kerja.{'\n'}Poin: pengembalian instan
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenCancel(false)}
				buttonTextR="Konfirmasi"
				buttonActionR={() => deliveryCancel(deliveryItem.pdmIdx)}
			/>
		</>
	);
};

