import React, { useState } from "react";
import axios from "axios";
import { config } from "../config/config";

declare global {
	interface Window {
		ReactNativeWebView: {
			postMessage: (value: string) => void;
		};
	}
}

const apiUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_API_URL;
// const apiUrl = "http://172.30.1.70:3001";

const postMessage = () => {
	localStorage.removeItem("token");
	if (typeof window == undefined || !window?.ReactNativeWebView) {
		return;
	} else {
		window.ReactNativeWebView?.postMessage(
			JSON.stringify({
				type: "OVERLAP",
			})
		);
	}
};

const get = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	console.log(apiUrl, "APIURL@@@@");
	console.log(process.env, "APIURL@@@@");
	try {
		result = await axios.get(apiUrl + url, {
			params: params,
			headers: { Authorization: token },
		});
	} catch (e) {
		if (e.response?.data !== undefined) {
			if (e.response.data.error == -999) {
				postMessage();
			} else {
				result = e;
			}
		}
	}
	return result;
};
const post = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.post(apiUrl + url, params, {
			headers: { Authorization: token },
		});
	} catch (e) {
		if (e.response?.data !== undefined) {
			if (e.response.data.error == -999) {
				postMessage();
			} else {
				result = e;
			}
		}
	}
	return result;
};
const patch = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.patch(apiUrl + url, params, {
			headers: {
				Authorization: token,
				"Content-Type": `application/json;charset=UTF-8`,
				Accept: "application/json",
				// 추가
				"Access-Control-Allow-Origin": [
					`https://api.service.go-pang.com`,
					"https://service.go-pang.com",
				],
				"Access-Control-Allow-Credentials": true,
			},
			// headers: { Authorization: token },
			// headers: {
			// 	Authorization: token,
			// 	"Content-Type": "multipart/form-data",
			// },
		});
	} catch (e) {
		if (e.response?.data !== undefined) {
			if (e.response.data.error == -999) {
				postMessage();
			} else {
				result = e;
			}
		}
	}
	return result;
};
const del = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.delete(apiUrl + url, {
			headers: { Authorization: token },
		});
	} catch (e) {
		if (e.response?.data !== undefined) {
			if (e.response.data.error == -999) {
				postMessage();
			} else {
				result = e;
			}
		}
	}
	return result;
};
export default { get, post, patch, del };
