// 약관
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import HELP007002 from "./HELP007002";
import HELP007003 from "./HELP007003";

export default () => {
	// 황성화 탭 인덱스
	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<>
			<Page
				title="Syarat dan Ketentuan"
				contents={
					<>
						<TabMenu
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabs={[
								{
									index: 0,
									name: "Syarat Penggunaan",
									content: <HELP007002 />,
								},
								{
									index: 1,
									name: "Kebijakan Pribadi",
									content: <HELP007003 />,
								},
							]}
						/>
					</>
				}
			/>
		</>
	);
};
