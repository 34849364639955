// 로그인
import React, { useEffect, useState } from "react";
import Api from "../libs/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { SetProfileAtom } from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Modal from "../Components/Modal";

import logo from "../Images/logo.svg";
import google from "../Images/login_google.png";
import whatsapp from "../Images/login_whatsapp.png";
import apple from "../Images/login_apple.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const LogoWrap = styled(S.flexRowCenter)`
	flex-grow: 1;
`;
const Logo = styled.img`
	width: 29rem;
	height: 9.4rem;
`;
const ButtonWrap = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 4rem;
`;
const LoginButton = styled.button`
	width: 92%;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	border: 1px solid ${S.color.gray400};
	margin-top: 1rem;
	position: relative;
`;
const LoginLogo = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	position: absolute;
	left: 1.7rem;
`;
const PopupLogo = styled(S.flexRowCenter)`
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	background-color: white;
	margin-right: 0.6rem;
	img {
		width: 75%;
	}
`;
const GoBack = styled(S.flexRowStart)`
	width: 100%;
	padding: 0.8rem 1.4rem;
	svg {
		height: 100%;
		transform: rotate(180deg);
	}
`;
declare global {
	interface Window {
		ReactNativeWebView: {
			postMessage: (value: string) => void;
		};
	}
}

export default () => {
	const navigate = useNavigate();

	const [isOpenAlert, setOpenAlert] = useState<boolean>(false);
	const [isOpenSetProfile, setOpenSetProfile] =
		useRecoilState<boolean>(SetProfileAtom); // 최초 프로필 설정 모달

	// 로그인 창 띄우기
	const Login = () => {
		// 로그인 연결 앱으로 메세지 전송
		const postMessage = (cannel: string) => {
			if (typeof window == undefined || !window?.ReactNativeWebView) {
				return;
			} else {
				window.ReactNativeWebView?.postMessage(
					JSON.stringify({ type: "LOGIN", channel: cannel })
				);
			}
		};
		return (
			<>
				<LoginButton onClick={() => postMessage("google")}>
					<LoginLogo src={google} />
					<S.button_M>Google Log-in</S.button_M>
				</LoginButton>
				{/* <LoginButton onClick={() => postMessage('whatsapp')}>
					<LoginLogo src={whatsapp} />
					<S.button_M>WhatsApp Log-in</S.button_M>
				</LoginButton> */}
				{localStorage.getItem("OS") == "ios" && (
					<LoginButton onClick={() => postMessage("apple")}>
						<LoginLogo src={apple} />
						<S.button_M>Apple Log-in</S.button_M>
					</LoginButton>
				)}
			</>
		);
	};

	return (
		<Container>
			<GoBack onClick={() => navigate(-1)}>
				<Chevron fill="black" />
			</GoBack>
			<LogoWrap>
				<Logo src={logo} />
			</LogoWrap>
			<ButtonWrap>{Login()}</ButtonWrap>
			{/* 재가입 제한 안내 */}
			<Modal
				isOpen={isOpenAlert}
				contents={
					<>
						<h2>
							<S.headline_4>Pendaftaran Diblokir</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray900}>
								Anda tidak dapat mendaftar ulang dengan akun
								yang telah dihapus selama 60 hari.
							</S.bodyText_M_long>
						</p>
						<p>
							<S.bodyText_S color={S.color.gray700}>
								Tanggal bisa mendaftar kembali : 2023-07-12
							</S.bodyText_S>
						</p>
					</>
				}
				buttonTextL={"OK"}
				buttonActionL={() => setOpenAlert(false)}
			/>
			{/* 중복 메일 제한 */}
			<Modal
				isOpen={false}
				contents={
					<>
						<h2>
							<S.headline_4>
								Alamat E-mail telah terdaftar.
							</S.headline_4>
						</h2>
						<S.popupBox style={{ margin: 0 }}>
							<S.flexRowCenter>
								<PopupLogo>
									<img src={google} />
								</PopupLogo>
								<S.bodyText_M_long color={S.color.gray900}>
									abc**@google.co.kr
								</S.bodyText_M_long>
							</S.flexRowCenter>
						</S.popupBox>
					</>
				}
				buttonTextL={"Periksa"}
				buttonActionL={() => setOpenAlert(false)}
			/>
		</Container>
	);
};
