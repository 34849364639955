import React, { useState } from "react";

// 금액 단위 콤마
const addComma = (value: number) => {
	let returnString = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return returnString;
};
// input 입력되며 콤마
const onChangeComma = (value) => {
	if (value == 0) return "";
	const comma = (value) => {
		value = String(value);
		return value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
	};
	const uncomma = (value) => {
		value = String(value);
		return value.replace(/[^\d]+/g, "");
	};
	return comma(uncomma(value));
};
// 숫자만 남기기
const onlyNum = (value: string) => {
	let regex = /[^0-9]/g;
	return value.replace(regex, "");
};
// 숫자로 변형
const toNumber = (value: string) => {
	let regex = /[^0-9]/g;
	let result = value.replace(regex, "");

	return Number(result);
};
// 핸드폰번호 형식
const phoneNumFormat = (value: string) => {
	return onlyNum(value).replace(
		/(^02.{0}|^01.{1}|[0-9]{4,4})([0-9]{3,4})([0-9]{3,4})/g,
		"$1 - $2 - $3"
	);
};
// 날짜
const getNow = () => {
	const d = new Date();
	return d.toLocaleDateString();
};
const getNowString = () => {
	const d = new Date();
	const year = d.getFullYear().toString(); // 년
	const month = (d.getMonth() + 1).toString().padStart(2, "0"); // 월
	const day = d.getDate().toString().padStart(2, "0"); // 일
	const hours = d.getHours().toString().padStart(2, "0"); // 시
	const minutes = d.getMinutes().toString().padStart(2, "0"); // 분
	return year + month + day + hours + minutes;
};
const getDateString = (date: Date) => {
	const year = date.getFullYear().toString(); // 년
	const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월
	const day = date.getDate().toString().padStart(2, "0"); // 일
	const hours = date.getHours().toString().padStart(2, "0"); // 시
	const minutes = date.getMinutes().toString().padStart(2, "0"); // 분
	const seconds = date.getSeconds().toString(); // 초
	return year + month + day + hours + minutes + seconds;
};
const dateFormat = (value: string, type?: string) => {
	let data = "";
	let regex = /[^0-9]/g;
	let number = value.replace(regex, "");
	if (type === "date") {
		data = number.substr(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3");
	} else if (type === "newDate") {
		data = number
			.substr(0, 14)
			.replace(
				/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
				"$1-$2-$3T$4:$5:$6"
			);
	} else {
		data = number
			.substr(0, 12)
			.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3 $4:$5");
	}
	return data;
};

export default {
	addComma,
	onChangeComma,
	onlyNum,
	toNumber,
	phoneNumFormat,
	getNow,
	getNowString,
	getDateString,
	dateFormat,
};
