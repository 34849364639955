// 배송지관리
import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import Api from "../libs/api";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { IsOverlapAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Modal from "../Components/Modal";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

const Container = styled.div`
	padding: 2rem 1.4rem 0;
`;
const DeliveryBox = styled(S.borderBox)`
	margin-bottom: 1.4rem;
	padding: 1.6rem;
	&:last-child {
		margin-bottom: 0.4rem;
	}
`;
const DeliveyButton = styled.div`
	display: flex;
	align-items: center;
	button {
		padding: 0 0.5rem;
	}
`;
const DeliveyAddress = styled(S.flexRowStart)`
	margin-top: 1.4rem;
	padding-top: 1.2rem;
	border-top: 1px solid ${S.color.gray300};
	align-items: flex-start;
	& > div {
		margin-left: 1rem;
	}
`;

interface Data {
	mdaIdx: number;
	mmIdx: number;
	addressName: string;
	recipientName: string;
	tel: string;
	postcode: string;
	address1: string;
	address2: string;
	defaultYn: number;
	useYn: boolean;
	regIdx?: number;
	regDt?: string;
	regIp?: string;
	modIdx?: number;
	modDt?: string;
	modIp?: string;
}

export default () => {
	const navigate = useNavigate();
	// 팝업

	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false); // 배송지 추가, 수정
	const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false); // 배송지 삭제 확인
	const location = useLocation().state;
	const presentData = location.item;
	// 배송지 리스트 정보
	const [deliveryDatas, setDeliveryDatas] = useState<Data[]>([]);
	const [deliveryNextPage, setDeliveryNextPage] = useState<boolean>(true);
	const deliveryPage = useRef<number>(1);
	const deliveryObserver = useRef<HTMLDivElement>(null);
	// 배송지 등록/수정 구분
	const updateType = useRef<number>();
	// 선택된 배송지 idx
	const [selectedIdx, setSelectedIdx] = useState<number>(null);
	// 배송지 수정/등록 잇풋
	const [mdaIdx, setMdaIdx] = useState<string>("");
	const [addressName, setAddressName] = useState<string>("");
	const [recipientName, setRecipientName] = useState<string>("");
	const [tel, setTel] = useState<string>("");
	const [postcode, setPostcode] = useState<string>("");
	const [address1, setAddress1] = useState<string>("");
	const [address2, setAddress2] = useState<string>("");
	const [defaultYn, setDefaultYn] = useState<boolean>(false);

	// 배송지 목록 리셋
	const deliveryReset = () => {
		setDeliveryDatas([]);
		setDeliveryNextPage(true);
		deliveryPage.current = 1;
		// setSelectedIdx(null);
	};
	// 배송지 목록 조회
	const deliveryFetch = async () => {
		console.log("deliveryFetch", deliveryPage.current);
		await Api.get(`/member/info/memberDeliveryAddress`, {
			page: deliveryPage.current,
		}).then((res) => {
			if (res.data.data !== undefined) {
				const data: Data[] = res.data.data;
				setDeliveryDatas((prevDatas) => [...prevDatas, ...data]);
				setDeliveryNextPage(data.length === 10);
				if (data.length) {
					deliveryPage.current += 1;
				}
			}
		});
	};
	useEffect(() => {
		if (!deliveryObserver.current || !deliveryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				deliveryFetch();
			}
		});
		io.observe(deliveryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [deliveryNextPage]);
	// 배송지 상세 조회
	const detailDeliveryAddress = async (mdaIdx) => {
		updateType.current = mdaIdx == "" ? 0 : 1;
		if (mdaIdx == "") {
			setMdaIdx("");
			setAddressName("");
			setRecipientName("");
			setTel("");
			setPostcode("");
			setAddress1("");
			setAddress2("");
			setDefaultYn(false);
		} else {
			await Api.get(`/member/info/memberDeliveryAddress/` + mdaIdx).then(
				(res) => {
					const data: Data = res.data.data;
					setMdaIdx(data.mdaIdx.toString());
					setAddressName(data.addressName);
					setRecipientName(data.recipientName);
					setTel(data.tel);
					setPostcode(data.postcode);
					setAddress1(data.address1);
					setAddress2(data.address2);
					setDefaultYn(data.defaultYn === 1);
				}
			);
		}
		setOpenPopup(true);
	};

	// 입력값 변경 이벤트
	const onChangeEvent = (target) => {
		// 입력 최대값 체크
		if (target.value.length > target.maxLength) {
			if (target.name == "addressName") {
				setAddressName(target.value.slice(0, target.maxLength));
			} else if (target.name == "recipientName") {
				setRecipientName(target.value.slice(0, target.maxLength));
			} else if (target.name == "tel") {
				setTel(target.value.slice(0, target.maxLength));
			}
		} else {
			if (target.name == "addressName") {
				setAddressName(target.value);
			} else if (target.name == "recipientName") {
				setRecipientName(target.value);
			} else if (target.name == "tel") {
				setTel(Util.phoneNumFormat(target.value));
			}
		}
	};

	// 배송지 등록/수정
	const addressUpdate = async () => {
		const params = {
			addressName: addressName,
			recipientName: recipientName,
			tel: tel,
			postcode: postcode,
			address1: address1,
			address2: address2,
			defaultYn: defaultYn ? 1 : 0,
		};
		// 등록/수정 구분
		if (updateType.current == 0) {
			// 등록
			await Api.post(`/member/info/memberDeliveryAddress`, params).then(
				(res) => {
					if (res.data !== undefined) {
						setToastType(0);
						setToastContents("Alamat pengiriman telah dimasukkan.");
						setOpenToast(true);
						setOpenPopup(false);
						if (deliveryDatas.length == 0) {
							deliveryFetch();
						} else {
							deliveryReset();
						}
					} else {
						alert("fail");
					}
				}
			);
		} else {
			// 수정
			await Api.patch(
				`/member/info/memberDeliveryAddress/${mdaIdx}`,
				params
			).then((res) => {
				if (res.data !== undefined) {
					setToastType(0);
					setToastContents("Alamat pengiriman telah diubah");
					setOpenToast(true);
					setOpenPopup(false);
					deliveryReset();
				} else {
					alert("fail");
				}
			});
		}
	};
	// 배송지 삭제
	const deleteIdx = useRef<number>(null);
	const deleteDeliveryAddress = async () => {
		await Api.del(
			`/member/info/memberDeliveryAddress/` + deleteIdx.current
		).then((res) => {
			if (res.data !== undefined) {
				setToastType(0);
				setToastContents("Alamat pengiriman telah dihapus.");
				setOpenToast(true);
				deliveryReset();
				setOpenConfirm(false);
			} else {
				alert("fail");
			}
		});
	};

	return (
		<>
			<Page
				title="Pengaturan Alamat Pengiriman"
				contents={
					<RefreshHandler
						handleRefresh={() => deliveryReset()}
						contents={
							<S.wrapper>
								{deliveryDatas.length === 0 ? (
									<NoList type={0} small top />
								) : (
									<Container>
										{deliveryDatas?.map((data, index) => (
											<DeliveryBox key={data.mdaIdx}>
												<S.flexRowBetween>
													<S.controlsWrap>
														<S.radio
															defaultChecked={
																// data.defaultYn === 1
																selectedIdx ==
																data.mdaIdx
															}
															id={
																"delivery" +
																data.mdaIdx
															}
															name="delivery"
															type="radio"
															onChange={() =>
																setSelectedIdx(
																	data.mdaIdx
																)
															}
														/>
														<label
															htmlFor={
																"delivery" +
																data.mdaIdx
															}
														>
															<S.bodyText_M>
																{
																	data.addressName
																}
															</S.bodyText_M>
														</label>
														{data.defaultYn ===
															1 && (
															<S.badge
																style={{
																	marginLeft:
																		"0.8rem",
																}}
															>
																<S.bodyText_XS
																	color={
																		S.color
																			.primary
																	}
																>
																	Utama
																</S.bodyText_XS>
															</S.badge>
														)}
													</S.controlsWrap>
													<DeliveyButton>
														<button
															onClick={() => {
																detailDeliveryAddress(
																	data.mdaIdx
																);
															}}
														>
															<S.bodyText_XS
																color={
																	S.color
																		.gray600
																}
															>
																Ubah
															</S.bodyText_XS>
														</button>
														<S.bodyText_S
															color={
																S.color.gray300
															}
														>
															{" "}
															|{" "}
														</S.bodyText_S>
														<button
															onClick={() => {
																deleteIdx.current =
																	data.mdaIdx;
																setOpenConfirm(
																	true
																);
															}}
														>
															<S.bodyText_XS
																color={
																	S.color
																		.gray600
																}
															>
																Hapus
															</S.bodyText_XS>
														</button>
													</DeliveyButton>
												</S.flexRowBetween>
												<DeliveyAddress>
													<p>
														<S.subhead_4
															color={
																S.color.gray600
															}
														>
															Alamat
														</S.subhead_4>
													</p>
													<div>
														<S.bodyText_S>
															{data.address1}{" "}
															{data.address2}
														</S.bodyText_S>
														<p
															style={{
																marginTop:
																	"0.8rem",
															}}
														>
															<S.bodyText_S
																color={
																	S.color
																		.gray800
																}
															>
																{
																	data.recipientName
																}{" "}
																/ {data.tel}
															</S.bodyText_S>
														</p>
													</div>
												</DeliveyAddress>
											</DeliveryBox>
										))}
									</Container>
								)}
								<div style={{ padding: "1.6rem 1.4rem 1rem" }}>
									<S.buttonPrimary_B
										onClick={() => {
											detailDeliveryAddress("");
										}}
									>
										<S.button_S>
											Tambahkan Alamat Pengiriman
										</S.button_S>
									</S.buttonPrimary_B>
								</div>
								<div ref={deliveryObserver} />
							</S.wrapper>
						}
					/>
				}
				button={"Konfirmasi"}
				buttonAction={() => {
					console.log(selectedIdx);
					navigate("/DLVR001001", {
						state: { item: presentData, selectedIdx: selectedIdx },
						replace: true,
					});
				}}
				disabled={selectedIdx === null}
				absolute
			/>
			{/* 배송지 추가, 수정 */}
			<Modal
				isOpen={isOpenPopup}
				close={() => setOpenPopup(false)}
				contents={
					<S.pageContainer>
						<h2>
							<S.headline_4>
								{updateType.current == 0
									? "Masukkan Alamat Pengiriman"
									: "Ubah Alamat Pengiriman"}
							</S.headline_4>
						</h2>
						<h2>
							<S.subhead_4>Label Alamat</S.subhead_4>{" "}
							<S.subhead_4 color={S.color.error}>*</S.subhead_4>
						</h2>
						<S.inputField>
							<div className="inputWrap">
								<input
									name="addressName"
									type="text"
									value={addressName}
									onChange={(e) => {
										onChangeEvent(e.target);
									}}
									placeholder="Masukkan label alamat"
									maxLength={20}
								/>
							</div>
						</S.inputField>
						<h2>
							<S.subhead_4>Penerima</S.subhead_4>{" "}
							<S.subhead_4 color={S.color.error}>*</S.subhead_4>
						</h2>
						<S.inputField>
							<div className="inputWrap">
								<input
									name="recipientName"
									type="text"
									value={recipientName}
									onChange={(e) => {
										onChangeEvent(e.target);
									}}
									placeholder="Masukkan nama penerima"
									maxLength={20}
								/>
							</div>
						</S.inputField>
						<h2>
							<S.subhead_4>Kontak</S.subhead_4>{" "}
							<S.subhead_4 color={S.color.error}>*</S.subhead_4>
						</h2>
						<S.inputField>
							<div className="inputWrap">
								<input
									name="tel"
									type="text"
									value={tel}
									onChange={(e) => {
										onChangeEvent(e.target);
									}}
									placeholder="0800 - 000 - 000"
									maxLength={18}
								/>
							</div>
						</S.inputField>
						<h2>
							<S.subhead_4>Alamat</S.subhead_4>{" "}
							<S.subhead_4 color={S.color.error}>*</S.subhead_4>
						</h2>
						<div className="inputArea">
							<S.inputField>
								<div className="inputWrap">
									<input
										id="postcode"
										name="postcode"
										placeholder="Masukkan alamat"
										value={postcode}
										onChange={(e) =>
											setPostcode(e.target.value)
										}
									></input>
								</div>
								<button>
									<S.button_XS>Cari alamat</S.button_XS>
								</button>
							</S.inputField>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="address1"
										name="address1"
										placeholder="Masukkan alamat"
										value={address1}
										onChange={(e) =>
											setAddress1(e.target.value)
										}
									></input>
								</div>
							</S.inputField>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="address2"
										name="address2"
										placeholder="Masukkan alamat"
										value={address2}
										onChange={(e) =>
											setAddress2(e.target.value)
										}
									></input>
								</div>
							</S.inputField>
						</div>
						<S.controlsWrap>
							<S.checkbox
								id="defaultYn"
								name="defaultYn"
								type="checkbox"
								checked={defaultYn}
								onChange={(e) => setDefaultYn(e.target.checked)}
							></S.checkbox>
							<label htmlFor="defaultYn">
								<S.bodyText_S>
									Pilih sebagai alamat utama
								</S.bodyText_S>
							</label>
						</S.controlsWrap>
						<input id="mdaIdx" name="mdaIdx" type="hidden" />
					</S.pageContainer>
				}
				buttonTextL={true ? "Simpan" : "Simpan"}
				buttonActionL={() => addressUpdate()}
				// 필수값 미입력시 버튼 비활성화
				buttonDisabledL={
					!(
						addressName.length > 0 &&
						recipientName.length > 0 &&
						tel.length > 0 &&
						postcode.length > 0 &&
						address1.length > 0 &&
						address2.length > 0
					)
				}
			/>
			{/* 배송지 삭제 */}
			<Modal
				isOpen={isOpenConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>Hapus alamat pengiriman</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Alamat pengiriman yang telah dihapus tidak bisa
							dipulihkan kembali.{`\n`}Anda yakin akan
							menghapusnya?
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenConfirm(false)}
				buttonTextR="OK"
				buttonActionR={() => deleteDeliveryAddress()}
			/>
		</>
	);
};
