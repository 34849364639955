// 이용내역(상자)
import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import Api from '../libs/api';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LoadingAtom, UserAtom } from '../Recoil/Atoms/UserState';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';
import Util from '../Store/Util';

import * as S from '../Styles/Style';
import NoList from '../Components/NoList';
import RefreshHandler from '../Components/RefreshHandler';

import chestGold from '../Images/chest_gold.png';
import point from '../Images/point.png';
import { config } from '../config/config';

const UserPropertyArea = styled(S.flexRowCenter)`
	background-color: ${S.color.orange50};
	border-radius: 1rem;
	margin: 2rem 1.4rem 1rem;
	padding: 1.2rem 0;
	img {
		height: 3.4rem;
		margin-right: 1.2rem;
	}
	p {
		border-radius: 2rem;
	}
`;

interface boxHistoryData {
	boxActionCode: string;
	boxType: string;
	changeType: string;
	changeTypeCode: string;
	mbqhIdx: number;
	regDt: string;
	count: string;
	updownType: string;
}

export default () => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 상자내역 리스트 정보
	const [boxHistoryDatas, setBoxHistoryDatas] = useState<boxHistoryData[]>([]);
	const [boxHistoryNextPage, setBoxHistoryNextPage] = useState<boolean>(true);
	const boxHistoryPage = useRef<number>(1);
	const boxHistoryObserver = useRef<HTMLDivElement>(null);
	//
	// 상자내역 리셋
	const boxHistoryReset = () => {
		setBoxHistoryDatas([]);
		setBoxHistoryNextPage(true);
		boxHistoryPage.current = 1;
	};
	// 상자내역 리스트 불러오기
	const boxHistoryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log('boxHistoryFetch');
		await Api.get(`/member/info/memberBoxHistory`, {
			page: boxHistoryPage.current,
			limit: 10,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				setBoxHistoryDatas((prevDatas) => [...prevDatas, ...data]);
				setBoxHistoryNextPage(data.length === 10);
				if (data.length) {
					boxHistoryPage.current += 1;
				}
			} else {
				alert('fail');
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		if (!boxHistoryObserver.current || !boxHistoryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				boxHistoryFetch();
			}
		});
		io.observe(boxHistoryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [boxHistoryNextPage]);
	// 상자 이력 구분
	const boxChangeText = (boxActionCode: string) => {
		switch (boxActionCode) {
			case '2_01_0': // 사용자 골드박스 현금구매건 차감
				return 'Peti Harta Karun Dibuka';
			case '2_01_1': // 사용자 골드상자 결제구매
				return 'Pembelian Peti Harta Karun';
			case '2_02_0': // 사용자 골드상자 오픈
				return 'Peti Harta Karun Dibuka';
			case '2_02_1': // 사용자 골드상자 포인트구매
				return 'Pembelian Peti Harta Karun';
			case '2_04_0': // 사용자 골드박스 합성 획득건 차감
				return 'Peti Harta Karun Dibuka';
			case '2_04_1': // 사용자 골드상자 합성 획득
				return 'Mendapatkan Peti Harta Karun';
			case '2_05_0': // 사용자 골드박스 기타 차감
				return 'Peti Harta Karun Dibuka';
			case '2_05_1': // 사용자 골드상자 기타 획득
				return 'Mendapatkan Peti Harta Karun';
			case '2_21_0': // 관리자 골드박스 테스트 지급건 회수
				return 'Test';
			case '2_21_1': // 관리자 테스트 골드상자 지급
				return 'Test';
			case '2_23_0': // 관리자 골드상자 이벤트 지급건 회수
				return 'Event';
			case '2_23_1': // 관리자 골드박스 이벤트 지급
				return 'Event';
			case '2_24_0': // 관리자 기타 골드상자 회수
				return 'Lain-lain';
			case '2_24_1': // 관리자 기타 골드상자 지급
				return 'Lain-lain';
			default:
				return '-';
		}
	};

	return (
		<S.wrapper>
			<UserPropertyArea
				onClick={() => {
					userState.goldboxCnt > 0
						? navigate('/BUPO001001', {
								replace: true,
							})
						: navigate('/POIN001001', {
								replace: true,
							});
				}}>
				<img src={chestGold} />
				<p>
					<S.bodyText_M_long>Gold box </S.bodyText_M_long>
					<S.subhead_3>{Util.addComma(userState.goldboxCnt)} Buah</S.subhead_3>
				</p>
			</UserPropertyArea>
			{/* 상자내역 */}
			{/* 리스트 */}
			<RefreshHandler
				handleRefresh={() => boxHistoryReset()}
				contents={
					<S.wrapper>
						{boxHistoryDatas.length === 0 || boxHistoryDatas === undefined ? (
							<NoList type={6} />
						) : (
							boxHistoryDatas?.map((boxHistoryData, index) => (
								<S.helpItem key={index}>
									<div>
										<S.bodyText_M_long
											$ellipsis={1}
											style={{
												marginBottom: '0.8rem',
											}}>
											{boxChangeText(boxHistoryData.boxActionCode)}
										</S.bodyText_M_long>
										<S.bodyText_XS color={S.color.gray500}>
											{Util.dateFormat(boxHistoryData?.regDt)}
										</S.bodyText_XS>
									</div>
									<S.flexColEnd>
										<S.subhead_3
											$ellipsis={1}
											style={{
												marginBottom: '0.4rem',
											}}>
											{boxHistoryData.updownType == '1' ? '+' : '-'}
											{boxHistoryData?.count}
										</S.subhead_3>
										<S.bodyText_S color={S.color.gray600}>
											{boxHistoryData.boxType == '2' ? 'Gold' : ''}
										</S.bodyText_S>
									</S.flexColEnd>
								</S.helpItem>
							))
						)}
						<div ref={boxHistoryObserver} />
					</S.wrapper>
				}
			/>
		</S.wrapper>
	);
};

