import React, { useState, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";

const Container = styled.div`
	width: 100%;
	overflow: hidden;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
`;
const TabContainer = styled.div`
	height: 5rem;
	display: flex;
	position: relative;
	flex-shrink: 0;

	button {
		width: 50%;
		font-size: 1.2rem;
		transition: color 100ms ease;
		span {
			text-align: center;
		}
	}
`;
const TabBar = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 50%;
	height: 0.4rem;
	background-color: ${S.color.primary};
	transition: left 100ms ease;
`;

interface DefaultType {
	tabs: { index: number; name: string; content: JSX.Element }[];
	activeTab?: number;
	setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
}

export default ({
	tabs,
	activeTab,
	setActiveTab,
}: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useRecoilState(LoadingAtom);

	return (
		<Container>
			<TabContainer>
				{tabs.map((el, idx) => (
					<button
						key={el.index}
						onClick={() => {
							if (isLoading) return;
							setActiveTab(idx);
						}}
						disabled={isLoading}
					>
						<S.button_XS
							color={activeTab === el.index && S.color.primary}
						>
							{el.name}
						</S.button_XS>
					</button>
				))}
				<TabBar
					style={{
						left: activeTab === 1 && "50%",
					}}
				/>
			</TabContainer>
			{tabs[activeTab].content}
		</Container>
	);
};
