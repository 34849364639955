// 확률
import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Api from "../libs/api";
import { config } from "../config/config";

import * as S from "../Styles/Style";

import { ReactComponent as XIcon } from "../Images/x.svg";

const Wrapper = styled(S.popupWrapper)`
	display: flex;
	flex-direction: column;
`;
const GradeList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;

	li {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 0.5rem;
	}
`;
const GradeBox = styled(S.flexRowCenter)<{ color: string }>`
	width: 3rem;
	height: 3rem;
	background-color: ${(prop) => prop.color};
	text-align: center;
	span {
		font-weight: bold;
	}
`;
const ProductList = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	ul {
		display: flex;
		flex-wrap: wrap;
	}
`;
const ProductItem = styled.li<{ color: string }>`
	width: calc(100% / 3 - 0.5rem);
	margin-right: 0.75rem;
	height: 7rem;
	margin-top: 1rem;
	border: 1.5px solid ${S.color.gray500};
	border-color: ${(prop) => prop.color && prop.color};
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
`;

interface treasureData {
	pmIdx: string;
	gradeCode: string;
	imageUrl: string;
}
interface gradeData {
	percentage: string;
	gradeCode: string;
}

interface DefaultType {
	isOpen: boolean; // 팝업 생성 여부
	close: () => void;
}

export default ({ isOpen, close }: PropsWithChildren<DefaultType>) => {
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 보물 리스트 정보
	const [treasureDatas, setTreasureDatas] = useState<treasureData[]>([]);
	const [treasureNextPage, setTreasureNextPage] = useState<boolean>(true);
	const treasurePage = useRef<number>(1);
	const treasureObserver = useRef<HTMLDivElement>(null);

	const [gradeDatas, setGradeDatas] = useState<gradeData[]>([]);
	const colorList = [
		{ grade: "S5", color: S.color.primaryVariant },
		{ grade: "S4", color: S.color.primary },
		{ grade: "S3", color: S.color.secondaryVariant },
		{ grade: "S2", color: S.color.secondary },
		{ grade: "S1", color: S.color.point01 },
		{ grade: "S", color: S.color.point02 },
		{ grade: "A", color: "#ffe206" },
		{ grade: "B", color: "#a963d5" },
		{ grade: "C", color: "#dc6cba" },
		{ grade: "D", color: S.color.gray900 },
		{ grade: "E", color: S.color.gray700 },
		{ grade: "F", color: S.color.gray500 },
	];

	// 보물 리스트 불러오기
	const treasureFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.get("/common/gradePercentage", {
			page: treasurePage.current,
			limit: 30,
		}).then((res) => {
			console.log(res);
			if (res.data !== undefined && res.data !== null) {
				const grade = res.data.data.grade;
				const data = res.data.data.present;
				setTreasureDatas((prevDatas) => [...prevDatas, ...data]);
				setTreasureNextPage(data.length === 30);
				if (data.length) {
					treasurePage.current += 1;
				}
				setGradeDatas(grade);
			} else {
				alert("fail");
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		if (!treasureObserver.current || !treasureNextPage || !isOpen) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				treasureFetch();
			}
		});
		io.observe(treasureObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [treasureNextPage, isOpen]);

	return (
		<S.popupContainer $isOpen={isOpen}>
			<Wrapper>
				<S.popupXIcon onClick={() => close()}>
					<XIcon fill={S.color.gray700} />
				</S.popupXIcon>
				<h2>
					<S.headline_4>Daftar Produk Kotak Emas</S.headline_4>
				</h2>
				<p>
					<S.bodyText_S color={S.color.gray800}>
						Untuk informasi detail mengenai probabilitas, silakan
						periksa bagian Tanya Jawab (FAQ).
					</S.bodyText_S>
				</p>
				<GradeList>
					{gradeDatas.map((item, index) => {
						return (
							<li key={index}>
								<GradeBox
									color={
										colorList.filter(
											(color) =>
												color.grade == item.gradeCode
										)[0].color
									}
								>
									<S.bodyText_S color="white">
										{item.gradeCode}
									</S.bodyText_S>
								</GradeBox>
								<S.bodyText_XS color={S.color.gray500}>
									{item.percentage}
								</S.bodyText_XS>
							</li>
						);
					})}
				</GradeList>
				<ProductList>
					<ul>
						{treasureDatas?.map((treasureData, index) => (
							<ProductItem
								key={treasureData.pmIdx}
								color={
									colorList.filter(
										(color) =>
											color.grade ==
											treasureData.gradeCode
									)[0].color
								}
								style={{
									marginRight: (index + 1) % 3 == 0 && 0,
								}}
							>
								<S.heightImg
									src={imgUrl + treasureData.imageUrl}
									alt=""
								/>
							</ProductItem>
						))}
					</ul>
					<div ref={treasureObserver} />
				</ProductList>
			</Wrapper>
		</S.popupContainer>
	);
};
