// 보물상자 오픈
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import Ratio from "../Components/Ratio";

import boxOpenBg from "../Images/box_open_bg.png";
import chestGold from "../Images/chest_gold.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import { ReactComponent as Help } from "../Images/question_circle_fill.svg";
import { ReactComponent as InfoIcon } from "../Images/info_fill.svg";

const Container = styled.div`
	flex-grow: 1;
	background-image: url(${boxOpenBg});
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	padding: 2rem 1.4rem;
`;
const BoxSelectArea = styled(S.flexRowCenter)`
	position: relative;
	& > div {
		width: 18rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img {
		height: 10rem;
		margin-bottom: 0.6rem;
	}
	p {
		width: 100%;
		padding: 0.4rem 0;
		background-color: ${S.color.orange800};
		border: 1px solid ${S.color.orange900};
		box-shadow: 0 0.2rem 0.1rem ${S.color.orange900};
		border-radius: 0.6rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		span {
			display: block;
			text-align: center;
		}
	}
`;
const OpenArea = styled.div`
	padding: 1.5rem 2rem 2rem;
	border-radius: 1rem;
	border: 2px solid #d35e2f66;
	background-color: ${S.color.gray100};
	background-color: white;
	position: relative;
	header {
		margin-bottom: 0.6rem;
		padding-right: 3rem;
	}
`;
const BoxCountArea = styled.div`
	margin: 0.8rem 0;
	border-bottom: 1px solid ${S.color.primary};
	display: flex;
	align-items: center;
	justify-content: space-between;
	span {
		width: 100%;
		padding: 1.2rem;
		font-size: 1.6rem;
		font-family: "concertOneRegular";
		text-align: center;
	}
	button {
		width: 2.2rem;
		height: 2.2rem;
		background-color: ${S.color.gray400};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
`;
const BoxCountButtonArea = styled(S.flexRowBetween)`
	margin: 1.2rem 0 2.4rem;
	button {
		flex-grow: 2;
		flex-shrink: 1;
		padding: 0.6rem 1rem;
		margin-right: 0.8rem;
		border-radius: 2rem;
		background-color: ${S.color.orange50};
		box-shadow: 0 0.4rem 0 ${S.color.primary};
		border: 1px solid ${S.color.primary};
		color: ${S.color.primary};
		span {
			text-align: center;
		}
	}
	button:last-child {
		flex-grow: 1;
		margin-right: 0;
		flex-shrink: 0;
	}
`;
const Ratioutton = styled.button`
	position: absolute;
	top: -0.5rem;
	left: calc(50% - 6rem);
	transform: translate(-50%);
	svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;
const HelpButton = styled.button`
	position: absolute;
	top: 2rem;
	right: 2.2rem;
	svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 선택된 상자 개수
	const [selectedBoxCount, setSelectedBoxCount] = useState<number>(0);
	// 팝업
	const [isOpenHelp, setOpenOpenHelp] = useState<boolean>(false);
	const [isOpenRatio, setOpenRatio] = useState<boolean>(false);
	const [visibleRatio, setVisibleRatio] = useState<boolean>(false);

	useEffect(() => {
		ratioCheck();
	}, []);
	// 상자 개수 선택
	const addBoxCount = (count: number) => {
		// 선택 가능한 최대 상자 개수 계산
		const userBoxCount = userState.goldboxCnt;
		const maxCount = userBoxCount > 50 ? 50 : userBoxCount;
		if (selectedBoxCount + count >= maxCount) {
			setSelectedBoxCount(maxCount);
		} else if (selectedBoxCount <= maxCount) {
			setSelectedBoxCount(selectedBoxCount + count);
		}
	};
	// 상자 오픈
	const boxOpen = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.post("/box/open", {
			bmIdx: "2",
			openCnt: selectedBoxCount.toString(),
			// testing:
		}).then((res) => {
			if (res.data !== undefined) {
				if (res.data.statusCode === 200) {
					navigate("/BUPO001002", {
						state: { data: res.data.data },
						replace: true,
					});
				}
			} else {
				alert("fail");
			}
		});
		setLoading(false);
	};
	// 확률 관련 내용 생성 여부
	const ratioCheck = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.get("/common/gradePercentage", {
			page: 1,
			limit: 30,
		}).then((res) => {
			console.log(res);
			if (res.data !== undefined) {
				if (res.data.data == null) {
					setVisibleRatio(false);
				} else {
					setVisibleRatio(true);
				}
			} else {
				setVisibleRatio(false);
				alert("fail");
			}
		});
		setLoading(false);
	};
	return (
		<>
			<Page
				title="Buka Peti Harta Karun"
				badge="Beli Peti "
				badgeAction={() => navigate("/POIN001001")}
				backTo={"/"}
				contents={
					<>
						<Container>
							<BoxSelectArea>
								<div>
									{visibleRatio && (
										<Ratioutton
											onClick={() => setOpenRatio(true)}
										>
											<InfoIcon fill={"white"} />
										</Ratioutton>
									)}
									<img src={chestGold} />
									<p>
										<S.mainTitle_M color="white">
											Gold box
										</S.mainTitle_M>
										<S.bodyText_S color="white">
											Anda memiliki{" "}
											{Util.addComma(
												userState.goldboxCnt
											)}{" "}
											buah
										</S.bodyText_S>
									</p>
								</div>
							</BoxSelectArea>
							<OpenArea>
								<header>
									<S.mainTitle_M>
										Pilih jumlah peti harta karun yang akan
										Anda buka
									</S.mainTitle_M>
								</header>
								<p>
									<S.bodyText_S color={S.color.gray500}>
										Anda bisa membuka maksimal 50 peti harta
										karun.
									</S.bodyText_S>
								</p>
								<BoxCountArea>
									<span>{selectedBoxCount}</span>
									<button
										onClick={() => setSelectedBoxCount(0)}
									>
										<XIcon fill="white" width="1rem" />
									</button>
								</BoxCountArea>
								<BoxCountButtonArea>
									<button onClick={() => addBoxCount(1)}>
										<S.mainTitle_S>+1</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(5)}>
										<S.mainTitle_S>+5</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(10)}>
										<S.mainTitle_S>+10</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(50)}>
										<S.mainTitle_S>Maksimal</S.mainTitle_S>
									</button>
								</BoxCountButtonArea>
								<S.buttonPrimary
									onClick={() => boxOpen()}
									disabled={selectedBoxCount == 0}
								>
									<S.button_M>
										Buka Peti Harta Karun
									</S.button_M>
								</S.buttonPrimary>
								<HelpButton
									onClick={() => setOpenOpenHelp(true)}
								>
									<Help fill="#CFCFCF" />
								</HelpButton>
							</OpenArea>
						</Container>
					</>
				}
			/>
			{/* 오픈 안내 */}
			<Modal
				isOpen={isOpenHelp}
				contents={
					<>
						<h2>
							<S.headline_4>
								Informasi Pembukaan Peti Harta Karun
							</S.headline_4>
						</h2>
						{visibleRatio && (
							<p>
								<S.bodyText_M_long color={S.color.gray800}>
									Daftar produk yang bisa didapatkan dapat
									dilihat di daftar harta karun atau dengan
									menekan tombol tanda seru (!) pada peti
									harta karun yang akan dibuka, lalu periksa
									daftar produk.
								</S.bodyText_M_long>
							</p>
						)}
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Membeli Random Box memungkinkan Anda untuk
								mendapatkan satu hadiah secara acak. Jika Anda
								sudah membuka peti harta karun dan mendapatkan
								hadiahnya, pembatalan pembayaran tidak dapat
								dilakukan.
							</S.bodyText_M_long>
						</p>
						<S.textList $gray>
							<li className="subTitle">
								<S.subhead_3>
									[Informasi Pengiriman/Penggantian]
								</S.subhead_3>
							</li>
							<li>
								<S.bodyText_S>
									Untuk menerima hadiah dari peti harta karun,
									Anda perlu mengajukan permintaan pengiriman
									terpisah di "Harta Karun Saya".{"\n"}(Ongkos
									kirim 15,000P)
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Pembatalan pengiriman dapat dilakukan
									melalui "Status Pengiriman" di "Harta Karun
									Saya" jika statusnya masih "Permintaan
									Pengiriman".
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Penggantian dan pengembalian produk yang
									diterima karena alasan seperti perubahan
									pikiran atau ketidakpuasan tidak dapat
									dilakukan.
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Jika ada masalah dengan produk yang diterima
									(pengiriman yang salah, kerusakan, dll.),
									Anda dapat mengajukan penggantian melalui
									Layanan 1:1.
								</S.bodyText_S>
							</li>
						</S.textList>
					</>
				}
				close={() => setOpenOpenHelp(false)}
			/>
			{/* 확률정보 */}
			<Ratio isOpen={isOpenRatio} close={() => setOpenRatio(false)} />
		</>
	);
};
