// 자주 묻는 질문
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom, IsOverlapAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

import search from "../Images/search.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const FaqItem = styled.div<{ $view: boolean }>`
	width: 100%;
	border-bottom: 1px solid ${S.color.gray300};
	&:first-child {
		margin-top: 2rem;
		border-top: 1px solid ${S.color.gray300};
	}
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.6rem 1.4rem;
	}
	svg {
		width: 1.8rem;
		transform: ${(props) =>
			props.$view ? "rotate(-90deg)" : "rotate(90deg)"};
	}
	.answer {
		display: ${(props) => (props.$view ? "block" : "none")};
		padding: 1.6rem 1.4rem;
		border-top: 1px solid ${S.color.gray300};
		background-color: ${S.color.gray100};
	}
`;

interface faqData {
	bfIdx: number;
	orderNumber: number;
	categoryCode: string;
	categoryName: string;
	questionText: string;
	answerText: string;
	useYn: number;
	regIdx: number;
	regDt: string;
	displayYn: number;
}

export default () => {
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 카테고리 슬라이드 메뉴
	const [slideMenuData, setSlideMenuData] = useState([
		{ code: 0, text: "Semua" }, // 전체
		{ code: 1, text: "Penggunaan" }, // 이용
		{ code: 2, text: "Pembayaran" }, // 결제
		{ code: 4, text: "Pengiriman" }, // 배송
		{ code: 6, text: "Lain-lain" }, // 기타
		{ code: 7, text: "Keanggotaan" }, // 회원
	]);
	// 선택된 슬라이드 메뉴 값
	const slideMenuValue = useRef<number>(0);
	// 거래소 리스트 정보
	const [faqDatas, setFaqDatas] = useState<faqData[]>([]);
	const [faqNextPage, setFaqNextPage] = useState<boolean>(true);
	const faqPage = useRef<number>(1);
	const faqObserver = useRef<HTMLDivElement>(null);
	// 선택된 faq idx
	const [selectedFaqIdx, setSelectedFaqIdx] = useState<number>(0);
	// 검색
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	// 자주 묻는 질문 리셋
	const faqReset = () => {
		setFaqDatas([]);
		setFaqNextPage(true);
		faqPage.current = 1;
	};
	// 자주 묻는 질문 리스트 불러오기
	const faqFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("faqFetch");
		searchYnRef.current = false;
		await Api.get(`/board/faqList`, {
			page: faqPage.current,
			keyword: keywordRef.current == "" ? undefined : keywordRef.current,
			categoryCode:
				slideMenuValue.current == 0 ? "" : slideMenuValue.current, // 1 = 이용, 2 = 결제, 4 = 배송, 6 = 기타, 7 = 회원
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;
				console.log(data);
				setFaqDatas((prevDatas) => [...prevDatas, ...data]);
				setFaqNextPage(data.length === 10);
				if (data.length) {
					faqPage.current += 1;
				}
			} else {
				alert("fail");
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (!faqObserver.current || !faqNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				faqFetch();
			}
		});
		io.observe(faqObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [faqNextPage, slideMenuData]);

	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), faqReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		faqReset();
	};
	return (
		<Page
			title="FAQ"
			contents={
				<>
					{/* 검색 */}
					<S.searchArea>
						<div className="searchBox">
							<input
								id="keyword"
								type="text"
								placeholder="kata pencarian"
							/>
							<S.searchButton onClick={() => searchAction()}>
								<img src={search} />
							</S.searchButton>
						</div>
					</S.searchArea>
					{/* 슬라이드 메뉴 */}
					<S.slideMenuWrap style={{ margin: "1rem 0 1.2rem" }}>
						<S.slideMenu>
							{slideMenuData?.map((data, index) => (
								<S.slideMenuItem
									key={index}
									value={index}
									onClick={() => {
										if (isLoading) return;
										slideMenuValue.current = data.code;
										faqReset();
									}}
									$active={
										slideMenuValue.current === data.code
									}
								>
									<button>
										<S.bodyText_XS>
											{data.text}
										</S.bodyText_XS>
									</button>
								</S.slideMenuItem>
							))}
						</S.slideMenu>
					</S.slideMenuWrap>
					{/* 리스트 */}
					<RefreshHandler
						handleRefresh={() => faqReset()}
						contents={
							<S.wrapper>
								{faqDatas.length === 0 ? (
									<NoList type={7} searchKeyword={keyword} />
								) : (
									faqDatas?.map((faqData, index) => (
										<FaqItem
											key={index}
											onClick={() => {
												if (
													faqData.bfIdx ===
													selectedFaqIdx
												)
													return setSelectedFaqIdx(0);
												setSelectedFaqIdx(
													faqData.bfIdx
												);
											}}
											$view={
												faqData.bfIdx === selectedFaqIdx
											}
										>
											<header>
												<S.subhead_3>
													{faqData.questionText}
												</S.subhead_3>
												<Chevron
													fill={S.color.gray600}
												/>
											</header>
											<div className="answer">
												<S.bodyText_S>
													{faqData.answerText}
												</S.bodyText_S>
											</div>
										</FaqItem>
									))
								)}
								<div ref={faqObserver} />
							</S.wrapper>
						}
					/>
				</>
			}
		></Page>
	);
};
