// 회원탈퇴
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IsLoginAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

const ExplainList = styled.ul`
	& > li {
		display: flex;
		margin-bottom: 1.2rem;
	}
	& > li:last-child {
		margin-bottom: 0rem;
	}
	.number {
		width: 2rem;
		height: 2rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}
`;
const DetailItem = styled(S.textList)`
	margin-top: 0.8rem;
`;

export default () => {
	const navigate = useNavigate();
	const setIsLogin = useSetRecoilState(IsLoginAtom);
	// 탈퇴 완료
	const [isOpenAlert, setOpenAlert] = useState(false);
	// 동의 여부
	const [agree, setAgree] = useState<boolean>(false);
	// 탈퇴사유
	const [reason, setReason] = useState<number>();
	// 기타 사유 작성
	const [reasonText, setReasonText] = useState<string>("");
	// 탈퇴사유 리스트
	const radioList = [
		{ id: 0, text: "Kekhawatiran data pribadi disalahgunakan." },
		{ id: 1, text: "Tidak ada kepercayaan pada layanan ini." },
		{ id: 2, text: "Tidak ada produk yang saya inginkan." },
		{
			id: 3,
			text: "Proses penukaran, pengembalian barang dan pengembalian dana sulit.",
		},
		{ id: 4, text: "Kurangnya event menarik dan promo." },
		{ id: 5, text: "Tidak nyaman menggunakan aplikasi ini." },
		{ id: 6, text: "Sudah lama tidak aktif menggunakan aplikasi." },
		{ id: 7, text: "Lainnya." },
	];
	const withDrawMember = async () => {
		await Api.patch("/auth/withDrawMember").then((res) => {
			console.log(res);
			if (res.status == 200) {
				setIsLogin(false);
				localStorage.removeItem("token");
				window.ReactNativeWebView?.postMessage(
					JSON.stringify({ type: "LOGOUT" })
				);
				setOpenAlert(true);
			}
		});

		//
	};

	return (
		<>
			<Page
				title="Hapus Akun"
				contents={
					<S.pageContainer>
						<section>
							<h1>
								<S.subhead_2>
									Pastikan Anda telah membaca semua informasi
									sebelum melakukan penghapusan akun.
								</S.subhead_2>
							</h1>
							<p style={{ margin: "0.4rem 0 1.2rem" }}>
								<S.bodyText_M_long>
									Pastikan Anda sudah memahami semua
									ketentuan. ID yang Anda gunakan saat ini,
									[ID pengguna], tidak dapat digunakan kembali
									atau dipulihkan oleh Anda atau orang lain
									setelah penghapusan akun.
								</S.bodyText_M_long>
							</p>
							<ExplainList>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											1.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Setelah penghapusan akun, Anda tidak
											dapat lagi menggunakan layanan
											Gopang dengan akun yang telah
											dihapus.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											2.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Semua poin dan barang yang Anda
											miliki akan dihapus dan tidak dapat
											dipulihkan setelah penghapusan akun.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											3.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Informasi akun dan riwayat
											penggunaan layanan pribadi Anda akan
											dihapus secara permanen.
										</S.bodyText_M_long>
										<DetailItem $gray $small>
											<li>
												<S.bodyText_S>
													Informasi Pengguna: Hapus
													informasi nickname, email,
													nomor HP, dan alamat
													Pengguna
												</S.bodyText_S>
											</li>
											<li>
												<S.bodyText_S>
													Market: Hapus barang yang
													dijual, barang yang dibeli,
													dan status transaksi
												</S.bodyText_S>
											</li>
											<li>
												<S.bodyText_S>
													News Room: Hapus barang yang
													didapat, komentar, dan
													balasan
												</S.bodyText_S>
											</li>
										</DetailItem>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											4.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Riwayat pembayaran dan riwayat
											penukaran akan disimpan selama 5
											tahun.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											5.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Anda tidak dapat mendaftar ulang
											dengan data akun yang telah dihapus
											selama 60 hari.
										</S.bodyText_M_long>
									</div>
								</li>
							</ExplainList>
							<S.bar />
							<S.controlsWrap>
								<S.checkbox
									id="agree"
									size="L"
									type="checkbox"
									checked={agree}
									onChange={() => setAgree(!agree)}
								></S.checkbox>
								<label htmlFor="agree">
									<S.bodyText_M_long>
										Saya telah membaca dan menyetujui semua
										ketentuan di atas.
									</S.bodyText_M_long>
								</label>
							</S.controlsWrap>
						</section>
						<section>
							<h1>
								<S.subhead_2>
									Alasan Penutupan Akun{" "}
									<S.subhead_2 color={S.color.error}>
										(wajib)
									</S.subhead_2>
								</S.subhead_2>
							</h1>
							<S.inputList>
								{radioList.map((item) => {
									return (
										<li key={item.id}>
											<S.controlsWrap>
												<S.radio
													id={"reason" + item.id}
													type="radio"
													name="reason"
													checked={reason == item.id}
													onChange={() =>
														setReason(item.id)
													}
												/>
												<label
													htmlFor={"reason" + item.id}
												>
													<S.bodyText_M
														color={S.color.gray800}
													>
														{item.text}
													</S.bodyText_M>
												</label>
											</S.controlsWrap>
										</li>
									);
								})}
							</S.inputList>
							<S.inputField
								style={{ marginTop: "1.2rem" }}
								$disabled={reason !== 7}
							>
								<div className="inputWrap">
									<textarea
										name=""
										id=""
										placeholder="Masukkan dalam 100 karakter."
										cols={30}
										rows={5}
										value={reasonText}
										onChange={(e) => {
											setReasonText(e.target.value);
										}}
										maxLength={100}
										disabled={reason !== 7}
									></textarea>
									<S.inputCount className="inTextArea">
										<S.bodyText_XS>
											{reasonText.length}/100
										</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
						</section>
					</S.pageContainer>
				}
				button="Hapus Akun"
				buttonAction={() => withDrawMember()}
				disabled={!agree || reason == undefined}
			/>
			<Modal
				isOpen={isOpenAlert}
				contents={
					<>
						<h2>
							<S.headline_4>Akun telah dihapus.</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Terima kasih telah menggunakan GOPANG.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL="OK"
				buttonActionL={() => {
					navigate("/", { replace: true });
				}}
			/>
		</>
	);
};
