// 리스트 없는 페이지
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { LoadingAtom } from '../Recoil/Atoms/UserState';

import * as S from '../Styles/Style';

const Container = styled.div<{
	$small?: boolean;
	$top?: boolean;
	$bottom?: boolean;
}>`
	width: 100%;
	height: ${(props) => (props.$small ? '15rem' : 0)};
	flex-grow: ${(props) => (props.$small ? 0 : 1)};
	padding: 0.4rem 1.4rem 0.4rem;
	padding-top: ${(props) => (props.$top ? '2rem' : '0.4rem')};
	padding-bottom: ${(props) => (props.$bottom ? '2rem' : '0.4rem')};
	div {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img {
		width: 6rem;
		height: 6.3rem;
		margin-bottom: 2rem;
	}
`;
const NoSearch = styled(S.wrapper)`
	h1 {
		/* margin: 2rem 0; */
		margin: 0.6rem 0 2rem;
	}
	ul {
		background-color: ${S.color.gray100};
		padding: 2rem;
		border-radius: 1rem;
	}
`;
const NoList = styled(S.wrapper)`
	background-color: ${S.color.gray100};
	border-radius: 1rem;
	justify-content: center;
`;

interface DefaultType {
	type?: number;
	// 검색 결과 없음
	searchKeyword?: string;
	// 전체 페이지를 차지하지 않음
	small?: boolean;
	top?: boolean;
	bottom?: boolean;
}

export default ({ type, searchKeyword, small, top, bottom }: PropsWithChildren<DefaultType>) => {
	// 로딩중
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 리스트가 없습니다 문구
	const sentence = () => {
		switch (type) {
			case 0:
				// 배송지 관리
				return 'Tidak ada alamat pengiriman yang terdaftar.';
			// 등록된 배송지가 없습니다.
			case 1:
				// 내보물함(배송)
				return 'Tidak ada riwayat pengiriman.';
			// 발송 내역이 없습니다.
			case 2:
				// 분해하기
				return 'Tidak ada riwayat transaksi.';
			// 거래 내역이 없습니다.
			case 3:
				// 합성 재료선택
				return 'Tidak ada harta karun yang Anda miliki.';
			// 가지고 있는 보물이 없습니다.
			case 4:
				// 공지사항
				return 'Tidak ada pemberitahuan baru.';
			// 새로운 알림이 없습니다.
			case 5:
				// 이벤트
				return 'Tidak ada event yang terdaftar.';
			// 등록된 이벤트가 없습니다.
			case 6:
				// 이용내역(상자)
				// 이용내역(포인트)
				return 'Tidak ada riwayat penggunaan.';
			// 사용 내역이 없습니다.
			case 7:
				// 자주묻는질문
				return 'Tidak ada FAQ yang terdaftar.';
			// 등록된 FAQ가 없습니다.
			case 8:
				// 내보물함(보관)
				// 보물
				return 'Tidak ada produk';
			// 상품이 없습니다.
			case 9:
				// 나의문의
				return 'Tidak ada pertanyaan yang terdaftar.';
			// 등록된 질문이 없습니다.
			default:
				// 알림내역
				return 'Tidak ada notifikasi baru.';
			// 새 알림이 없습니다.
		}
	};

	return (
		<Container $small={small} $top={top} $bottom={bottom}>
			{searchKeyword !== undefined && searchKeyword !== '' && !isLoading ? (
				<NoSearch>
					<h1>
						<S.subhead_3>Tidak ada hasil pencarian untuk ‘{searchKeyword}’.</S.subhead_3>
					</h1>
					<S.textList $gray>
						<li>
							<S.bodyText_S>Pastikan kata yang Anda masukkan benar.</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Coba kurangi jumlah kata kunci Anda atau ganti dengan kata kunci lain.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>Coba kata kunci lain yang berkaitan.</S.bodyText_S>
						</li>
					</S.textList>
				</NoSearch>
			) : (
				<NoList>
					<S.bodyText_M color={S.color.gray400}>
						{isLoading ? 'Mohon tunggu sebentar.' : sentence()}
					</S.bodyText_M>
				</NoList>
			)}
		</Container>
	);
};

