// 알림설정
import React, { useState, useEffect, useRef, Fragment } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { UserAtom, LoadingAtom, IsLoginAtom } from "../Recoil/Atoms/UserState";
import {
	AlertSettingAtom,
	AlertSettingContentsAtom,
} from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";
import Page from "../Components/Page";

import bell from "../Images/bell.png";

const TopExplin = styled(S.flexRowStart)`
	margin: 2rem 1.4rem 0;
	padding: 1.2rem 1.4rem;
	background-color: ${S.color.gray100};
	border-radius: 1rem;
	img {
		width: 2rem;
		height: 2rem;
		margin-right: 1.2rem;
	}
`;
const ToggleButton = styled.button`
	width: 4.8rem;
	height: 2.8rem;
	border-radius: 2rem;
	display: flex;
	align-items: center;
	justify-items: center;
	background-color: ${S.color.gray400};
	position: relative;
	transition: background-color 100ms linear;
	flex-shrink: 0;
	margin-left: 1.4rem;
	&::after {
		content: "";
		display: block;
		width: 2.4rem;
		height: 2.4rem;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		left: 0.2rem;
		transition: left 100ms linear;
	}
	&.on {
		background-color: ${S.color.primary};
	}
	&.on::after {
		left: 2.2rem;
	}
`;

export default () => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const isLogin = useRecoilValue(IsLoginAtom);
	const userState = useRecoilValue(UserAtom);
	const setOpenAlertSetting = useSetRecoilState<boolean>(AlertSettingAtom);
	const setAlertSettingContents = useSetRecoilState(AlertSettingContentsAtom);

	// 푸시 동의
	const [noticeYn, setNoticeYn] = useState<boolean>(false);
	const [activityYn, setActivityYn] = useState<boolean>(false);
	const [tradeYn, setTradeYn] = useState<boolean>(false);
	const [smsSendYn, setSmsSendYn] = useState<boolean>(false);
	const [mailSendYn, setMailSendYn] = useState<boolean>(false);
	const [pushAgreeYn, setPushAgreeYn] = useState<boolean>(false);
	const [nightYn, setNightYn] = useState<boolean>(false);

	const notifyList = [
		{
			id: 0,
			text: "Pemberitahuan",
			explain: "Informasi tentang penggunaan layanan Gopang.",
			key: "noticeYn",
			pushAgree: noticeYn,
			setPushAgree: setNoticeYn,
			active: isLogin,
		},
		{
			id: 1,
			text: "Aktifitas Saya",
			explain:
				"Informasi tentang status pengiriman, tanggapan pertanyaan dan masa berlaku kupon.",
			key: "activityYn",
			pushAgree: activityYn,
			setPushAgree: setActivityYn,
			active: isLogin,
		},
		{
			id: 2,
			text: "Market",
			explain: "Informasi tentang transaksi di Market.",
			key: "tradeYn",
			pushAgree: tradeYn,
			setPushAgree: setTradeYn,
			active: isLogin,
		},
		{
			id: 3,
			text: "Event",
			explain: "Informasi tentang event, promo dan update.",
			active: true,
		},
		{
			id: 4,
			text: "Iklan Tengah Malam",
			explain: "Pengiriman iklan tengah malam (21:00~08:00)",
			key: "nightYn",
			pushAgree: nightYn,
			setPushAgree: setNightYn,
			active: true,
		},
	];

	// 회원 알림 설정 정보
	const alertSettingFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("alertSettingFetch");
		await Api.get(`/member/info/memberAlertCenterSelect`).then((res) => {
			if (res.data == undefined) {
			} else {
				const data = res.data.data;
				console.log(res.data.data);
				setNoticeYn(data.noticeYn === 1);
				setActivityYn(data.activityYn === 1);
				setTradeYn(data.tradeYn === 1);
				setNightYn(data.nightYn === 1);
			}
		});

		setLoading(false);
	};
	useEffect(() => {
		if (isLogin) {
			console.log(userState);
			setSmsSendYn(userState.smsSendYn === 1);
			setMailSendYn(userState.mailSendYn === 1);
			alertSettingFetch();
		}
	}, [userState]);
	// 알림 설정 내역 저장
	const alertSetting = async (key: string, agree: boolean) => {
		if (isLoading) return;
		setLoading(true);
		await Api.post(`/member/info/memberAlertCenterProcess`, {
			data: {
				[key]: agree ? 1 : 0,
			},
		}).then((res) => {
			if (res.data !== undefined) {
				if (res.data.statusCode === 200) {
					// 혜택/이벤트 || 야간알림 변경한 경우 토스트 생성
					if (key === "pushAgreeYn" || key === "nightYn") {
						setAlertSettingContents({
							agree: agree,
							type: key === "pushAgreeYn" ? 0 : 1,
						});
						setOpenAlertSetting(true);
					}
				}
			} else {
				alert("fail");
			}
		});

		setLoading(false);
	};

	return (
		<Page
			title="Pengaturan Notifikasi"
			contents={
				<S.wrapper>
					{/* TODO: 시스템설정 필요 안내 */}
					{true && (
						<TopExplin>
							<img src={bell} alt="" />
							<S.bodyText_S color={S.color.gray800}>
								Notifikasi di perangkat Anda dimatikan. Silakan
								aktifkan untuk menerima notifikasi push.
							</S.bodyText_S>
						</TopExplin>
					)}
					{notifyList.map((item, index) => {
						if (item.active) {
							return (
								<S.helpItem
									key={item.id}
									style={
										index == 0 || (index == 3 && !isLogin)
											? { border: 0, marginTop: 0 }
											: {}
									}
								>
									<div>
										<h1>
											<S.subhead_2>
												{item.text}
											</S.subhead_2>
										</h1>
										<S.bodyText_S color={S.color.gray600}>
											{item.explain}
										</S.bodyText_S>
										{item.id == 3 && (
											<S.flexRowBetween
												style={{
													marginTop: "1.2rem",
												}}
											>
												<S.controlsWrap>
													<S.checkbox
														type="checkbox"
														name="pushAgreeAd"
														id="smsSendYn"
														checked={smsSendYn}
														onChange={() => {
															setSmsSendYn(
																!smsSendYn
															);
															alertSetting(
																"smsSendYn",
																!smsSendYn
															);
														}}
													/>
													<label htmlFor="smsSendYn">
														<S.bodyText_M
															color={
																S.color.gray800
															}
														>
															SMS
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
												<S.controlsWrap>
													<S.checkbox
														type="checkbox"
														name="pushAgreeAd"
														id="mailSendYn"
														checked={mailSendYn}
														onChange={() => {
															setMailSendYn(
																!mailSendYn
															);
															alertSetting(
																"mailSendYn",
																!mailSendYn
															);
														}}
													/>
													<label htmlFor="mailSendYn">
														<S.bodyText_M
															color={
																S.color.gray800
															}
														>
															Email
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
												<S.controlsWrap>
													<S.checkbox
														type="checkbox"
														name="pushAgreeAd"
														id="pushAgreeYn"
														checked={pushAgreeYn}
														onChange={() => {
															setPushAgreeYn(
																!pushAgreeYn
															);
															alertSetting(
																"pushAgreeYn",
																!pushAgreeYn
															);
														}}
													/>
													<label htmlFor="pushAgreeYn">
														<S.bodyText_M
															color={
																S.color.gray800
															}
														>
															Notifikasi Push
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
											</S.flexRowBetween>
										)}
									</div>
									{item.id !== 3 && (
										<ToggleButton
											className={
												item.pushAgree ? "on" : ""
											}
											onClick={() => {
												item.setPushAgree(
													!item.pushAgree
												);

												alertSetting(
													item.key,
													!item.pushAgree
												);
											}}
										/>
									)}
								</S.helpItem>
							);
						}
					})}
				</S.wrapper>
			}
		/>
	);
};
