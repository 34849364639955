// 내 정보 관리
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import axios from "axios";
import { replace, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
	IsLoginAtom,
	UserAtom,
	NickChangeCount,
	LoadingAtom,
} from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import Authentication from "../Components/Authentication";

import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as Rotate } from "../Images/rotate.svg";
import { config } from "../config/config";

const GoOutButton = styled(S.flexRowEnd)`
	padding: 1.4rem;
	span {
		text-decoration: underline;
	}
`;

export default () => {
	const navigate = useNavigate();
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const apiUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_API_URL;
	const setIsLogin = useSetRecoilState(IsLoginAtom);
	const [userState, setUserState] = useRecoilState(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	const [nickChangeCount, setNickChangeCount] =
		useRecoilState(NickChangeCount);
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenModal, setOpenModal] = useState<boolean>(false);
	// 프로필 설정
	const [nick, setNick] = useState<string>(userState.nick);
	const [profile, setProfile] = useState<string | ArrayBuffer>("");
	const [profileExplain, setProfileExplain] = useState<string>("");
	const [isValid, setValid] = useState<boolean>(false);
	// 휴대폰 인증
	const [name, setName] = useState<string>(userState.name);
	const [phoneNumber, setPhoneNumber] = useState<string>(
		userState.phoneNumber
	);
	const [complete, setComplete] = useState<boolean>(false);

	// 프로필 설정 표시될 이미지
	const profileImage = () => {
		if (profile == "") {
			if (userState.profileImageUrl == "") {
				return defaultProfile;
			} else {
				if (userState.profileImageUrl.includes("http")) {
					return userState.profileImageUrl;
				} else {
					return imgUrl + userState.profileImageUrl;
				}
			}
		} else {
			return profile;
		}
	};
	// 이미지 등록
	const profileUpload = async (e, reset?: boolean) => {
		const formData = new FormData();
		if (reset) {
			// 기본이미지로
			formData.append("reset", "true");
		} else {
			const file = e.target.files[0];
			console.log(file.size);
			if (file.size > 20 * 1024 * 1024) {
				return (
					setToastType(1),
					setToastContents(
						"File gambar hingga 20MB dapat dilampirkan." // 번역 확인 필요
					),
					setOpenToast(true)
				);
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setProfile(reader.result);
				};
				formData.append("profileImageUrl", file);
			}
		}
		const token = localStorage.getItem("token");
		await await axios
			.patch(apiUrl + "/member/info/memberInfoChange", formData, {
				headers: {
					Authorization: token,
					"content-type": "multipart/form-data",
				},
			})
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};
	// 회원정보 조회
	const memberInfoSelect = async () => {
		await Api.get("/member/info/memberInfoSelect").then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data;
				// 약관 미동의 회원
				if (data.member.stateCode == "01") {
				} else {
					setUserState(data.member);
					setNickChangeCount(data.nickChangeCount);
				}
			} else {
				alert("fail");
			}
		});
	};
	// 닉네임 중복체크
	const memberNickDuplicationCheck = async () => {
		if (isLoading) return;
		setLoading(true);

		// 영어, 한글, 숫자만 사용 가능
		const regex = /^[a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]+$/;
		if (nick === userState.nick) {
			// 변화 없음
			console.log("변화 없음");
		} else if (nickChangeCount <= 0) {
			// 닉네임 변경 횟수 초과
			setOpenModal(true);
		} else if (nick.length < 8) {
			// 8글자 미만 작성
			console.log("8글자 미만");
			setProfileExplain("Tulis minimal 8 karakter");
		} else if (nick.search(/\s/) != -1 || !regex.test(nick)) {
			// 특수문자 사용
			console.log("특수문자 사용");
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else if (false) {
			// 필터처리된 단어 포함
			console.log("필터처리된 단어 포함");
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else {
			await Api.get(`/member/info/memberNickDuplicationCheck`, {
				nick: nick,
			}).then((res) => {
				if (res.data !== undefined) {
					if (res.data.data === 0) {
						// 사용 가능
						console.log("사용 가능");
						setProfileExplain("Nickname bisa digunakan.");
						setValid(true);
						Api.patch(`/member/info/memberInfoChange`, {
							nick: nick,
						}).then((res) => {
							console.log(res);
							memberInfoSelect();
						});
					} else if (res.data.data > 0) {
						// 중복
						console.log("중복");
						setProfileExplain("Ada nickname yang sudah digunakan.");
						setValid(false);
					} else {
						setValid(false);
					}
				} else {
					alert("fail");
				}
			});
		}
		setLoading(false);
	};
	// 로그아웃
	const logout = async () => {
		await Api.get("/auth/logout").then((res) => {
			console.log("res : ", res.status);
			if (res.status == 200) {
				setIsLogin(false);
				localStorage.removeItem("token");
				window.ReactNativeWebView?.postMessage(
					JSON.stringify({ type: "LOGOUT" })
				);
				navigate("/", { replace: true });
			} else {
				console.log("로그아웃 실패");
			}
		});
	};

	return (
		<>
			<Page
				title="Informasi profil"
				contents={
					<S.pageContainer>
						<section>
							<h1>
								<S.flexRowBetween>
									<S.subhead_2>Profil</S.subhead_2>
									<S.bodyText_XS color={S.color.gray800}>
										Nickname dapat diubah:{" "}
										<S.bodyText_XS
											style={{ fontWeight: "bold" }}
										>
											{nickChangeCount} kali
										</S.bodyText_XS>
									</S.bodyText_XS>
								</S.flexRowBetween>
							</h1>
							<S.setProfileArea>
								<S.userProfile $url={profileImage()}>
									<input
										type="file"
										id="profileUpload"
										accept=" .gif, .jpg, .jpeg, .png"
										onChange={(e) => profileUpload(e)}
									/>
									<label htmlFor="profileUpload" />
									<Rotate
										fill="white"
										onClick={(e) => {
											setProfile(defaultProfile);
											profileUpload(e, true);
										}}
									/>
								</S.userProfile>
								<S.inputField>
									<div className="inputWrap">
										<input
											id="nick"
											value={nick}
											onFocus={() => setNick("")}
											onChange={(e) => {
												setValid(false);
												setProfileExplain("");
												setNick(e.target.value);
											}}
											placeholder={"Nickname"}
											maxLength={20}
										/>
									</div>
								</S.inputField>
								<p>
									<S.bodyText_S
										color={
											isValid
												? S.color.point01
												: S.color.error
										}
									>
										{profileExplain}
									</S.bodyText_S>
								</p>
							</S.setProfileArea>
							<S.buttonPrimary_B
								style={{ marginBottom: "1.6rem" }}
								onClick={() => memberNickDuplicationCheck()}
							>
								<S.button_XS>Periksa Nickname</S.button_XS>
							</S.buttonPrimary_B>
							<S.textList>
								<li>
									<S.bodyText_S color={S.color.gray400}>
										Boleh menggunakan huruf dan angka.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S color={S.color.gray400}>
										Harus terdiri dari 8-20 karakter.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S color={S.color.gray400}>
										Penggunaan kata-kata kotor atau tidak
										pantas dapat mengakibatkan sanksi dan
										perubahan paksa nickname.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S color={S.color.gray400}>
										Silakan unggah foto dengan ukuran
										maksimal 20 MB dalam format GIF, PNG,
										atau JPG.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
						<section>
							<h1>
								<S.subhead_2>Informasi Saya</S.subhead_2>
							</h1>
							{/* 번호인증 */}
							<Authentication
								type={1}
								name={name}
								setName={setName}
								phoneNumber={phoneNumber}
								setPhoneNumber={setPhoneNumber}
								complete={complete}
								setComplete={setComplete}
							/>
							<h3>
								<S.subhead_4>Email</S.subhead_4>
							</h3>
							<S.inputField>
								<div className="inputWrap">
									<input
										type="text"
										value={userState.email}
										readOnly
									/>
								</div>
							</S.inputField>
						</section>
						<section className="low">
							<S.flexRowCenter onClick={() => logout()}>
								<S.bodyText_L_long>Logout</S.bodyText_L_long>
							</S.flexRowCenter>
						</section>
						<GoOutButton>
							<S.bodyText_S
								color={S.color.gray600}
								onClick={() => navigate("/MEMB003001")}
							>
								Hapus akun
							</S.bodyText_S>
						</GoOutButton>
					</S.pageContainer>
				}
			/>
			{/* 닉네임 변경 횟수 초과 안내 */}
			<Modal
				isOpen={isOpenModal}
				contents={
					<>
						<h1>
							<S.headline_4>
								Anda tidak dapat mengubah nickname Anda saat
								ini.
							</S.headline_4>
						</h1>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda dapat mengubah nickname Anda maksimal dua
								kali dalam satu bulan.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL="OK"
				buttonActionL={() => setOpenModal(false)}
			/>
		</>
	);
};
