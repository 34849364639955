// 약관(서비스 개인정보취급방침)
import React from "react";
import styled from "styled-components";

import * as S from "../Styles/Style";

const Container = styled(S.wrapper)`
	height: 1rem;
	min-height: 100%;
	padding: 1.4rem;
	overflow-y: scroll;
	flex-grow: 1;
	flex-shrink: 1;
	p {
		margin-bottom: 1rem;
	}
	h1 {
		font-family: "RobotoMedium";
		font-size: 1.6rem;
		line-height: 2.4rem;
		margin-bottom: 1rem;
	}
	h2 {
		font-family: "RobotoMedium";
		font-size: 1.4rem;
		line-height: 2.2rem;
	}
	span {
		font-family: "RobotoRegular";
		font-size: 1.2rem;
		line-height: 1.8rem;
		display: block;
		margin-bottom: 0.8rem;
		color: ${S.color.gray800};
	}
	span.sub {
		padding-left: 1rem;
	}
	span.small {
		font-size: 1rem;
		padding-left: 1rem;
	}
`;

export default () => {
	return (
		<Container>
			<p>
				<h1>Ketentuan Umum</h1>
				<span>
					PT Bomulsun HBiz Indonesia (selanjutnya disebut
					“Perusahaan”) berkomitmen untuk melindungi keamanan data
					pribadi Anda. Kami senantiasa mematuhi peraturan
					perundang-undangan yang berlaku terkait perlindungan data
					pribadi, seperti Undang-Undang Nomor 19 Tahun 2016 tentang
					Perubahan atas Undang-Undang Nomor 11 Tahun 2008 tentang
					Informasi dan Transaksi Elektronik, serta peraturan
					perundang-undangan lainnya. Kebijakan Privasi ini dibuat
					untuk menjelaskan kepada Anda bagaimana kami mengumpulkan,
					menggunakan, mengelola, dan melindungi data pribadi Anda.
					{"\n\n"}
					Kebijakan Privasi ini dapat diakses dengan mudah melalui
					aplikasi GoPang. Kami berhak untuk mengubah Kebijakan
					Privasi ini sewaktu-waktu sesuai dengan perkembangan
					peraturan perundang-undangan atau untuk meningkatkan
					kualitas layanan kami. Perubahan terhadap Kebijakan Privasi
					ini akan kami informasikan melalui aplikasi GoPang. Oleh
					karena itu, kami sarankan Anda untuk memeriksa Kebijakan
					Privasi ini secara berkala.
					{"\n\n"}
					Anda berhak untuk menolak memberikan persetujuan atas
					pengumpulan, penggunaan, pengungkapan, atau pengalihan data
					pribadi Anda. Namun, perlu diketahui bahwa penolakan Anda
					dapat mengakibatkan pembatasan atau penghentian akses Anda
					terhadap layanan yang kami sediakan.
					{"\n\n"}※ Kebijakan Privasi ini berlaku untuk semua situs
					(termasuk mobile web dan aplikasi) yang dikelola oleh
					GoPang.
				</span>
			</p>
			<p>
				<h2>1. Tujuan Pengumpulan dan Penggunaan Data Pribadi</h2>
			</p>
			<p>
				<span>
					1) Saat Anda mendaftar, Perusahaan mengumpulkan informasi
					pribadi minimal yang diperlukan untuk memproses pesanan
					produk dan layanan, pengiriman barang, pembayaran, serta
					memberikan informasi yang relevan dan bermanfaat kepada
					Anda.
				</span>
				<span>
					2) Perusahaan tidak mengumpulkan informasi pribadi yang
					dapat menyinggung hak asasi manusia dasar Anda, seperti ras
					dan etnis, ideologi dan kepercayaan, asal usul, afiliasi
					politik, catatan kriminal, atau kondisi kesehatan.
				</span>
				<span>
					3) Informasi pribadi yang dikumpulkan akan digunakan untuk
					tujuan sebagai berikut:
				</span>
				<span className="sub">
					a. Terkait Keanggotaan: Verifikasi identitas, identifikasi
					pribadi untuk penggunaan akun layanan, penanganan pertanyaan
					dan keluhan pelanggan, pengiriman pemberitahuan, dan
					penyimpanan catatan untuk penyelesaian sengketa.
				</span>
				<span className="sub">
					b. Terkait Produk dan Layanan: Pembelian produk dan
					pembayaran biaya, pengiriman produk/barang hadiah atau
					tagihan.
				</span>
				<span className="sub">
					c. Terkait Pemasaran dan Informasi Produk (Opsional: Dengan
					persetujuan menerima SMS dan email): Pemberitahuan informasi
					layanan/event, survei kepuasan pelanggan, wawancara
					pelanggan, dan penggunaan data untuk analisis pemasaran.
				</span>
			</p>
			<p>
				<h2>2. Data Pribadi yang Dikumpulkan</h2>
			</p>
			<p>
				<span>
					1) Saat pertama kali mendaftar, Perusahaan mengumpulkan
					informasi pribadi sebagai berikut:
				</span>
				<span className="sub">
					- Saat Pendaftaran: Nama, Tanggal Lahir, Jenis Kelamin, ID
					Login, Kata Sandi, Nomor Telepon Rumah, Nomor Telepon
					Seluler, Email, Untuk pengguna di bawah usia 13 tahun:
					Informasi wali.
				</span>
				<span className="sub">
					- Saat Menggunakan Layanan: Alamat pengiriman, Informasi
					pembayaran.
				</span>
				<span>
					2) Selama penggunaan layanan, informasi berikut dapat secara
					otomatis dihasilkan dan dikumpulkan: (Informasi pembayaran,
					riwayat penggunaan layanan, log akses)
				</span>
				<span>
					3) Selama menggunakan layanan pembayaran, informasi
					pembayaran berikut dapat dikumpulkan:
				</span>
				<span className="sub">
					- Saat pembayaran dengan kartu kredit: Nama perusahaan kartu
					kredit, nomor kartu.
				</span>
				<span className="sub">
					- Saat pembayaran dengan virtual account: Informasi bank,
					nomor rekening, informasi identifikasi, informasi
					pembayaran.
				</span>
				<span className="sub">
					- Saat pembayaran dengan dompet digital: Informasi bank,
					nomor rekening, informasi identifikasi, informasi
					pembayaran.
				</span>
				<span className="sub">
					- Saat pembayaran dengan QRIS: Informasi bank, nomor
					rekening, informasi identifikasi, informasi pembayaran.
				</span>
				<span>
					4) Perusahaan mengumpulkan informasi pribadi melalui
					pendaftaran dan konsultasi melalui pusat layanan pelanggan.
				</span>
			</p>
			<p>
				<h2>3. Prosedur dan Metode Penghapusan Data Pribadi</h2>
			</p>
			<p>
				<span>
					Data pribadi Anda pada prinsipnya akan dihapus tanpa
					penundaan setelah tujuan pengumpulan dan penggunaan data
					tersebut tercapai.
				</span>
				<span>1) Prosedur Penghapusan</span>
				<span className="sub">
					Informasi yang Anda masukkan saat pendaftaran anggota dan
					sebagainya, setelah tujuannya tercapai, akan dipindahkan ke
					basis data terpisah dan disimpan untuk jangka waktu tertentu
					sesuai dengan kebijakan internal dan peraturan
					perundang-undangan terkait (lihat jangka waktu penyimpanan
					dan penggunaan). Setelah jangka waktu tersebut, informasi
					tersebut akan segera dihapus. Informasi pribadi yang telah
					dipindahkan ke basis data terpisah tidak akan digunakan
					untuk tujuan lain kecuali diwajibkan oleh undang-undang.
				</span>
				<span>2) Jangka Waktu Penghapusan</span>
				<span className="sub">
					Data pribadi Anda akan dihapus selambat-lambatnya 5 hari
					setelah jangka waktu penyimpanan berakhir. Jika tujuan
					pengolahan data pribadi tercapai, layanan terkait
					dihentikan, atau bisnis diakhiri sehingga data pribadi tidak
					lagi diperlukan, maka data pribadi tersebut akan dihapus
					selambat-lambatnya 5 hari setelah tanggal pengakuan bahwa
					pengolahan data pribadi tersebut tidak lagi diperlukan.
				</span>
				<span>3) Metode Penghapusan</span>
				<span className="sub">
					Data pribadi yang dicetak pada kertas akan dihancurkan
					menggunakan mesin penghancur atau dibakar. Data pribadi yang
					disimpan dalam bentuk file elektronik akan dihapus dengan
					menggunakan metode teknis yang tidak memungkinkan pemulihan
					data.
				</span>
			</p>
			<p>
				<h2>
					4. Pengalihan Pengelolaan dan Penyediaan Data Pribadi kepada
					Pihak Ketiga
				</h2>
			</p>
			<p>
				<span>
					1) Secara umum, Perusahaan akan memberikan data pribadi
					pengguna kepada pihak ketiga setelah mendapatkan persetujuan
					sebelumnya dari pengguna. Namun, hal berikut ini merupakan
					pengecualian:
				</span>
				<span className="sub">
					a. Jika diwajibkan oleh undang-undang, atau jika ada
					permintaan dari lembaga penegak hukum atau pengawas sesuai
					dengan prosedur dan metode yang ditetapkan dalam
					undang-undang untuk tujuan penyelidikan.
				</span>
				<span className="sub">
					b. Jika diperlukan untuk melindungi nyawa, tubuh, atau
					properti pengguna atau pihak ketiga yang mendesak, meskipun
					tidak dapat memperoleh persetujuan.
				</span>
				<span>
					2) Secara umum, Perusahaan akan menggunakan atau memberikan
					data pribadi kepada pihak ketiga dalam batas yang telah
					diumumkan dalam Kebijakan Privasi ini, dan tidak akan
					menggunakan atau memberikannya melebihi batas tersebut.
					Namun, hal ini tidak berlaku jika pengguna telah memberikan
					persetujuan sebelumnya untuk pengungkapan atau penyediaan
					kepada pihak ketiga.
				</span>
				<span>
					3) Dalam hal lain yang memerlukan penyediaan data pribadi
					kepada pihak ketiga, Perusahaan dapat memberikan data
					pribadi tersebut melalui prosedur yang sah, seperti
					memperoleh persetujuan dari pengguna, sebagai berikut:
				</span>
				<span className="sub">
					- Pihak yang menerima (data), item (data) yang dibagikan,
					tujuan penggunaan oleh pihak yang menerima (data), masa
					penyimpanan dan penggunaan, supplier produk, nama lengkap,
					nomor ponsel, alamat pengiriman, pengiriman barang,
					notifikasi pengiriman, dan pengelolaan tugas yang diperlukan
					untuk melaksanakan perjanjian jual beli online adalah
					beberapa contoh data yang mungkin dibagikan. Dihapus setelah
					tujuan penyediaan tercapai.
				</span>
				<span>
					※ Namun, jika ada ketentuan dalam peraturan
					perundang-undangan yang mengharuskan penyimpanan, maka data
					tersebut dapat disimpan sesuai dengan jangka waktu
					penyimpanan yang berlaku.
				</span>
				<span>
					※ Hanya berlaku untuk beberapa perusahaan pemasok yang
					melakukan pengiriman langsung.
				</span>
			</p>
			<p>
				<h2>5. Pengalihan Pengelolaan Data Pribadi</h2>
			</p>
			<p>
				<span>
					1) Perusahaan secara berkelanjutan memantau dan mengawasi
					apakah pihak ketiga yang ditunjuk untuk mengelola data
					pribadi dapat mengelola data tersebut dengan aman. Setelah
					tugas yang ditugaskan selesai, Perusahaan memastikan bahwa
					pihak ketiga tersebut menghapus data pribadi yang mereka
					miliki.
				</span>
				<span>
					2) Untuk memberikan layanan yang lebih baik, Perusahaan
					mengalihkan pengelolaan data pribadi seperti berikut:
				</span>
				<span>[Pengiriman Barang]</span>
				<span>
					- Pihak yang ditunjuk: Perusahaan jasa pengiriman domestik,
					perusahaan jasa pengiriman internasional, penyedia
					pembayaran online (penjual)
				</span>
				<span>
					- Ruang lingkup pekerjaan yang ditugaskan: Pengiriman,
					pengiriman, pengambilan barang oleh perusahaan jasa
					pengiriman, dan informasi yang diperlukan untuk proses
					tersebut.
				</span>
				<span>[Pengiriman Voucher, Gift Card]</span>
				<span>
					- Pihak yang ditunjuk: [Perusahaan penyedia Voucher, Gift
					Card]
				</span>
				<span>
					- Ruang lingkup pekerjaan yang ditugaskan: Pengiriman,
					penggunaan, dan verifikasi Voucher, Gift Card, dan informasi
					yang diperlukan untuk proses tersebut.
				</span>
				<span>[Pengiriman E-Voucher dan Kupon]</span>
				<span>
					- Pihak yang ditunjuk: [Penyedia E-Voucher dan Kupon]
				</span>
				<span>
					- Ruang lingkup pekerjaan yang ditugaskan: Pengiriman,
					penggunaan, dan verifikasi E-Voucher dan kupon online, dan
					informasi yang diperlukan untuk proses tersebut.
				</span>
				<span>[Pembayaran Online (PG)]</span>
				<span>- Pihak yang ditunjuk: NICEPAY</span>
				<span>
					- Ruang lingkup pekerjaan yang ditugaskan: Layanan perantara
					pembayaran seperti kartu kredit, dompet digital, virtual
					account, QRIS, dan hal-hal lain yang terkait dengan
					pembelian dan penjualan barang.
				</span>
			</p>
			<p>
				<h2>
					6. Ketentuan Mengenai Pengumpulan, Penggunaan, dan Penolakan
					Informasi Perilaku dan Pengidentifikasi Iklan
				</h2>
			</p>
			<p>
				<span>
					1) Perusahaan dapat mengumpulkan dan menggunakan data
					perilaku pengguna untuk memberikan layanan dan manfaat yang
					disesuaikan serta iklan online yang relevan selama pengguna
					menggunakan layanan.
				</span>
				<span>
					2) Perusahaan dapat mengumpulkan dan menggunakan data
					perilaku pengguna untuk memberikan iklan online yang relevan
					dengan cara sebagai berikut:
				</span>
				<span className="sub">
					a. Pengusaha iklan yang ingin mengumpulkan dan menggunakan
					data perilaku.
				</span>
				<span className="sub">
					b. Item data perilaku: Riwayat kunjungan, log aktivitas, dan
					riwayat pencarian pengguna di dalam layanan.
				</span>
				<span className="sub">
					c. Metode pengumpulan data perilaku: Dikumpulkan secara
					otomatis saat pengguna mengunjungi situs web atau aplikasi
					Perusahaan.
				</span>
				<span className="sub">
					d. Tujuan pengumpulan data perilaku: Menyediakan iklan yang
					dipersonalisasi berdasarkan minat dan preferensi pengguna.
				</span>
				<span className="sub">
					e. Periode penyimpanan/penggunaan dan metode penghapusan
					informasi selanjutnya: Dalam waktu 5 hari setelah pengguna
					berhenti menjadi anggota atau sesuai dengan jangka waktu
					penyimpanan yang ditetapkan dalam peraturan
					perundang-undangan.
				</span>
				<span>
					3) Perusahaan atau pengusaha iklan online hanya akan
					mengumpulkan data perilaku minimum yang diperlukan untuk
					iklan online yang relevan, dan tidak akan mengumpulkan data
					perilaku sensitif yang dapat secara signifikan melanggar
					hak, kepentingan, atau privasi pribadi pengguna, seperti
					ideologi, keyakinan, hubungan keluarga dan kerabat,
					pendidikan, riwayat penyakit, atau pengalaman aktivitas
					sosial lainnya.
				</span>
				<span>
					4) Perusahaan atau pengusaha iklan online dapat mengumpulkan
					dan menggunakan pengidentifikasi iklan (ADID, IDFA, dll.) di
					mobile web untuk iklan online yang relevan, dan pengguna
					dapat memblokir/mengaktifkan iklan yang disesuaikan di
					aplikasi dengan mengubah pengaturan pada ponsel.
				</span>
				<span className="sub">
					- Memblokir/mengaktifkan pengidentifikasi iklan pada ponsel
				</span>
				<span className="small">
					(1) [Android] (1) Pengaturan-{">"} (2) Pengaturan Google-
					{">"} (3) Iklan-{">"} (4) Atur ulang ID iklan atau Hapus ID
					iklan
				</span>
				<span className="small">
					(2) [iPhone]: (1) Pengaturan -{">"} (2) Privasi -{">"} (3)
					Pelacakan -{">"} (4) Matikan Izinkan Aplikasi Meminta untuk
					Melacak{" "}
				</span>
				<span>
					※ Menu dan metode dapat sedikit berbeda tergantung pada
					versi sistem ponsel.
				</span>
				<span>
					5) Pengguna dapat menghubungi melalui kontak yang tertera
					pada pasal 12 untuk mengajukan pertanyaan, mengajukan
					keberatan, atau melaporkan masalah yang terkait dengan data
					perilaku.
				</span>
			</p>
			<p>
				<h2>7. Hak-Hak Anggota dan Cara Pelaksanaannya</h2>
			</p>
			<p>
				<span>
					1) Pengguna sebagai anggota dapat kapan saja melihat atau
					mengubah informasi pribadi yang telah didaftarkan, serta
					dapat mencabut persetujuan atas penggunaan informasi pribadi
					melalui prosedur penonaktifan keanggotaan.
				</span>
				<span>
					2) Untuk melihat dan mengubah informasi pribadi, pengguna
					dapat mengakses menu "[Akun Saya] {">"} [Informasi Profil]".
					Untuk menonaktifkan keanggotaan, pengguna dapat mengakses
					menu ""[Akun Saya] {">"} [Informasi Profil]" lalu pilih
					"[Hapus Akun]". Selain itu, pengguna juga dapat mengajukan
					permintaan untuk melihat, mengubah, atau menonaktifkan
					keanggotaan melalui menu Pertanyaan 1:1, atau email ke pusat
					layanan pelanggan.
				</span>
				<span>
					3) Jika pengguna mengajukan permintaan untuk memperbaiki
					kesalahan dalam informasi pribadi, Perusahaan tidak akan
					menggunakan atau memberikan informasi pribadi tersebut
					sebelum perbaikan selesai. Informasi pribadi yang telah
					dihapus atau dinonaktifkan atas permintaan pengguna akan
					ditangani sesuai dengan jangka waktu penyimpanan yang
					tercantum dalam poin 3. Ketentuan Penggunaan Informasi
					Pribadi, dan tidak dapat dilihat atau digunakan untuk tujuan
					lain.
				</span>
			</p>
			<p>
				<h2>8. Kewajiban Pengguna</h2>
			</p>
			<p>
				<span>
					1) Pengguna wajib melindungi informasi pribadi pengguna
					sendiri, dan Perusahaan tidak bertanggung jawab atas segala
					masalah yang timbul akibat kebocoran informasi pribadi
					karena kelalaian pengguna sendiri atau masalah yang terjadi
					di internet.
				</span>
				<span>
					2) Mohon masukkan informasi pribadi dengan benar dan
					terbaru. Pengguna bertanggung jawab atas segala masalah yang
					timbul akibat ketidakakuratan informasi yang dimasukkan.
					Jika pengguna menggunakan informasi pribadi orang lain untuk
					mendaftar atau menggunakan layanan, keanggotaan pengguna
					akan dicabut dan pengguna dapat dikenai sanksi sesuai dengan
					ketentuan dalam Undang-Undang Perlindungan Data Pribadi,
					Undang-Undang Informasi dan Transaksi Elektronik, dan
					peraturan perundang-undangan lainnya.
				</span>
				<span>
					3) Selain memiliki hak untuk melindungi informasi pribadi,
					pengguna juga berkewajiban untuk melindungi diri sendiri dan
					tidak melanggar informasi pribadi orang lain. Harap
					berhati-hati agar informasi pribadi, termasuk ID dan kata
					sandi, tidak bocor, dan hindari merusak informasi pribadi
					orang lain, termasuk postingan.
				</span>
				<span>
					4) Pengguna wajib mematuhi Undang-Undang Perlindungan Data
					Pribadi, Undang-Undang Informasi dan Transaksi Elektronik,
					Kitab Undang-Undang Hukum Pidana, dan peraturan
					perundang-undangan lainnya terkait dengan informasi pribadi.
				</span>
			</p>
			<p>
				<h2>9. Tulisan (Posting)</h2>
			</p>
			<p>
				<span>
					1) Perusahaan menghargai tulisan pengguna dan akan berupaya
					sebaik mungkin untuk melindungi tulisan pengguna agar tidak
					diubah, dirusak, atau dihapus. Namun, hal ini tidak berlaku
					untuk kasus-kasus berikut:
				</span>
				<span className="sub">
					- Tulisan spam (misalnya: iklan untuk situs tertentu, iklan
					yang mengarahkan ke situs lain, dan tautan), tulisan yang
					menyebarkan informasi palsu dengan tujuan mencemarkan nama
					baik orang lain, pengungkapan identitas orang lain tanpa
					persetujuan, konten yang melanggar hak cipta perusahaan atau
					hak kekayaan intelektual pihak ketiga, dan konten lain yang
					tidak sesuai dengan topik papan buletin. Untuk mendorong
					budaya papan buletin yang positif, Perusahaan dapat
					menghapus bagian tertentu atau menggantinya dengan simbol
					saat mengungkap identitas orang lain tanpa izin. Dalam kasus
					lain, Perusahaan dapat menghapus tulisan setelah memberikan
					peringatan yang jelas atau individual.
				</span>
				<span>
					2) Pada dasarnya, semua hak dan tanggung jawab terkait
					dengan tulisan adalah milik penulisnya. Selain itu,
					informasi yang secara sukarela diungkapkan melalui tulisan
					sulit untuk dilindungi, oleh karena itu pengguna harus
					mempertimbangkan dengan matang sebelum menggunakan layanan
					ini untuk mengungkapkan informasi.
				</span>
			</p>
			<p>
				<h2>
					10. Tindakan Pengamanan Teknis dan Administratif terhadap
					Informasi Pribadi
				</h2>
			</p>
			<p>
				<span>
					Perusahaan menerapkan tindakan pengamanan berikut untuk
					melindungi informasi pribadi pengguna:
				</span>
				<span>1) Enkripsi Kata Sandi</span>
				<span className="sub">
					Kata sandi pengguna dienkripsi dan disimpan secara aman.
				</span>
				<span className="sub">
					Hanya pengguna yang mengetahui kata sandi, dan hanya
					pengguna yang dapat mengonfirmasi dan mengubah informasi
					pribadi dengan mengetahui kata sandi.
				</span>
				<span className="sub">
					Oleh karena itu, harap berhati-hati agar kata sandi tidak
					diketahui orang lain.
				</span>
				<span>2) Pencegahan terhadap Peretasan dan Virus Komputer</span>
				<span className="sub">
					Perusahaan menggunakan tindakan keamanan yang diperlukan
					untuk mencegah kebocoran atau kerusakan informasi pribadi
					pengguna akibat peretasan atau virus komputer, dan
					Perusahaan berupaya untuk memperoleh semua metode teknis
					yang memungkinkan untuk memastikan tindakan keamanan yang
					lebih baik.
				</span>
				<span>
					3) Pembatasan dan Pelatihan bagi Pengelola Informasi Pribadi
				</span>
				<span className="sub">
					Perusahaan membatasi jumlah karyawan yang menangani
					informasi pribadi dan secara berkala melakukan pelatihan
					kepada karyawan terkait untuk memastikan pelaksanaan dan
					kepatuhan terhadap kebijakan ini.
				</span>
				<span>
					4) Penyusunan dan Pelaksanaan Rencana Manajemen Internal
				</span>
				<span className="sub">
					Perusahaan menyusun dan melaksanakan rencana manajemen
					internal.
				</span>
				<span>
					5) Penyimpanan dan Pencegahan Perubahan atau Pemalsuan Log
					Akses
				</span>
				<span className="sub">
					Perusahaan menyimpan dan mengelola log akses (log web,
					ringkasan informasi, dll.) ke sistem pemrosesan informasi
					pribadi setidaknya selama 6 bulan, dan memastikan bahwa log
					akses tidak diubah, dicuri, hilang, atau dipalsukan.
				</span>
				<span>6) Tindakan Teknis untuk Mencegah Peretasan</span>
				<span className="sub">
					Untuk mencegah kebocoran atau kerusakan informasi pribadi
					akibat peretasan atau virus komputer, Perusahaan menginstal
					program keamanan, melakukan pembaruan dan pemeriksaan secara
					berkala, dan memasang sistem di area yang aksesnya terbatas
					dari luar serta melakukan pengawasan dan pemblokiran secara
					teknis dan fisik.
				</span>
				<span>
					7) Pengendalian Akses bagi Orang yang Tidak Berwenang
				</span>
				<span className="sub">
					Perusahaan memiliki tempat penyimpanan fisik yang terpisah
					untuk sistem informasi pribadi dan menerapkan prosedur
					pengendalian akses.
				</span>
			</p>
			<p>
				<h2>11. Penanggung Jawab Perlindungan Data Pribadi</h2>
			</p>
			<p>
				<span>
					Untuk melindungi informasi pribadi pengguna dan menangani
					keluhan terkait informasi pribadi, Perusahaan telah menunjuk
					departemen layanan pelanggan. Untuk pertanyaan terkait
					informasi pribadi, silakan hubungi departemen layanan
					pelanggan di bawah ini:
				</span>
				<span>□ Layanan Pelanggan: Tim Operasional GoPang</span>
				<span className="sub">Email: hbiz.indonesia@gmail.com</span>
				<span className="sub">
					Jika perlu melaporkan atau berkonsultasi mengenai
					pelanggaran privasi, silakan hubungi lembaga berikut:
				</span>
				<span className="sub">
					1) Komisi Perlindungan Data Pribadi (KPDP)
				</span>
				<span className="sub">2) Kepolisian Negara Indonesia</span>
				<span className="sub">
					3) Badan Perlindungan Konsumen Nasional (BPKN)
				</span>
				<span className="sub">
					4) Kementerian Komunikasi dan Informatika
				</span>
			</p>
			<p>
				<h2>12. Perlindungan Privasi Anak</h2>
			</p>
			<p>
				<span>
					Penggunaan sebagian layanan mungkin dibatasi untuk anak di
					bawah usia 13 tahun yang memerlukan persetujuan dari wali.
				</span>
			</p>
			<p>
				<h2>13. Kewajiban Pemberitahuan</h2>
			</p>
			<p>
				<span>
					Kebijakan Privasi ini berlaku efektif mulai dari tanggal
					berlaku yang tertera dan akan diberitahukan melalui aplikasi
					7 hari sebelum adanya perubahan atau revisi sesuai dengan
					peraturan perundang-undangan dan kebijakan.
				</span>
			</p>
			<p>
				<span>Perusahaan: PT Bomulsun HBiz Indonesia</span>
			</p>
			<p>
				<span>
					Alamat: The Smith Office Alam Sutera unit 0709,
					Desa/Kelurahan Kunciran, Kec. Pinang, Kota Tangerang,
					Provinsi Banten 15144
				</span>
			</p>
			<p>
				<span>NOMOR INDUK BERUSAHA (NIB): 1503240242224</span>
			</p>
			<p>
				<span>CEO: Hong Booyong</span>
			</p>
			<p>
				<span>Tanggal berlaku: 2025. 01. 03.</span>
			</p>
		</Container>
	);
};
