// 공지사항
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import Popup from "../Components/Popup";
import RefreshHandler from "../Components/RefreshHandler";

import search from "../Images/search.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";
import { config } from "../config/config";

const Banner = styled.div`
	height: 10rem;
	background-color: ${S.color.gray100};
`;
const NoticePopupContents = styled.div`
	header {
		padding: 2rem 1.4rem;
		border-bottom: 1px solid ${S.color.gray300};
		h1 {
			margin-bottom: 1.2rem;
			word-break: break-all;
		}
	}
	section {
		padding: 2rem 1.4rem;
		word-break: break-all;
		.detailImageWrap {
			border-radius: 1rem;
			overflow: hidden;
			margin-bottom: 1.4rem;
		}
		img {
			width: 100%;
		}
	}
`;

interface noticeData {
	bnIdx: number;
	subject: string;
	contents: string;
	regDt: string;
}
interface noticeDetail {
	bnIdx: string;
	boardTypeCode: string;
	contents: string;
	displayYn: string;
	modDt: string;
	modIdx: number;
	modIp: string;
	noticeContentsImageUrl: string;
	noticeDetailImages: string;
	openDt: string;
	regDt: string;
	regIdx: number;
	regIp: string;
	subject: string;
	useYn: number;
}

export default () => {
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 상세 정보 팝업
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	// 상세 정보 보여줄 공지 내용
	const [detailNotice, setDetailNotice] = useState<
		noticeDetail | undefined
	>();
	// 공지사항 리스트 정보
	const [noticeDatas, setNoticeDatas] = useState<noticeData[]>([]);
	const [noticeNextPage, setNoticeNextPage] = useState<boolean>(true);
	const noticePage = useRef<number>(1);
	const noticeObserver = useRef<HTMLDivElement>(null);
	// 검색
	const [filter, setFilter] = useState<string>(null);
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");

	// 공지사항 리셋
	const noticeReset = () => {
		setNoticeDatas([]);
		setNoticeNextPage(true);
		noticePage.current = 1;
	};
	// 공지사항 리스트 불러오기
	const noticeFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("noticeFetch");
		searchYnRef.current = false;
		await Api.get(`/board/noticeList`, {
			page: noticePage.current,
			keyword: keywordRef.current == "" ? undefined : keywordRef.current,
			limit: 15,
		}).then((res) => {
			if (res.data !== undefined) {
				const data = res.data.data.list;

				setNoticeDatas((prevDatas) => [...prevDatas, ...data]);
				setNoticeNextPage(data.length === 15);
				if (data.length) {
					noticePage.current += 1;
				}
			} else {
				alert("fail");
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		if (!noticeObserver.current || !noticeNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				noticeFetch();
			}
		});
		io.observe(noticeObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [noticeNextPage]);
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), noticeReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		noticeReset();
	};
	// 공지 상세 팝업 내용
	const getDetailNotice = async (bnIdx: number) => {
		await Api.get(`/board/noticeView/${bnIdx}`).then((res) => {
			if (res.status == 200) {
				console.log(res.data.data);
				setDetailNotice(res.data.data);
				setOpenPopup(true);
			}
		});
	};
	return (
		<>
			<Page
				title="Pemberitahuan"
				contents={
					<>
						{/* 상단배너 */}
						{/* <Banner></Banner> */}
						{/* 검색 */}
						<S.searchArea>
							<div className="searchBox">
								<input
									id="keyword"
									type="text"
									placeholder="kata pencarian"
								/>
								<S.searchButton onClick={() => searchAction()}>
									<img src={search} />
								</S.searchButton>
							</div>
						</S.searchArea>
						{/* 리스트 */}
						<RefreshHandler
							handleRefresh={() => noticeReset()}
							contents={
								<S.wrapper>
									{noticeDatas.length == 0 ? (
										<NoList
											type={4}
											searchKeyword={keyword}
										/>
									) : (
										noticeDatas?.map(
											(noticeData, index) => (
												<S.helpItem
													key={index}
													onClick={() => {
														getDetailNotice(
															noticeData.bnIdx
														);
													}}
												>
													<div>
														<h1>
															<S.subhead_3
																$ellipsis={2}
															>
																{
																	noticeData.subject
																}
															</S.subhead_3>
														</h1>
														<p>
															<S.bodyText_XS
																color={
																	S.color
																		.gray500
																}
															>
																{Util.dateFormat(
																	noticeData.regDt,
																	"date"
																)}
															</S.bodyText_XS>
														</p>
													</div>
													<Chevron
														fill={S.color.gray600}
													/>
												</S.helpItem>
											)
										)
									)}
									<div ref={noticeObserver} />
								</S.wrapper>
							}
						/>
					</>
				}
			/>
			{/* 공지사항 상세내용 */}
			<Popup
				isOpen={isOpenPopup}
				close={() => {
					setOpenPopup(false);
					setDetailNotice(undefined);
				}}
				title={"Pemberitahuan"}
				contents={
					detailNotice && (
						<NoticePopupContents>
							<header>
								<h1>
									<S.subhead_2>
										{detailNotice.subject}
									</S.subhead_2>
								</h1>
								<p>
									<S.bodyText_S color={S.color.gray600}>
										{Util.dateFormat(
											detailNotice.regDt,
											"date"
										)}
									</S.bodyText_S>
								</p>
							</header>
							<section>
								{detailNotice.noticeContentsImageUrl && (
									<div className="detailImageWrap">
										<img
											src={
												imgUrl +
												detailNotice.noticeContentsImageUrl
											}
											alt=""
										/>
									</div>
								)}
								<S.bodyText_M_long>
									{detailNotice.contents}
								</S.bodyText_M_long>
							</section>
						</NoticePopupContents>
					)
				}
			/>
		</>
	);
};
