// 배송요청
import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../libs/api';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DeliveryPrice, LoadingAtom, UserAtom, DeliveryRequest } from '../Recoil/Atoms/UserState';
import Util from '../Store/Util';
import { ToastAtom, ToastTypeAtom, ToastContentsAtom } from '../Recoil/Atoms/PopupState';

import * as S from '../Styles/Style';
import Page from '../Components/Page';
import Payment from '../Components/Payment';
import Modal from '../Components/Modal';

import xWhite from '../Images/x_white.png';
import { config } from '../config/config';

const AbsoluteButtonWrap = styled.div`
	width: 100%;
	padding: 1.4rem;
	position: absolute;
	bottom: 0;
	background: linear-gradient(transparent 0%, white 20%);
`;

declare global {
	interface Window {
		ReactNativeWebView: {
			postMessage: (value: string) => void;
		};
	}
}

interface Data {
	mdaIdx: number;
	mmIdx: number;
	addressName: string;
	recipientName: string;
	tel: string;
	postcode: string;
	address1: string;
	address2: string;
	saveYn: number;
	defaultYn: number;
	useYn: boolean;
	regIdx?: number;
	regDt?: string;
	regIp?: string;
	modIdx?: number;
	modDt?: string;
	modIp?: string;
}

export default () => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const userState = useRecoilValue(UserAtom);
	const apiUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_API_URL;
	const imgUrl = config[process.env.WDS_SOCKET_PATH].REACT_APP_IMG_URL;
	const location = useLocation().state;
	const presentData = location.item;
	const selectedIdx = location.selectedIdx;
	const deliveryPrice = useRecoilValue(DeliveryPrice);
	const token = localStorage.getItem('token');
	// 팝업
	const setOpenToast = useSetRecoilState(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	// 배송비 결제 팝업 생성
	const [isOpenDeliveryCharge, setOpenDeliveryCharge] = useState<boolean>(false);
	// 배송 요청 완료
	const [isOpenDeliveryRequest, setOpenDeliveryRequest] = useRecoilState<boolean>(DeliveryRequest);
	// 결제 방식
	const [paymentType, setPaymentType] = useState<number>(2);
	// 사용할 포인트
	const [pointToUse, setPointToUse] = useState<string>('');
	// 인풋
	const [addressName, setAddressName] = useState<string>('');
	const [recipientName, setRecipientName] = useState<string>('');
	const [tel, setTel] = useState<string>('');
	const [postcode, setPostcode] = useState<string>('');
	const [address1, setAddress1] = useState<string>('');
	const [address2, setAddress2] = useState<string>('');
	const [saveYn, setSaveYn] = useState<boolean>(false);
	const [defaultYn, setDefaultYn] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');

	useEffect(() => {
		setOpenDeliveryRequest(false);
		detailDeliveryAddress(selectedIdx ?? '');
	}, []);

	// 배송지 상세 조회
	const detailDeliveryAddress = async (mdaIdx?) => {
		await Api.get(`/member/info/memberDeliveryAddress/${mdaIdx}`).then((res) => {
			const data = res.data.data;
			console.log(data);
			let setData: Data;
			if (mdaIdx == '' && data !== undefined) {
				if (data[0].defaultYn == 1) {
					setData = data[0];
				}
			} else {
				setData = data;
			}
			if (setData !== undefined) {
				setAddressName(setData.addressName);
				setRecipientName(setData.recipientName);
				setTel(setData.tel);
				setPostcode(setData.postcode);
				setAddress1(setData.address1);
				setAddress2(setData.address2);
			}
		});
	};

	// 입력값 변경 이벤트
	const onChangeEvent = (target) => {
		// 입력 최대값 체크
		if (target.value.length > target.maxLength) {
			if (target.name == 'addressName') {
				setAddressName(target.value.slice(0, target.maxLength));
			} else if (target.name == 'recipientName') {
				setRecipientName(target.value.slice(0, target.maxLength));
			} else if (target.name == 'tel') {
				setTel(target.value.slice(0, target.maxLength));
			}
		} else {
			if (target.name == 'addressName') {
				setAddressName(target.value);
			} else if (target.name == 'recipientName') {
				setRecipientName(target.value);
			} else if (target.name == 'tel') {
				setTel(Util.phoneNumFormat(target.value));
			}
		}
	};

	// 결제 요청 앱으로 메세지 전송
	const postMessage = (params) => {
		if (typeof window == undefined || !window?.ReactNativeWebView) {
			return;
		} else {
			window.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: 'PAYMENT',
					token: token,
					params: params,
				})
			);
		}
	};

	// 배송 요청
	const deliveryRequest = async () => {
		// 가상계좌 완료
		// Pembelian peti harta karun Anda akan dikonfirmasi dalam waktu maksimal 10 menit setelah pembayaran ke virtual account dilakukan.
		if (isLoading) return;
		setLoading(true);
		const params = {
			pumIdx: presentData.pumIdx,
			usePoint: Util.toNumber(pointToUse),
			recipientName: recipientName,
			tel: tel,
			postcode: postcode,
			addressName: addressName,
			address1: address1,
			address2: address2,
			deliveryMessage: message,
			saveYn: saveYn ? 1 : 0,
			defaultYn: defaultYn ? 1 : 0,
		};

		if (paymentType === 2) {
			// PG결제요청은 앱으로 메세지 전송
			postMessage(params);
		} else {
			if (userState.freePoint < deliveryPrice) {
				// 포인트 부족
				setToastType(1);
				setToastContents('Poin tidak cukup.');
				setOpenToast(true);
			} else {
				await Api.get('/member/info/memberPresentDeliveryInsert', params).then((res) => {
					if (res.data !== undefined) {
						if (res.data.code == 200) {
							setOpenDeliveryRequest(true);
						} else {
							// 배송 신청 실패
							setToastType(1);
							setToastContents('Permintaan pengiriman gagal.');
							setOpenToast(true);
						}
					} else {
						// 배송 신청 실패
						setToastType(1);
						setToastContents('Permintaan pengiriman gagal.');
						setOpenToast(true);
					}
				});
			}
		}
		setLoading(false);
	};

	return (
		<>
			<Page
				title="Permintaan Pengiriman"
				contents={
					<S.pageContainer>
						<section>
							<S.treasureBox>
								<div>
									<S.heightImg src={imgUrl + presentData.imageUrl} />
								</div>
								<p>
									<S.subhead_3 $ellipsis={1}>{presentData.name}</S.subhead_3>
								</p>
							</S.treasureBox>
							<S.flexRowBetween>
								<S.subhead_2>Informasi Pengiriman</S.subhead_2>
								<S.borderBadge
									$large
									onClick={() =>
										navigate('/DLVR003001', {
											state: {
												item: presentData,
											},
										})
									}>
									<S.bodyText_XS>Pengaturan Alamat Pengiriman</S.bodyText_XS>
								</S.borderBadge>
							</S.flexRowBetween>
							<h2>
								<S.subhead_4 color={S.color.gray800}>Label Alamat</S.subhead_4>{' '}
								<S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										name="addressName"
										type="text"
										value={addressName}
										onChange={(e) => {
											onChangeEvent(e.target);
										}}
										placeholder="Masukkan label alamat"
										maxLength={20}
									/>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4 color={S.color.gray800}>Penerima</S.subhead_4>{' '}
								<S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										name="recipientName"
										type="text"
										value={recipientName}
										onChange={(e) => {
											onChangeEvent(e.target);
										}}
										placeholder="Masukkan nama penerima"
										maxLength={20}
									/>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4 color={S.color.gray800}>Kontak</S.subhead_4>{' '}
								<S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										name="tel"
										type="text"
										value={tel}
										onChange={(e) => {
											onChangeEvent(e.target);
										}}
										placeholder="0800 - 000 - 000"
										maxLength={18}
									/>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4 color={S.color.gray800}>Alamat</S.subhead_4>{' '}
								<S.subhead_4 color={S.color.error}>*</S.subhead_4>
							</h2>
							<div className="inputArea">
								<S.inputField>
									<div className="inputWrap">
										<input
											id="postcode"
											name="postcode"
											placeholder="Masukkan alamat"
											value={postcode}
											onChange={(e) => setPostcode(e.target.value)}></input>
									</div>
									<button>
										<S.button_XS>Cari alamat</S.button_XS>
									</button>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input
											id="address1"
											name="address1"
											placeholder="Masukkan alamat"
											value={address1}
											onChange={(e) => setAddress1(e.target.value)}></input>
									</div>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input
											id="address2"
											name="address2"
											placeholder="Masukkan alamat"
											value={address2}
											onChange={(e) => setAddress2(e.target.value)}></input>
									</div>
								</S.inputField>
								<S.controlsWrap>
									<S.checkbox
										id="saveYn"
										name="saveYn"
										type="checkbox"
										checked={saveYn}
										onChange={(e) => setSaveYn(e.target.checked)}></S.checkbox>
									<label htmlFor="save">
										<S.bodyText_S color={S.color.gray800}>Simpan di daftar alamat.</S.bodyText_S>
									</label>
								</S.controlsWrap>
								<S.controlsWrap>
									<S.checkbox
										id="defaultYn"
										name="defaultYn"
										type="checkbox"
										checked={defaultYn}
										onChange={(e) => setDefaultYn(e.target.checked)}></S.checkbox>
									<label htmlFor="basic">
										<S.bodyText_S color={S.color.gray800}>Pilih sebagai alamat utama</S.bodyText_S>
									</label>
								</S.controlsWrap>
							</div>
							<h2 style={{ marginTop: '2rem' }}>
								<S.subhead_4 color={S.color.gray800}>Catatan Pengiriman</S.subhead_4>
							</h2>
							<S.inputField style={{ marginBottom: '1.2rem' }}>
								<div className="inputWrap">
									<textarea
										name="message"
										id=""
										rows={5}
										value={message}
										onChange={(e) => {
											setMessage(e.target.value);
										}}
										maxLength={50}
										placeholder="Masukkan dalam 50 karakter."></textarea>
									<S.inputCount className="inTextArea">
										<S.bodyText_XS>{message.length}/50</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
							<S.textList $gray>
								<li>
									<S.bodyText_S>
										Tergantung pada produk dan ketersediaan stok, pengiriman barang mungkin
										membutuhkan waktu yang lebih lama.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Pengiriman ke beberapa daerah mungkin terbatas, dan biaya pengiriman tambahan
										mungkin berlaku jika jarak pengiriman jauh.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
						<section>
							<S.borderBox style={{ padding: '1.2rem 0' }}>
								<p style={{ textAlign: 'center' }}>
									<S.headline_4 color={S.color.gray800} style={{ textAlign: 'center' }}>
										Poin saya saat ini
									</S.headline_4>
								</p>
								<p style={{ textAlign: 'center' }}>
									<S.headline_3 style={{ textAlign: 'center' }} color={S.color.primaryVariant}>
										{Util.addComma(userState.freePoint)}P
									</S.headline_3>
								</p>
							</S.borderBox>
						</section>
					</S.pageContainer>
				}
				button={'Bayar Ongkos Kirim'}
				buttonAction={() => setOpenDeliveryCharge(true)}
				disabled={
					!(
						(
							addressName.length > 0 &&
							recipientName.length > 0 &&
							tel.length > 0 &&
							postcode.length > 0 &&
							address1.length > 0 &&
							address2.length > 0
						)
						// 주소
					)
				}
			/>
			{/* 배송비 결제 팝업 */}
			<S.popupContainer
				$isOpen={isOpenDeliveryCharge}
				// $isOpen={true}
				style={{ backgroundColor: 'white' }}>
				<S.page>
					<S.pageTitle>
						<button className="close" onClick={() => setOpenDeliveryCharge(false)}>
							<img src={xWhite} />
						</button>
						<S.mainTitle_M color="white">Informasi terperinci harta karun</S.mainTitle_M>
					</S.pageTitle>

					<S.pageContainer>
						<Payment
							type={2}
							totalPrice={deliveryPrice}
							paymentType={paymentType}
							setPaymentType={setPaymentType}
							pointToUse={pointToUse}
							setPointToUse={setPointToUse}
						/>
					</S.pageContainer>

					<AbsoluteButtonWrap>
						<S.buttonPrimary
							onClick={() => {
								setOpenDeliveryCharge(false);
								deliveryRequest();
							}}
							disabled={paymentType === 1 && deliveryPrice - Util.toNumber(pointToUse) !== 0}>
							Bayar dan Ajukan Pengiriman
						</S.buttonPrimary>
					</AbsoluteButtonWrap>
				</S.page>
			</S.popupContainer>
			{/* 배송 요청 완료 */}
			<Modal
				isOpen={isOpenDeliveryRequest}
				contents={
					<>
						<h2>
							<S.headline_4>Pengiriman telah diajukan.</S.headline_4>
						</h2>
						<S.flexRowStart>
							<S.popupBorderBox>
								<S.heightImg src={imgUrl + presentData.imageUrl} />
							</S.popupBorderBox>
							<p>
								<S.subhead_4 color={S.color.gray900} $ellipsis={2}>
									{presentData.name}
								</S.subhead_4>
							</p>
						</S.flexRowStart>
					</>
				}
				buttonTextL={'OK'}
				buttonActionL={() => navigate('/MYME002001', { replace: true })}
			/>
		</>
	);
};

