// 내보물함
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import Filter from "../Components/Filter";
import MYME002002 from "./MYME002002";
import MYME002003 from "./MYME002003";

export default () => {
	const location = useLocation();
	// 황성화 탭 인덱스
	const defaultTab = location.state ? location.state.data : 0;
	const [activeTab, setActiveTab] = useState<number>(defaultTab);
	// 필터 모달 A
	const [isOpenFilterA, setOpenFilterA] = useState<boolean>(false);
	const [filterA, setFilterA] = useState<string[]>([]);
	const defaultOrderByA = 2;
	const [orderByA, setOrderByA] = useState<number>(defaultOrderByA);
	// 필터 모달 B
	const [isOpenFilterB, setOpenFilterB] = useState<boolean>(false);
	const [filterB, setFilterB] = useState<string[]>([]);
	const [stateCode, setStateCode] = useState<string>(""); // 선택된 상태값
	const options = [
		{ id: "01", text: "Dalam proses pembayaran" }, // 결제 중
		{ id: "-01", text: "Pembayaran gagal" }, // 결제 실패
		{ id: "00", text: "Permintaan Pengiriman" }, // 배송 신청
		{ id: "10", text: "Persiapan Pengiriman" }, // 배송 준비
		{ id: "20", text: "Dalam Pengiriman" }, // 배송 중
		{ id: "30", text: "Pengiriman Selesai" }, // 배송 완료
		{ id: "-10", text: "Pengiriman Dibatalkan" }, // 배송 취소
	];

	return (
		<>
			<Page
				title="Harta Karun Saya"
				contents={
					<TabMenu
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabs={[
							{
								index: 0,
								name: "Peti Harta Karun",
								content: (
									<MYME002002
										setOpenFilter={setOpenFilterA}
										filter={filterA}
										orderBy={orderByA}
									/>
								),
							},
							{
								index: 1,
								name: "Status Pengiriman",
								content: (
									<MYME002003
										setOpenFilter={setOpenFilterB}
										filter={filterB}
										stateCode={stateCode}
									/>
								),
							},
						]}
					/>
				}
				bottomNav={true}
			/>
			{isOpenFilterA && (
				<Filter
					division={0}
					isOpen={isOpenFilterA}
					close={() => setOpenFilterA(false)}
					filter={filterA}
					setFilter={setFilterA}
					defaultOrderBy={defaultOrderByA}
					orderBy={orderByA}
					setOrderBy={setOrderByA}
				/>
			)}
			{isOpenFilterB && (
				<Filter
					division={1}
					isOpen={isOpenFilterB}
					close={() => setOpenFilterB(false)}
					filter={filterB}
					setFilter={setFilterB}
					select={stateCode}
					setSelect={setStateCode}
					optionsTitle={"Status pembayaran dan pengiriman"}
					options={options}
				/>
			)}
		</>
	);
};
