// 보물상자 오픈 애니메이션
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";

import gachaGold from "../Images/gacha_gold.mp4";

const Container = styled.div`
	height: 100vh;
	position: relative;
	video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export default (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state.data;
	const userState = useRecoilValue(UserAtom);
	// 영상 cover 위한 사이즈 조절
	const aniSize =
		window.innerWidth * 2.3 > window.innerHeight
			? window.innerWidth * 2.3
			: window.innerHeight + 2;
	// 영상 종료 여부
	const [finish, setFinish] = useState<boolean>(false);

	// 일정 시간 이후, 혹은 화면 클릭 시 페이지 이동
	useEffect(() => {
		if (finish) return next();

		const time = setTimeout(() => {
			next();
		}, 5000);

		return () => clearTimeout(time);
	}, [finish]);

	const next = () => {
		console.log(data);
		navigate("/BUPO001003", {
			state: { data: data },
			replace: true,
		});
	};

	return (
		<Container onClick={() => setFinish(true)}>
			<video
				muted
				autoPlay
				height={aniSize}
				loop
				webkit-playsinline
				playsInline
				preload="metadata"
			>
				<source
					// 오픈한 박스 종류 구분하여 영상 실행

					src={gachaGold}
					type="video/mp4"
				/>
			</video>
		</Container>
	);
};
